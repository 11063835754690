import React, { useState } from "react";
import FinBase from "../FinBase";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import Swal from "sweetalert2";
import config from "../../../functions/config";

function Add_Holiday() {
    const ID = Cookies.get('Login_id');
    const [data, setData] = useState({ title: '', sdate: '', edate: '' });
    const [agree, setAgree] = useState(false); // State for checkbox
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };

    const handleCheckboxChange = (e) => {
        setAgree(e.target.checked);
    };

    const addHoliday = (e) => {
        e.preventDefault();

        if (!agree) {
            Swal.fire({
                icon: "error",
                title: "You must agree to the terms and conditions.",
            });
            return;
        }

        const formattedData = {
            title: data.title,
            sdate: data.sdate,
            edate: data.edate,
        };
        const url = `${config.base_url}/Fin_add_holiday/${ID}/`;

        axios.post(url, formattedData)
            .then((res) => {
                if (res.data.status) {
                    Swal.fire({
                        icon: "success",
                        title: "Holiday Added",
                    });
                    navigate('/holiday');
                } else if (res.data.message) {
                    Swal.fire({
                        icon: "error",
                        title: res.data.message,
                    });
                }
            })
            .catch((err) => {
                console.log("ERROR=", err);
                if (err.response && err.response.data.message) {
                    Swal.fire({
                        icon: "error",
                        title: err.response.data.message,
                    });
                }
            });
    };

    return (
        <>
            <FinBase />
            <div className="page-content" style={{ backgroundColor: '#2f516f', minHeight: "100vh" }}>
                <div className="card radius-15 h-20">
                    <div className="row">
                        <div className="col-md-12">
                            <center><h2 className="mt-3">ADD HOLIDAY</h2></center>
                            <hr />
                        </div>
                    </div>
                </div>

                <div className="card radius-15">
                    <div className="card-body">
                        <form method="post" className="needs-validation" noValidate onSubmit={addHoliday}>
                            <div className="row">
                                <div className="col-md-12 mx-0">
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="title" style={{ color: 'white' }}>Title</label>
                                            <input type="text" name="title" className="form-control"
                                                style={{ backgroundColor: '#2a4964', color: 'white', width: '900px' }} required id="title" value={data.title} onChange={handleInputChange} />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="sdate" style={{ color: 'white' }}>Start Date</label>
                                            <input type="date" name="sdate" className="form-control"
                                                style={{ backgroundColor: '#2a4964', color: 'white' }} required id="sdate" value={data.sdate} onChange={handleInputChange} />
                                        </div>
                                        <div className="col-md-12 mt-3">
                                            <label htmlFor="edate" style={{ color: 'white' }}>End Date</label>
                                            <input type="date" name="edate" className="form-control"
                                                style={{ backgroundColor: '#2a4964', color: 'white' }} required id="edate" value={data.edate} onChange={handleInputChange} />
                                        </div>
                                    </div>

                                    {/* Checkbox for Terms and Conditions */}
                                    <div className="form-check mt-4">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="terms"
                                            checked={agree}
                                            onChange={handleCheckboxChange}
                                        />
                                        <label className="form-check-label text-white" htmlFor="terms">
                                            I agree to the <a href="/terms" target="_blank" className="text-light">terms and conditions</a>
                                        </label>
                                    </div>

                                    <div className="row mt-5 mb-5">
                                        <div className="col-md-3"></div>
                                        <div className="col-md-6 d-flex justify-content-center">
                                            <button className="btn btn-outline-secondary w-50 text-light" type="submit" style={{ width: 'fit-content', height: 'fit-content' }}>SAVE</button>
                                            <button type="button" onClick={() => window.history.back()} className="btn btn-outline-secondary w-50 ml-1 text-light" style={{ width: 'fit-content', height: 'fit-content' }}>CANCEL</button>
                                        </div>
                                        <div className="col-md-3"></div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Add_Holiday;
