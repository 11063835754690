import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './components/Index';
import CompanyReg from './components/company/CompanyReg';
import CompanyReg2 from './components/company/CompanyReg2';
import Modules from './components/company/Modules';
import DistributorReg from './components/distributor/DistributorReg';
import DistributorReg2 from './components/distributor/DistributorReg2';
import StaffReg from './components/staff/StaffReg';
import StaffReg2 from './components/staff/StaffReg2';
import AdminHome from './components/admin/AdminHome';
import Distributors from './components/admin/Distributors';
import AdminPrivateRoutes from './components/routes/AdminPrivateRoutes';
import DistributorsReq from './components/admin/DistributorsReq';
import AllDistributors from './components/admin/AllDistributors';
import DistributorReqOverview from './components/admin/DistributorReqOverview';
import AllDistributorsOverview from './components/admin/AllDistributorsOverview';
import Clients from './components/admin/Clients';
import ClientsReq from './components/admin/ClientsReq';
import AllClients from './components/admin/AllClients';
import ClientReqOverview from './components/admin/ClientReqOverview';
import AllClientsOverview from './components/admin/AllClientsOverview';
import PaymentTerms from './components/admin/PaymentTerms';
import DistributorPrivateRoutes from './components/routes/DistributorPrivateRoutes';
import DistributorHome from './components/distributor/DistributorHome';
import DClientReq from './components/distributor/DClientReq';
import DClientReqOverview from './components/distributor/DClientReqOverview';
import DAllClients from './components/distributor/DAllClients';
import DClientOverview from './components/distributor/DClientOverview';
import CompanyHome from './components/company/CompanyHome';
import CompanyPrivateRoutes from './components/routes/CompanyPrivateRoutes';
import StaffReq from './components/company/StaffReq';
import AllStaffs from './components/company/AllStaffs';
import CompanyProfile from './components/company/CompanyProfile';
import CompanyStaffPrivateRoutes from './components/routes/CompanyStaffPrivateRoutes';
import EditCompanyProfile from './components/company/EditCompanyProfile';
import EditStaffProfile from './components/staff/EditStaffProfile';
import DistributorProfile from './components/distributor/DistributorProfile';
import DistributorProfileEdit from './components/distributor/DistributorProfileEdit';
import EditModules from './components/company/EditModules';
import AdminNotifications from './components/admin/AdminNotifications';
import Wrong from './components/company/Wrong';
import NotificationOverview from './components/admin/NotificationOverview';
import DistNotifications from './components/distributor/DistNotifications';
import DistNotificationOverview from './components/distributor/DistNotificationOverview';
import Items from './components/company/items/Items';
import AddItem from './components/company/items/AddItem';
import ViewItem from './components/company/items/ViewItem';
import ItemHistory from './components/company/items/ItemHistory';
import EditItem from './components/company/items/EditItem';
import Customers from './components/company/customers/Customers';
import AddCustomer from './components/company/customers/AddCustomer';
import ViewCustomer from './components/company/customers/ViewCustomer';
import CustomerHistory from './components/company/customers/CustomerHistory';
import EditCustomer from './components/company/customers/EditCustomer';
import PriceList from './components/company/pricelist/PriceList';
import AddPriceList from './components/company/pricelist/AddPriceList';
import ViewPriceList from './components/company/pricelist/ViewPriceList';
import PriceListHistory from './components/company/pricelist/PriceListHistory';
import EditPriceList from './components/company/pricelist/EditPriceList';
import ChartOfAccounts from './components/company/chartofaccounts/ChartOfAccounts';
import AddAccount from './components/company/chartofaccounts/AddAccount';
import ViewAccount from './components/company/chartofaccounts/ViewAccount';
import AccountHistory from './components/company/chartofaccounts/AccountHistory';
import EditAccount from './components/company/chartofaccounts/EditAccount';
import Banking from './components/company/banking/Banking';
import AddBank from './components/company/banking/AddBank';
import ViewBank from './components/company/banking/ViewBank';
import BankTransactionHistory from './components/company/banking/BankTransactionHistory';
import EditBank from './components/company/banking/EditBank';
import BankToCash from './components/company/banking/BankToCash';
import CashToBank from './components/company/banking/CashToBank';
import BankToBank from './components/company/banking/BankToBank';
import BankAdjust from './components/company/banking/BankAdjust';
import EditTransactions from './components/company/banking/EditTransactions';
import Employee from './components/company/employee/employee';
import AddEmployee from './components/company/employee/addemployee';
import Employeeoverview from './components/company/employee/employeeoverview';
import EmployeeHistory from './components/company/employee/employeehistory';
import EditEmployee from './components/company/employee/editemployee';
import StockAdjustment from './components/company/stockadjust/StockAdjustment';
import AddStockAdjust from './components/company/stockadjust/AddStockAdjust';
import ViewStockAdjust from './components/company/stockadjust/ViewStockAdjust';
import StockAdjustHistory from './components/company/stockadjust/StockAdjustHistory';
import EditStockAdjust from './components/company/stockadjust/EditStockAdjust';
import SalesOrder from './components/company/salesorder/SalesOrder';
import AddSalesOrder from './components/company/salesorder/AddSalesOrder';
import ViewSalesOrder from './components/company/salesorder/ViewSalesOrder';
import SalesOrderHistory from './components/company/salesorder/SalesOrderHistory';
import EditSalesOrder from './components/company/salesorder/EditSalesOrder';
import Vendors from './components/company/vendors/Allvendors';
import Addvendor from './components/company/vendors/Addvendor';
import View_vendor from './components/company/vendors/view_vendor';
import Edit_vendor from './components/company/vendors/Edit_vendor';
import Vendorhistory from './components/company/vendors/vendorhistory';
import Banklist from './components/company/bankholders/banklist';
import Addbankholder from './components/company/bankholders/add_bankholder';
import Viewholder from './components/company/bankholders/viewholder';
import BankHistory from './components/company/bankholders/bankhistory';
import Editholder from './components/company/bankholders/editholder';
import Invoice from './components/company/invoice/Invoice';
import AddInvoice from './components/company/invoice/AddInvoice';
import ViewInvoice from './components/company/invoice/ViewInvoice';
import InvoiceHistory from './components/company/invoice/InvoiceHistory';
import EditInvoice from './components/company/invoice/EditInvoice';
import DeliveryChallan from './components/company/deliverychallan/DeliveryChallan';
import AddDeliveryChallan from './components/company/deliverychallan/AddDeliveryChallan';
import ViewChallan from './components/company/deliverychallan/ViewChallan';
import ChallanHistory from './components/company/deliverychallan/ChallanHistory';
import EditDeliveryChallan from './components/company/deliverychallan/EditDeliveryChallan';
import ConvertChallanToInvoice from './components/company/deliverychallan/ConvertChallanToInvoice';
import Estimate from './components/company/estimate/Estimate';
import AddEstimate from './components/company/estimate/AddEstimate';
import ViewEstimate from './components/company/estimate/ViewEstimate';
import EstimateHistory from './components/company/estimate/EstimateHistory';
import EditEstimate from './components/company/estimate/EditEstimate';
import RecInvoice from './components/company/recurringinvoice/RecInvoice';
import AddRecInvoice from './components/company/recurringinvoice/AddRecInvoice';
import ViewRecInvoice from './components/company/recurringinvoice/ViewRecInvoice';
import RecInvoiceHistory from './components/company/recurringinvoice/RecInvoiceHistory';
import EditRecInvoice from './components/company/recurringinvoice/EditRecInvoice';
import RetInvoice from './components/company/retainerinvoice/RetInvoice';
import AddRetInvoice from './components/company/retainerinvoice/AddRetInvoice';
import ViewRetInvoice from './components/company/retainerinvoice/ViewRetInvoice';
import RetInvoiceHistory from './components/company/retainerinvoice/RetInvoiceHistory';
import EditRetInvoice from './components/company/retainerinvoice/EditRetInvoice';
import View_purchase_order from './components/company/purchaseorder/viewpurchaseorder';
import Purchase_order from './components/company/purchaseorder/purchaseorder';
import Add_Purchase_Order from './components/company/purchaseorder/Add_purchase_order';
import Edit_Purchase_Order from './components/company/purchaseorder/Edit_Purchase_Order';
import Purchase_order_History from './components/company/purchaseorder/Purchase_Order_History';
import CreditNote from './components/company/creditnote/CreditNote';
import AddCreditNote from './components/company/creditnote/AddCreditNote';
import ViewCreditNote from './components/company/creditnote/ViewCreditNote';
import CreditNoteHistory from './components/company/creditnote/CreditNoteHistory';
import EditCreditNote from './components/company/creditnote/EditCreditNote';
import ConvertSalesOrderToInvoice from './components/company/salesorder/ConvertSalesOrderToInvoice';
import ConvertEstimateToInvoice from './components/company/estimate/ConvertEstimateToInvoice';
import ConvertEstimateToRecInvoice from './components/company/estimate/ConvertEstimateToRecInvoice';
import ConvertChallanToRecInvoice from './components/company/deliverychallan/ConvertChallanToRecInvoice';
import ConvertSalesOrderToRecInvoice from './components/company/salesorder/ConvertSalesOrderToRecInvoice';
import ConvertEstimateToSalesOrder from './components/company/estimate/ConvertEstimateToSalesOrder';
import PaymentReceived from './components/company/paymentreceived/PaymentReceived';
import AddPaymentReceived from './components/company/paymentreceived/AddPaymentReceived';
import ViewPayment from './components/company/paymentreceived/ViewPayment';
import PaymentHistory from './components/company/paymentreceived/PaymentHistory';
import EditPaymentReceived from './components/company/paymentreceived/EditPaymentReceived';
import RecBill from './components/company/recurringbill/RecBill';
import AddRecBill from './components/company/recurringbill/AddRecBill';
import ViewRecBill from './components/company/recurringbill/ViewRecBill';
import RecBillHistory from './components/company/recurringbill/RecBillHistory';
import EditRecBill from './components/company/recurringbill/EditRecBill';
import Expense from './components/company/expense/Expense';
import AddExpense from './components/company/expense/AddExpense';
import ViewExpense from './components/company/expense/ViewExpense';
import ExpenseHistory from './components/company/expense/ExpenseHistory';
import EditExpense from './components/company/expense/EditExpense';
import Bill from './components/company/bill/Bill';
import AddBill from './components/company/bill/AddBill';
import ViewBill from './components/company/bill/ViewBill';
import BillHistory from './components/company/bill/BillHistory';
import EditBill from './components/company/bill/EditBill';
import PaymentMade from './components/company/paymentmade/PaymentMade';
import AddPaymentMade from './components/company/paymentmade/AddPaymentMade';
import DebitNote from './components/company/debitnote/DebitNote';
import AddDebitNote from './components/company/debitnote/AddDebitNote';
import ViewDebitNote from './components/company/debitnote/ViewDebitNote';
import DebitNoteHistory from './components/company/debitnote/DebitNoteHistory';
import EditDebitNote from './components/company/debitnote/EditDebitNote';
import ViewPaymentMade from './components/company/paymentmade/ViewPaymentMade';
import PaymentMadeHistory from './components/company/paymentmade/PaymentMadeHistory';
import EditPaymentMade from './components/company/paymentmade/EditPaymentMade';
import Attendence from './components/company/payroll_attendence/Attendence';
import Add_Attendece from './components/company/payroll_attendence/add_attendence';
import View_Attendence from './components/company/payroll_attendence/view_attendence';
import Edit_Attendence from './components/company/payroll_attendence/edit_attendence';
import Attendence_history from './components/company/payroll_attendence/attendence_history';
import SalaryDetails from './components/company/salarydetails/SalaryDetails';
import AddSalaryDetails from './components/company/salarydetails/AddSalaryDetails';
import ViewSalaryDetails from './components/company/salarydetails/ViewSalaryDetails';
import SalaryDetailsHistory from './components/company/salarydetails/SalaryDetailsHistory';
import EditSalaryDetails from './components/company/salarydetails/EditSalaryDetails';
import TermUpdateModules from './components/company/TermUpdateModules';
import TermExtensionRequests from './components/admin/TermExtensionRequests';
import InvoiceReport from './components/company/invoicereport/InvoiceReport';
import RecInvoiceReport from './components/company/recinvoicereport/RecInvoiceReport';
import Salesorderreport from './components/company/report/salesorderreport';
import Estimatereport from './components/company/report/estimatereport';
import Loanlist from './components/company/loan/loanlist';
import Addloan from './components/company/loan/addloan';
import ViewLoan from './components/company/loan/viewloan';
import LoanHistory from './components/company/loan/loanhistory';
import Repayment from './components/company/loan/repayment';
import Newloan from './components/company/loan/newloan';
import RepaymentHistory from './components/company/loan/repaymenthistory';
import AdditionalHistory from './components/company/loan/additionalhistory';
import RepaymentEdit from './components/company/loan/repaymentedit';
import Additionaledit from './components/company/loan/additinaledit';
import Loanedit from './components/company/loan/loanedit';
import Payment_Received_Summary from './components/company/report/Payment_recieved_summary';
import Payment_Made_summary from './components/company/report/Payment_made_summary';
import SalesByCustomer from './components/company/report/Sales_by_customer';
import SalesByItem from './components/company/report/Sales_by_item';
import Payment_Recieved_Details from './components/company/report/payment_recieved_details';
import Payment_Made_Details from './components/company/report/Payment_Made_details';
import RetainerInvoiceDetails from './components/company/RetainerInvoiceDetails';
import CreditNoteDetails from './components/company/creditnote/CreditNoteDetails';
import PurchaseItemReport from './components/company/report/PurchaseOrderItem';
import SalesItemReport from './components/company/report/SalesOrderItem';
import Purchase_By_Vendor from './components/company/Purchase_by_vendor/Purchase_By_Vendor';
import Purchase_By_Item from './components/company/Purchase_by_item/Purchase_By_Item';
import Employee_Loan_Statement from './components/company/report/Employee_Loan_Statement';
import Account_Type_Summary from './components/company/report/Account_Type_Summary';
import Purchase_order_details from './components/company/report/Purchase_order_details';
import Delivery_chellan_report from './components/company/report/Delivery_chellan_report';
import CustomerBalanceReport from './components/company/report/customer_balance_report';
import VendorBalanceReport from './components/company/report/vendor_balance_report';
import Debitnotereport from './components/company/report/debitnotereport';
import Expensereport from './components/company/report/expensereport';
import RecBillReport from './components/company/recurringbillreport/RecBillReport';
import BillReport from './components/company/billreport/BillReport';
import UPIPayments from './components/company/upi/UPIPayments';
import UPIStatement from './components/company/upi/UPIStatement';
import ChequePayments from './components/company/cheque/ChequePayments';
import ChequeStatement from './components/company/cheque/ChequeStatement';
import Holiday from './components/company/Holiday/Holiday';
import Add_Holiday from './components/company/Holiday/Add_Holiday';
import ViewHolidays from './components/company/Holiday/view_holidays';
import Edit_Holiday from './components/company/Holiday/Edit_Holiday';
import HolidayHistory from './components/company/Holiday/HolidayHistory';
import HolidayHistoryPage from './components/company/Holiday/HolidayHistory';
import ManualJournal from './components/company/manualjournal/ManualJournal';
import ManualJournalCreation from './components/company/manualjournal/ManualJournalCreation';
import ViewManualJournal from './components/company/manualjournal/viewjournal';
import ManualJournalHistory from './components/company/manualjournal/ManualJournalHistory';
import EditManualJournal from './components/company/manualjournal/EditManualJournal';
import Alltransactions from './components/company/report/Alltransactions';
import Partystatement from './components/company/report/Partystatement';
import Stock_details from './components/company/report/Stock_details';
import Low_stocks_details from './components/company/report/Low_stocks_details';
import Convert_porder_to_bill from './components/company/purchaseorder/Convert_porder_to_bill';
import Convert_porder_to_rbill from './components/company/purchaseorder/Convert_porder_to_rbill';  
import Agingsummary from './components/company/report/agingsummaryreport';
import AgingDetails from './components/company/report/agingDetails';
import GSTR1 from './components/company/report/GSTR1';
import GSTR2 from './components/company/report/GSTR2';
import Stock_val_summary from './components/company/report/Stock_val_summary';
import Bankstatement from './components/company/report/Bankstatement';
import Journalreport from './components/company/report/Journalreport';
import DiscountReport from './components/company/report/DiscountReport';
import Cashflow from './components/company/report/Cashflow';
import ItemReportByParty from './components/company/report/ItemReportByParty';
import PartyReportByItem from './components/company/report/PartyReportByItem';
import CashInHand from './components/company/banking/CashInHand';
import AddCash from './components/company/banking/AddCash';
import CashInHandStatement from './components/company/banking/CashInHandStatement';
import Cashinhand_Graph from './components/company/banking/Cashinhand_Graph';
import Edit_cashinhand from './components/company/banking/Edit_cashinhand';
import Cashinhand_history from './components/company/banking/Cashinhand_history';
import Trialbalance from './components/company/report/Trialbalance';
import Sales_item_discount_details from './components/company/report/Sales_item_discount_details';
import Sales_purchase_by_party from './components/company/report/Sales_purchase_by_party';
import SaleTransaction from './components/company/report/SaleTransaction';
import PurchaseTransaction from './components/company/report/PurchaseTransaction';
import Loan_account_stmt from './components/company/report/Loan_account_stmt';
import Ewaybill_report from './components/company/report/Ewaybill_report';
import GSTR3b from './components/company/report/GSTR3B';
import GSTR9 from './components/company/report/GSTR9';
import Stocksummary from './components/company/report/stocksummary';
import Daybook from './components/company/report/daybook';
import Sales_by_hsn from './components/company/report/sales_by_hsn';
import AllPartiesReports from './components/company/report/AllPartiesReports';
import Purchase_order_by_vendor from './components/company/report/Purchase_order_by_vendor';
import Salesorderbycustomer from './components/company/report/Salesorderbycustomer';
import LoanHolderlist from './components/company/loan_holder/loanholderlist';
import Addholderloan from './components/company/loan_holder/addholderloan';
import ViewLoanHolder from './components/company/loan_holder/viewloanholder';
import LoanHolderHistory from './components/company/loan_holder/loanholderhistory';
import LoanRepayment from './components/company/loan_holder/loanrepayment';
import AdditionalLoan from './components/company/loan_holder/loanadditional';
import LoanAdditionalHistory from './components/company/loan_holder/loanaddhistory';
import LoanAccountEdit from './components/company/loan_holder/loanaccountedit';
import AdditionalLoanEdit from './components/company/loan_holder/additionalLoanEdit';
import EditLoanRepayment from './components/company/loan_holder/repaymentLoanEdit';
import Outstanding_receivable from './components/company/report/Outstanding_receivable';
import Outstanding_payable from './components/company/report/Outstanding_payable';
import HorizontalProfitAndLoss from './components/company/report/HorizontalProfitAndLoss';
import VerticalProfitAndLoss from './components/company/report/VerticalProfitAndLoss';
import Horizontal_balance_sheet from './components/company/report/Horizontal_balance_sheet';
import Vertical_balance_sheet from './components/company/report/Vertical_balance_sheet';
import ReceiptNote from './components/company/receiptnote/ReceiptNote';
import AddReceiptNote from './components/company/receiptnote/AddReceiptNote';
import ViewReceiptNote from './components/company/receiptnote/ViewReceiptNote';
import ReceiptNoteHistory from './components/company/receiptnote/ReceiptNoteHistory';
import EditReceiptNote from './components/company/receiptnote/EditReceiptNote';
import ConvertReceiptToBill from './components/company/receiptnote/ConvertReceiptToBill';
import ConvertReceiptToRecBill from './components/company/receiptnote/ConvertReceiptToRecBill';
import EwayBill from './components/company/Eway-bill/Eway_bill_Home';
import Create_EwayBill from './components/company/Eway-bill/Create_EwayBill';
import Eway_Bill_Overview from './components/company/Eway-bill/Eway_Bill_overview';
import Eway_Bill_History from './components/company/Eway-bill/Eway_bill_history';
import Edit_Eway_Bill from './components/company/Eway-bill/Edit_Eway_bill';

function App() {
  return (
  <>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}></Route>
          <Route path="/company_registration" element={<CompanyReg />}></Route>
          <Route path="/Company_Registration2" element={<CompanyReg2 />}></Route>
          <Route path="/modules_list" element={<Modules />}></Route>
          <Route path="/wrong" element={<Wrong />}></Route>
          <Route path="/term_update_modules" element={<TermUpdateModules />}></Route>

          <Route path="/distributor_registration" element={<DistributorReg />}></Route>
          <Route path="/distributor_registration2" element={<DistributorReg2 />}></Route>
          
          <Route path="/staff_registration" element={<StaffReg />}></Route>
          <Route path="/staff_registration2" element={<StaffReg2 />}></Route>
          
          <Route element={<AdminPrivateRoutes />}>
            <Route path="/admin_home" element={<AdminHome />}></Route>
            <Route path="/admin_notifications" element={<AdminNotifications />}></Route>
            <Route path="/payment_terms" element={<PaymentTerms />}></Route>
            <Route path="/distributors" element={<Distributors />}></Route>
            <Route path="/clients" element={<Clients />}></Route>
            <Route path="/all_distributors" element={<AllDistributors />}></Route>
            <Route path="/distributors_requests" element={<DistributorsReq />}></Route>
            <Route path="/distributors_request_overview/:id/" element={<DistributorReqOverview />}></Route>
            <Route path="/all_distributors_overview/:id/" element={<AllDistributorsOverview />}></Route>
            <Route path="/clients_requests" element={<ClientsReq />}></Route>
            <Route path="/all_clients" element={<AllClients />}></Route>
            <Route path="/client_request_overview/:id/" element={<ClientReqOverview />}></Route>
            <Route path="/all_clients_overview/:id/" element={<AllClientsOverview />}></Route>
            <Route path="/anotification_overview/:id/" element={<NotificationOverview />}></Route>
            <Route path="/term_extension_requests" element={<TermExtensionRequests />}></Route>
          </Route>
          <Route element={<DistributorPrivateRoutes />}>
            <Route path="/distributor_home" element={<DistributorHome />}></Route>
            <Route path="/distributor_notifications" element={<DistNotifications />}></Route>
            <Route path="/distributor_profile" element={<DistributorProfile />}></Route>
            <Route path="/edit_distributor_profile" element={<DistributorProfileEdit />}></Route>
            <Route path="/DClient_req" element={<DClientReq />}></Route>
            <Route path="/DClients" element={<DAllClients />}></Route>
            <Route path="/DClient_request_overview/:id/" element={<DClientReqOverview />}></Route>
            <Route path="/DClient_overview/:id/" element={<DClientOverview />}></Route>
            <Route path="/dnotification_overview/:id/" element={<DistNotificationOverview />}></Route>
          </Route>

          <Route element={<CompanyPrivateRoutes />}>
            <Route path="/staff_requests" element={<StaffReq />}></Route>
            <Route path="/all_staffs" element={<AllStaffs />}></Route>
            <Route path="/edit_company_profile" element={<EditCompanyProfile />}></Route>
            <Route path="/edit_modules" element={<EditModules />}></Route>
          </Route>

          <Route element={<CompanyStaffPrivateRoutes />}>
            <Route path="/company_home" element={<CompanyHome />}></Route>
            <Route path="/company_profile" element={<CompanyProfile />}></Route>
            <Route path="/edit_staff_profile" element={<EditStaffProfile />}></Route>

            {/* Items */}
            <Route path="/items" element={<Items />}></Route>
            <Route path="/add_item" element={<AddItem />}></Route>
            <Route path="/view_item/:itemId/" element={<ViewItem />}></Route>
            <Route path="/item_history/:itemId/" element={<ItemHistory />}></Route>
            <Route path="/edit_item/:itemId/" element={<EditItem />}></Route>

            {/* Customers */}
            <Route path="/customers" element={<Customers />}></Route>
            <Route path="/add_customer" element={<AddCustomer />}></Route>
            <Route path="/view_customer/:customerId/" element={<ViewCustomer />}></Route>
            <Route path="/customer_history/:customerId/" element={<CustomerHistory />}></Route>
            <Route path="/edit_customer/:customerId/" element={<EditCustomer />}></Route>

            {/* Price List */}
            <Route path="/price_list" element={<PriceList />}></Route>
            <Route path="/add_price_list" element={<AddPriceList />}></Route>
            <Route path="/view_price_list/:priceListId/" element={<ViewPriceList />}></Route>
            <Route path="/price_list_history/:priceListId/" element={<PriceListHistory />}></Route>
            <Route path="/edit_price_list/:priceListId/" element={<EditPriceList />}></Route>

            {/* Chart of Accounts */}
            <Route path="/chart_of_accounts" element={<ChartOfAccounts />}></Route>
            <Route path="/add_account" element={<AddAccount />}></Route>
            <Route path="/view_account/:accountId/" element={<ViewAccount />}></Route>
            <Route path="/account_history/:accountId/" element={<AccountHistory />}></Route>
            <Route path="/edit_account/:accountId/" element={<EditAccount />}></Route>

            {/* Banking */}
            <Route path="/banking" element={<Banking />}></Route>
            <Route path="/add_bank" element={<AddBank />}></Route>
            <Route path="/view_bank/:bankId/" element={<ViewBank />}></Route>
            <Route path="/bank_transaction_history/:transactionId/" element={<BankTransactionHistory />}></Route>
            <Route path="/edit_bank/:bankId/" element={<EditBank />}></Route>
            <Route path="/edit_transaction/:transId/" element={<EditTransactions />}></Route>
            <Route path="/bank_to_cash/:bankId/" element={<BankToCash />}></Route>
            <Route path="/cash_to_bank/:bankId/" element={<CashToBank />}></Route>
            <Route path="/bank_to_bank/:bankId/" element={<BankToBank />}></Route>
            <Route path="/bank_adjust/:bankId/" element={<BankAdjust />}></Route>

            {/* Employee */}
            <Route path="/employee" element={<Employee />}></Route>
            <Route path="/add_employee" element={<AddEmployee />}></Route>
            <Route path="/employeeoverview/:itemId/" element={<Employeeoverview />}></Route>
            <Route path="/employee_history/:itemId/" element={<EmployeeHistory />}></Route>
            <Route path="/edit_employee/:itemId/" element={<EditEmployee />}></Route>

            {/* Stock Adjustment */}
            <Route path="/stock_adjust" element={<StockAdjustment />}></Route>
            <Route path="/add_stock_adjust" element={<AddStockAdjust />}></Route>
            <Route path="/view_stock_adjust/:stockId/" element={<ViewStockAdjust />}></Route>
            <Route path="/stock_adjust_history/:stockId/" element={<StockAdjustHistory />}></Route>
            <Route path="/edit_stock_adjust/:stockId/" element={<EditStockAdjust />}></Route>

            {/* Sales Order */}
            <Route path="/sales_order" element={<SalesOrder />}></Route>
            <Route path="/add_sales_order" element={<AddSalesOrder />}></Route>
            <Route path="/view_sales_order/:salesId/" element={<ViewSalesOrder />}></Route>
            <Route path="/sales_order_history/:salesId/" element={<SalesOrderHistory />}></Route>
            <Route path="/edit_sales_order/:salesId/" element={<EditSalesOrder />}></Route>
            <Route path="/convert_sales_order_to_invoice/:salesId/" element={<ConvertSalesOrderToInvoice />}></Route>
            <Route path="/convert_sales_order_to_rec_invoice/:salesId/" element={<ConvertSalesOrderToRecInvoice />}></Route>

            {/* Vendors */}
            <Route path="/vendors" element={<Vendors />} />
            <Route path="/add_vendor" element={<Addvendor/>} />
            <Route path="/view_vendor/:id/" element={<View_vendor />} />
            <Route path="/edit_vendor/:id/" element={<Edit_vendor />} />
            <Route path="/vendor_history/:id/" element={<Vendorhistory />} />

            {/* Bank holders */}
            <Route path="/banklist" element={<Banklist />}></Route>
            <Route path="/add_bankholder" element={<Addbankholder />}></Route>
            <Route path="/viewholder/:holderId/" element={<Viewholder />}></Route>
            <Route path="/bankhistory/:holderId/" element={<BankHistory />}></Route>
            <Route path="/editholder/:holderId/" element={<Editholder />}></Route>

            {/* Invoice */}
            <Route path="/invoice" element={<Invoice />}></Route>
            <Route path="/add_invoice" element={<AddInvoice />}></Route>
            <Route path="/view_invoice/:invoiceId/" element={<ViewInvoice />}></Route>
            <Route path="/invoice_history/:invoiceId/" element={<InvoiceHistory />}></Route>
            <Route path="/edit_invoice/:invoiceId/" element={<EditInvoice />}></Route>
            
            {/* Delivery Challan */}
            <Route path="/delivery_challan" element={<DeliveryChallan />}></Route>
            <Route path="/add_delivery_challan" element={<AddDeliveryChallan />}></Route>
            <Route path="/view_delivery_challan/:challanId/" element={<ViewChallan />}></Route>
            <Route path="/delivery_challan_history/:challanId/" element={<ChallanHistory />}></Route>
            <Route path="/edit_delivery_challan/:challanId/" element={<EditDeliveryChallan />}></Route>
            <Route path="/convert_challan_to_invoice/:challanId/" element={<ConvertChallanToInvoice />}></Route>
            <Route path="/convert_challan_to_rec_invoice/:challanId/" element={<ConvertChallanToRecInvoice />}></Route>

            {/* Estimate */}
            <Route path="/Estimate" element={<Estimate />}></Route>
            <Route path="/AddEstimate" element={<AddEstimate />}></Route>
            <Route path="/ViewEstimate/:estimateId/" element={<ViewEstimate />}></Route>
            <Route path="/EstimateHistory/:estimateId/" element={<EstimateHistory />}></Route>
            <Route path="/EditEstimate/:estimateId/" element={<EditEstimate />}></Route>
            <Route path="/convert_estimate_to_sales_order/:estimateId/" element={<ConvertEstimateToSalesOrder />}></Route>
            <Route path="/convert_estimate_to_invoice/:estimateId/" element={<ConvertEstimateToInvoice />}></Route>
            <Route path="/convert_estimate_to_rec_invoice/:estimateId/" element={<ConvertEstimateToRecInvoice />}></Route>

            {/* Recurring Invoice */}
            <Route path="/rec_invoice" element={<RecInvoice />}></Route>
            <Route path="/add_rec_invoice" element={<AddRecInvoice />}></Route>
            <Route path="/view_rec_invoice/:invoiceId/" element={<ViewRecInvoice />}></Route>
            <Route path="/rec_invoice_history/:invoiceId/" element={<RecInvoiceHistory />}></Route>
            <Route path="/edit_rec_invoice/:invoiceId/" element={<EditRecInvoice />}></Route>

            {/* Retainer Invoice */}
            <Route path="/ret_invoice" element={<RetInvoice />}></Route>
            <Route path="/add_ret_invoice" element={<AddRetInvoice />}></Route>
            <Route path="/view_ret_invoice/:invoiceId/" element={<ViewRetInvoice />}></Route>
            <Route path="/ret_invoice_history/:invoiceId/" element={<RetInvoiceHistory />}></Route>
            <Route path="/edit_ret_invoice/:invoiceId/" element={<EditRetInvoice />}></Route>

            {/* Purchase Order */}
            <Route path="/view_purchase_order/:id" element={<View_purchase_order />} />
            <Route path="/purchase_order" element={<Purchase_order />} />
            <Route path="/add_purchase_order" element={<Add_Purchase_Order />} />
            <Route path="/edit_purchase_order/:id" element={<Edit_Purchase_Order />} />
            <Route path="/purchase_order_history/:id" element={<Purchase_order_History />} />

            {/* Credit Note */}
            <Route path="/credit_note" element={<CreditNote />}></Route>
            <Route path="/add_credit_note" element={<AddCreditNote />}></Route>
            <Route path="/view_credit_note/:creditNoteId/" element={<ViewCreditNote />}></Route>
            <Route path="/credit_note_history/:creditNoteId/" element={<CreditNoteHistory />}></Route>
            <Route path="/edit_credit_note/:creditNoteId/" element={<EditCreditNote />}></Route>

            {/* Payment Received */}
            <Route path="/payment_received" element={<PaymentReceived />}></Route>
            <Route path="/add_payment" element={<AddPaymentReceived />}></Route>
            <Route path="/view_payment_received/:paymentId/" element={<ViewPayment />}></Route>
            <Route path="/payment_received_history/:paymentId/" element={<PaymentHistory />}></Route>
            <Route path="/edit_payment_received/:paymentId/" element={<EditPaymentReceived />}></Route>

            {/* Recurring Bills */}
            <Route path="/rec_bill" element={<RecBill />}></Route>
            <Route path="/add_rec_bill" element={<AddRecBill />}></Route>
            <Route path="/view_rec_bill/:billId/" element={<ViewRecBill />}></Route>
            <Route path="/rec_bill_history/:billId/" element={<RecBillHistory />}></Route>
            <Route path="/edit_rec_bill/:billId/" element={<EditRecBill />}></Route>

            {/* Expense */}
            <Route path="/expense" element={<Expense />}></Route>
            <Route path="/add_expense" element={<AddExpense />}></Route>
            <Route path="/view_expense/:expenseId/" element={<ViewExpense />}></Route>
            <Route path="/expense_history/:expenseId/" element={<ExpenseHistory />}></Route>
            <Route path="/edit_expense/:expenseId/" element={<EditExpense />}></Route>

            {/* Bill */}
            <Route path="/Bill" element={<Bill />}></Route>
            <Route path="/AddBill" element={<AddBill />}></Route>
            <Route path="/ViewBill/:billId/" element={<ViewBill />}></Route>
            <Route path="/BillHistory/:billId/" element={<BillHistory />}></Route>
            <Route path="/EditBill/:billId/" element={<EditBill />}></Route>

            {/* Debit Note */}
            <Route path="/debit_note" element={<DebitNote />}></Route>
            <Route path="/add_debit_note" element={<AddDebitNote />}></Route>
            <Route path="/view_debit_note/:debitNoteId/" element={<ViewDebitNote />}></Route>
            <Route path="/debit_note_history/:debitNoteId/" element={<DebitNoteHistory />}></Route>
            <Route path="/edit_debit_note/:debitNoteId/" element={<EditDebitNote />}></Route>

            {/* Payment Made */}
            <Route path="/payment_made" element={<PaymentMade />}></Route>
            <Route path="/add_payment_made" element={<AddPaymentMade />}></Route>
            <Route path="/view_payment_made/:paymentId/" element={<ViewPaymentMade />}></Route>
            <Route path="/payment_made_history/:paymentId/" element={<PaymentMadeHistory />}></Route>
            <Route path="/edit_payment_made/:paymentId/" element={<EditPaymentMade />}></Route>

            {/* Attendence */}
            <Route path="/attendence" element={<Attendence />} />
            <Route path="/add_attendence" element={<Add_Attendece />} />
            <Route path="/view_attendence/:id/:mn/:yr/" element={<View_Attendence />} />
            <Route path="/edit_attendence/:id/:mn/:yr/:Id/" element={<Edit_Attendence />} />
            <Route path="/attendence_history/:id/:mn/:yr/:Id/" element={<Attendence_history />} />

            {/* Salary Details */}
            <Route path="/salary_details" element={<SalaryDetails />}></Route>
            <Route path="/add_salary_details" element={<AddSalaryDetails />}></Route>
            <Route path="/view_salary_details/:salaryId/" element={<ViewSalaryDetails />}></Route>
            <Route path="/salary_details_history/:salaryId/" element={<SalaryDetailsHistory />}></Route>
            <Route path="/edit_salary_details/:salaryId/" element={<EditSalaryDetails />}></Route>

            {/* Invioce Report*/}
            <Route path="/invoice_report" element={<InvoiceReport />}></Route>

            {/* Recurring Invioce Report*/}
            <Route path="/rec_invoice_report" element={<RecInvoiceReport />}></Route>

            {/* reports */}
            <Route path="/salesorderreport" element={<Salesorderreport />}></Route>
            <Route path="/estimatereport" element={<Estimatereport />}></Route>

            {/* EMPLOYEE LOAN */}
            <Route path="/loanlist" element={<Loanlist />}></Route>
            <Route path="/addloan" element={<Addloan />}></Route>
            <Route path="/viewloan/:itemId/" element={<ViewLoan />}></Route>
            <Route path="/loanhistory/:loanId/" element={<LoanHistory />}></Route>
            <Route path="/repayment/:loanId/" element={<Repayment />}></Route>
            <Route path="/newloan/:loanId/" element={<Newloan />}></Route> 
            <Route path="/repaymenthistory/:rpayId/:loanId/" element={<RepaymentHistory />}></Route> 
            <Route path="/additionalhistory/:addId/:loanId/" element={<AdditionalHistory />}></Route> 
            <Route path="/repaymentedit/:rpayId/:loanId/" element={<RepaymentEdit />}></Route> 
            <Route path="/additionaledit/:addId/:loanId/" element={<Additionaledit />}></Route>
            <Route path="/loanedit/:loanId/" element={<Loanedit />}></Route> 

            {/* Reports(Made,Recieved-payment) */}
            <Route path="/payment_recieved_summary" element={<Payment_Received_Summary />} />
            <Route path="/payment_made_summary" element={<Payment_Made_summary />} />

            {/* SalesByCustomer */}
            <Route path="/Sales_by_customer" element={<SalesByCustomer />}></Route>
            
            {/* SalesByItem */}
            <Route path="/Sales_by_item" element={<SalesByItem />}></Route>

            {/* Payment Recieved Details */}
            <Route path="/payment_recieved_details" element={<Payment_Recieved_Details />} />

            {/* Payment Made Details */}
            <Route path="/payment_made_details" element={<Payment_Made_Details />} />

            {/* Retainer Invoice  Details */}
            <Route path="/retainer_invoice_details" element={<RetainerInvoiceDetails />}></Route>

            {/* Creditnote  Details */}
            <Route path="/credit_note_details" element={<CreditNoteDetails />}></Route>

            {/* PurchaseItemReport && SalesItemReport */}
            <Route path="/PurchaseItem" element={<PurchaseItemReport />}></Route>
            <Route path="/SalesItem" element={<SalesItemReport />}></Route>

            {/* purchase by vendor */}
            <Route path="/purchase_by_vendor" element={<Purchase_By_Vendor />}></Route>

            {/* purchase by item */}
            <Route path="/purchase_by_item" element={<Purchase_By_Item />}></Route>

            {/* Employee Loan Statement Report */}
            <Route path="/employee_loan_statement" element={<Employee_Loan_Statement />} />

            {/* Account Type Summary */}
            <Route path="/account_type_summary" element={<Account_Type_Summary />} />
            
            {/* Purchase order details */}
            <Route path="/Purchase_order_details" element={<Purchase_order_details />}></Route>

            {/* Delivery chellan report */}
            <Route path="/Delivery_chellan_report" element={<Delivery_chellan_report />}></Route>

            {/* customer balance  && vendor balance - report */}
            <Route path="/customer_balance_report" element={<CustomerBalanceReport />}></Route>
            <Route path="/vendor_balance_report" element={<VendorBalanceReport />}></Route>

            {/* Debitnotereport  && Expensereport */}
            <Route path="/debitnotereport" element={<Debitnotereport />}></Route>
            <Route path="/expensereport" element={<Expensereport />}></Route>

            {/* Bill Report*/}
            <Route path="/bill_report" element={<BillReport />}></Route>

            {/* Recurring Bill Report*/}
            <Route path="/rec_bill_report" element={<RecBillReport />}></Route>

            {/* UPI */}
            <Route path="/UPIPayments" element={<UPIPayments />}></Route>
            <Route path="/UPIStatement" element={<UPIStatement/>}></Route>

            {/* Cheque */}
            <Route path="/ChequePayments" element={<ChequePayments />}></Route>
            <Route path="/ChequeStatement" element={<ChequeStatement />}></Route>

            {/* Holiday */}
            <Route path="/holiday" element={<Holiday />} />
            <Route path="/add_holiday" element={<Add_Holiday />} />
            <Route path="/view_holidays/:ID/:month/:year" element={<ViewHolidays />} />
            <Route path="/edit_holidays/:holidayId" element={<Edit_Holiday />} />
            <Route path="/holiday_history/:holidayId" element={<HolidayHistoryPage/>} />

            {/* ManualJournal */}
            <Route path="/manualjournal" element={<ManualJournal />} />
            <Route path="/manualjournalcreation" element={<ManualJournalCreation />} />
            <Route path="/view_manual_journal/:journalId/" element={<ViewManualJournal />} />
            <Route path="/edit_manual_journal/:journalId/" element={<EditManualJournal />} />
            <Route path="/manual_journal_history/:journalId/" element={<ManualJournalHistory />} />

            {/* Alltransactions */}
            <Route path="/Alltransactions" element={<Alltransactions />}></Route>

            {/* Partystatement */}
            <Route path="/Partystatement" element={<Partystatement />}></Route>

            {/* Stock_details */}
            <Route path="/stock_details" element={<Stock_details />}></Route>
            <Route path="/low_stock_details" element={<Low_stocks_details />}></Route>

            <Route path="/convert_purchase_order_to_bill/:purchaseId/" element={<Convert_porder_to_bill />}></Route>
            <Route path="/convert_purchase_order_to_rbill/:billId/" element={<Convert_porder_to_rbill />}></Route>

            {/* Agingsummary */}
            <Route path="/agingsummary" element={<Agingsummary />}></Route>
            <Route path="/agingdetails" element={<AgingDetails />}></Route>

            {/* GSTR */}
            <Route path="/gstr1" element={<GSTR1 />}></Route>
            <Route path="/gstr2" element={<GSTR2 />}></Route>

            {/* StockValuationSummary */}
            <Route path="/Stock_val_summary" element={<Stock_val_summary />}></Route>
            
            {/* Bankstatement */}
            <Route path="/bankstatement" element={<Bankstatement />}></Route>

            <Route path="/Journalreport" element={<Journalreport />}></Route>
            <Route path="/discountreport" element={<DiscountReport />}></Route>

            {/* Cashflow */}
            <Route path="/Cashflow" element={<Cashflow />}></Route>

            {/* item report by party, party report by item */}
            <Route path="/item_report_by_party" element={<ItemReportByParty/>}></Route>
            <Route path="/party_report_by_item" element={<PartyReportByItem/>}></Route>

            <Route path="/cashinhand" element={<CashInHand />}></Route>
            <Route path="/addcash" element={<AddCash />}></Route>
            <Route path="/cashinhandstatement" element={<CashInHandStatement />}></Route>
            <Route path="/cashinhand_graph" element={<Cashinhand_Graph />}></Route>
            <Route path="/edit_cashinhand/:id" element={<Edit_cashinhand />} />
            <Route path="/cashinhand_history/:id" element={<Cashinhand_history />} />

            {/* Trialbalance */}
            <Route path="/Trialbalance" element={<Trialbalance />}></Route>

            <Route path="/Sales_item_discount_details" element={<Sales_item_discount_details />}></Route>
            <Route path="/Sales_purchase_by_party" element={<Sales_purchase_by_party />}></Route>

            <Route path="/saletransaction" element={<SaleTransaction />}></Route>
            <Route path="/purchasetransaction" element={<PurchaseTransaction />}></Route>

            <Route path="/Loan_account_stmt" element={<Loan_account_stmt />}></Route>
            <Route path="/Eway_bill_report" element={<Ewaybill_report />}></Route>

            <Route path="/gstr3b" element={<GSTR3b />}></Route>
            <Route path="/gstr9" element={<GSTR9 />}></Route>

            {/* stocksummary */}
            <Route path="/stocksummary" element={<Stocksummary />}></Route>

            {/* daybook */}
            <Route path="/daybook" element={<Daybook />}></Route>
            <Route path="/sales_by_hsn" element={<Sales_by_hsn />}></Route>
            
            {/* All Parties */}
            <Route path="/all_parties_report" element={<AllPartiesReports />}></Route>
            
            <Route path="/Purchase_order_by_vendor" element={<Purchase_order_by_vendor />}></Route>
            <Route path="/Salesorderbycustomer" element={<Salesorderbycustomer />}></Route>

            {/*  loan holder */}
            <Route path="/loanholderlist" element={<LoanHolderlist />}></Route>
            <Route path="/addholderloan" element={<Addholderloan />}></Route>
            <Route path="/viewloanholder/:itemId/" element={<ViewLoanHolder />}></Route>
            <Route path="/loanholderhistory/:loanId/" element={<LoanHolderHistory />}></Route>
            <Route path="/loanrepayment/:loanId/" element={<LoanRepayment />}></Route>
            <Route path="/loanadditional/:loanId/" element={<AdditionalLoan />}></Route>
            <Route path="/loanaddhistory/:addId/:loanId/" element={<LoanAdditionalHistory />}></Route> 
            <Route path="/loanaccountedit/:loanId/" element={<LoanAccountEdit />}></Route>
            <Route path="/additionalLoanEdit/:loanId/" element={<AdditionalLoanEdit />}></Route>
            <Route path="/repaymentLoanEdit/:loanId/" element={<EditLoanRepayment />}></Route>

            <Route path="/outstandingreceivable" element={<Outstanding_receivable />}></Route>
            <Route path="/outstanding_payable" element={<Outstanding_payable />}></Route>

            {/* profit and loss */}
            <Route path="/horizontal_profit_and_loss" element={<HorizontalProfitAndLoss/>}></Route>
            <Route path="/vertical_profit_and_loss" element={<VerticalProfitAndLoss/>}></Route>

            <Route path="/Horizontal_balance_sheet" element={<Horizontal_balance_sheet />}></Route>
            <Route path="/Vertical_balance_sheet" element={<Vertical_balance_sheet />}></Route>
            
            {/* Receipt note */}
            <Route path="/receipt_note" element={<ReceiptNote />}></Route>
            <Route path="/add_receipt" element={<AddReceiptNote />}></Route>
            <Route path="/view_receipt_note/:receiptId/" element={<ViewReceiptNote />}></Route>
            <Route path="/receipt_note_history/:receiptId/" element={<ReceiptNoteHistory />}></Route>
            <Route path="/edit_receipt_note/:receiptId/" element={<EditReceiptNote />}></Route>
            <Route path="/convert_receipt_to_bill/:receiptId/" element={<ConvertReceiptToBill />}></Route>
            <Route path="/convert_receipt_to_rec_bill/:receiptId/" element={<ConvertReceiptToRecBill />}></Route>

            {/* Eway-Bill */}
            <Route path="/Eway_bill" element={<EwayBill />} />
            <Route path="/create_eway_bill" element={<Create_EwayBill />} />
            <Route path="/ewaybill_overview/:id/" element={<Eway_Bill_Overview />} />
            <Route path="/eway_bill_history/:id/" element={<Eway_Bill_History />} />
            <Route path="/edit_eway_bill/:id" element={<Edit_Eway_Bill />} />

          </Route>
        </Routes>
      </BrowserRouter>
  </>
  )
}

export default App;
