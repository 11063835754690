import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import html2pdf from "html2pdf.js";
import axios from "axios";
import * as XLSX from "xlsx";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/salesorderreport.css";
  


function Horizontal_balance_sheet() {

  const ExpandableRow = ({ title, amount1, amount2, children, headerStyle, contentStyle }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <>
            <tr
                style={{  ...headerStyle }}
                onClick={() => setIsExpanded(!isExpanded)}
            >
                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>{title}</td>
                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{amount1}</td>
                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{amount2}</td>
            </tr>
            {isExpanded && (
                <>
                    {React.Children.map(children, child =>
                        React.cloneElement(child, { style: contentStyle })
                    )}
                </>
            )}
        </>
    );
};
  const ID = Cookies.get("Login_id");

  const [reportData, setReportData] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [report, setReport] = useState("");
  const [expandedRows, setExpandedRows] = useState({});

  const handleToggle = (index) => {
      setExpandedRows(prev => ({
          ...prev,
          [index]: !prev[index],
      }));
  };
 
  
  
  const fetchInvoiceReportDetails = () => {
    axios
      .get(`${config.base_url}/Fin_fetch_horizontal_balance_sheet_details/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");
          setStatus("");
          setReport("");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchInvoiceReportDetails();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  const handleCustomize = (e) => {
    e.preventDefault();

    axios
        .post(`${config.base_url}/Fin_fetch_horizontal_balance_sheet_details_customized/${ID}/`, {
            start_date: startDate,
            end_date: endDate,
        })
        .then((res) => {
            console.log("REPORT DATA=", res.data);

            setReportData(res.data.reportData);
            setOtherDetails(res.data.otherDetails);
            setStartDate(res.data.startDate);
            setEndDate(res.data.endDate);
            setReport(res.data.report);

            var contentDiv = document.getElementById("contentDiv");
            if (contentDiv.style.display === "block") {
                toggleContent();
            }
        })
        .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
                Swal.fire({
                    icon: "error",
                    title: `${err.response.data.message}`,
                });
            }
        });
};


  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Report_Horizontal_Balance_Sheet" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Report_Horizontal_Balance_Sheet" + date + ".xlsx");
  }

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var stat = status;
    var rpt = report;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            status: stat,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/Fin_share_horizontal_balance_sheet_details_To_Email/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
            }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
   
  }
  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }
  
  return (
    <>
            
      <FinBase />
      <div
        className="page-content mt-0 pt-0"
        id="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <center>
                <h2 className="text-uppercase" id="headline">
                Horizontal Balance Sheet
                </h2>
              </center>
              <hr />
            </div>

            <div className="bar">
              <div className=" left d-flex justify-content-start">
                <div className="position-relative mr-2">
                  <button
                    className="btn btn-secondary"
                    onClick={toggleContent}
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    <i className="fas fa-solid fa-gear"></i> Customize Report
                  </button>
                  <div id="contentDiv" className="salescontent">
                    <h6>Customize Report</h6>
                    <form
                      onSubmit={handleCustomize}
                      className="form reportCustomizeForm px-1"
                      method="get"
                    >
                      <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>From</label>
                        <br />
                        <input
                          className="inputdate form-control"
                          type="date"
                          name="start_date"
                          id="startDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required={endDate != "" ? true : false}
                        />
                      </div>
                      <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>To</label>
                        <br />
                        <input
                          type="date"
                          className="inputdate form-control"
                          name="end_date"
                          id="endDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required={startDate != "" ? true : false}
                        />
                      </div>
                    
                      {/* <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>Status</label>
                        <br />
                        <select
                          name="status"
                          id="salesStatus"
                          className="form-control"
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          
                          <option value="all">All</option>
                          <option value="saved">Saved</option>
                          <option value="draft">Draft</option>
                          <option value="bill">Converted to Bill</option>
                          <option value="recurring_bill">Converted to Rec. Bill</option>
                        </select>
                      </div> */}
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="submit"
                          className="btn btn-outline-light w-50"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Run Report
                        </button>
                        <button
                          type="reset"
                          onClick={toggleContent}
                          className="btn btn-outline-light ml-1 w-50"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="right d-flex">
                <a
                  className="btn btn-outline-secondary text-grey fa fa-file"
                  role="button"
                  id="pdfBtn"
                  onClick={reportPDF}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  {" "}
                  &nbsp;PDF
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                  role="button"
                  id="printBtn"
                  onClick={printSection}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Print
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
                  role="button"
                  id="exportBtn"
                  onClick={ExportToExcel}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Export
                </a>
                <div className="dropdown p-0 nav-item" id="shareBtn">
                  <li
                    className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                    data-toggle="dropdown"
                    style={{
                      height: "fit-content",
                      width: "fit-content",
                    }}
                  >
                    &nbsp;Share
                  </li>
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "black" }}
                    id="listdiv"
                  >
                    <a
                      href={shareUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li
                        style={{
                          textAlign: "center",
                          color: "#e5e9ec",
                          cursor: "pointer",
                        }}
                      >
                        WhatsApp
                      </li>
                    </a>
                    <li
                      style={{
                        textAlign: "center",
                        color: "#e5e9ec",
                        cursor: "pointer",
                      }}
                      data-toggle="modal"
                      data-target="#shareToEmail"
                    >
                      Email
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card radius-15 print-only" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">
              <div
                id="printReport"
                className="printReportTemplate"
                style={{ display: "block " }}
              >
                <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div className="py-4 bg-secondary">
                      <div className="col-12">
                        <center className="h5 text-white">
                          <b>{otherDetails.cmpName}</b>
                        </center>
                        <center className="h3 text-white">
                          <b> Horizontal Balance Sheet</b>
                        </center>
                        {startDate != "" && endDate != "" ? (
                          <center className="h6 text-white">
                            {startDate} {"TO"} {endDate}
                          </center>
                        ) : null}
                      </div>
                    </div>
                    <div className="row px-1 py-1">
                      <div className="col-12">
                        <section className="product-area mt-2 py-1">
                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            
            
        </div> 


        <div id="reportTable" style={{ display: 'flex', justifyContent: 'space-between' }}>
            

{/* <table style={{ border: '1px solid #dddddd', width: '50%', color: 'black', fontSize: 'medium' }}>
  <thead>
    <tr>
      <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>LIABILITIES</th>
      <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>AMOUNT</th>
      <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>AMOUNT</th>
    </tr>
  </thead>
  <tbody>
    {reportData.length > 0 && reportData.map((data, index) => (
      <React.Fragment key={index}>
        <ExpandableRow 
          title="CAPITAL ACCOUNT" 
          amount2="0.00" 
          headerStyle={{ fontWeight: '700', fontSize: 'medium' }} 
          contentStyle={{ opacity: 0.7, fontSize: 'small' }}
        >
          <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>DRAWINGS OF OWNER</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">0.00</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
          </tr>
          <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>OWNERS CAPITAL</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">0.00</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
          </tr>
        </ExpandableRow>

        <ExpandableRow 
          title="LOANS (Liability)" 
          amount2={data.total_bank_loan || "0.00"} 
          headerStyle={{ fontWeight: '700', fontSize: 'medium' }} 
          contentStyle={{ opacity: 0.7, fontSize: 'small' }}
        >
          <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>BANK A/C</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{data.total_bank_account || "0.00"}</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
          </tr>
          <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>SECURED LOANS</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{data.total_bank_loan || "0.00"}</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
          </tr>
        </ExpandableRow>

        <ExpandableRow 
          title="CURRENT LIABILITIES" 
          amount2={data.total_sundry_creditors || "0.00"} 
          headerStyle={{ fontWeight: '700', fontSize: 'medium' }} 
          contentStyle={{ opacity: 0.7, fontSize: 'small' }}
        >
          <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>DUTIES AND TERMS</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">0.00</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
          </tr>
          <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>PROVISIONS</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">0.00</td>
            <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
          </tr>
        </ExpandableRow>

        <tr style={{ fontWeight: '600', fontSize: 'medium' }}>
          {data.total_current_asset > data.total_liability ? (
            <>
              <td className="expandable-one">PROFIT</td>
              <td className="expandable-one"></td>
              <td className="expandable-one text-center">{data.difference}</td>
            </>
          ) : (
            <>
              <td className="expandable-one">--</td>
              <td className="expandable-one">--</td>
              <td className="expandable-one text-center">--</td>
            </>
          )}
        </tr>

        <tfoot>
          <tr style={{ fontWeight: '600', fontSize: 'x-large' }}>
            <td>Total</td>
            {data.total_current_asset > data.total_liability ? (
              <>
                <td></td>
                
                <td className="text-center">{data.total_current_asset}</td>
              </>
            ) : (
              <>
                <td></td>
                <td className="text-center">{data.total_liability}</td>
              </>
            )}
          </tr>
        </tfoot>
      </React.Fragment>
    ))}
  </tbody>
</table> */}


<table style={{ border: '1px solid #dddddd', width: '50%', color: 'black', fontSize: 'medium' }}>
  <thead>
    <tr>
      <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>LIABILITIES</th>
      <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>AMOUNT</th>
      <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>AMOUNT</th>
    </tr>
  </thead>
  <tbody>
    {reportData.length > 0 && reportData.map((data, index) => {
      // Default values for safety
      const totalCurrentAsset = data.total_current_asset || 0;
      const totalLiability = data.total_liability || 0;
      const totalBankLoan = data.total_bank_loan || 0;
      const totalBankAccount = data.total_bank_account || 0;
      const totalSundryCreditors = data.total_sundry_creditors || 0;
      const difference = data.difference || 0;

      return (
        <React.Fragment key={index}>
          <ExpandableRow 
            title="CAPITAL ACCOUNT" 
            amount2="0" 
            headerStyle={{ fontWeight: '700', fontSize: 'medium' }} 
            contentStyle={{ opacity: 0.7, fontSize: 'small' }}
          >
            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>DRAWINGS OF OWNER</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">0</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
            </tr>
            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>OWNERS CAPITAL</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">0</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
            </tr>
          </ExpandableRow>

          <ExpandableRow 
            title="LOANS (Liability)" 
            amount2={totalBankLoan} 
            headerStyle={{ fontWeight: '700', fontSize: 'medium' }} 
            contentStyle={{ opacity: 0.7, fontSize: 'small' }}
          >
            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>BANK A/C</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{totalBankAccount}</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
            </tr>
            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>SECURED LOANS</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{totalBankLoan}</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
            </tr>
          </ExpandableRow>

          <ExpandableRow 
            title="CURRENT LIABILITIES" 
            amount2={totalSundryCreditors} 
            headerStyle={{ fontWeight: '700', fontSize: 'medium' }} 
            contentStyle={{ opacity: 0.7, fontSize: 'small' }}
          >
            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>DUTIES AND TERMS</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">0</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
            </tr>
            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>PROVISIONS</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">0</td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
            </tr>
           
            <ExpandableRow 
          title="SUNDRY CREDITORS" 
          amount1={totalSundryCreditors} 
          headerStyle={{ opacity: 0.7, fontSize: 'small' }} 
          contentStyle={{ opacity: 0.7, fontSize: 'small' }}
        >
          {/* Map through the vendors under Sundry Creditors */}
          {data.vendors.map((vendor, vendorIndex) => (
            <tr key={vendorIndex} className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>
                {vendor.First_name} {vendor.Last_name}
              </td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">
                {vendor.total_balance}
              </td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
            </tr>
          ))}
        </ExpandableRow>
          </ExpandableRow>

          <tr style={{ fontWeight: '600', fontSize: 'medium' }}>
            {totalCurrentAsset > totalLiability ? (
              <>
                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="expandable-one">PROFIT</td>
                <td className="expandable-one"></td>
                <td className="expandable-one text-center">{difference}</td>
              </>
            ) : (
              <>
                <td className="expandable-one">--</td>
                <td className="expandable-one">--</td>
                <td className="expandable-one text-center">--</td>
              </>
            )}
          </tr>
        </React.Fragment>
      );
    })}
  </tbody>

  <tfoot>
    <tr style={{ fontWeight: 600, fontSize: 'x-large' }}>
      <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>Total</td>
      <td></td>
      <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">
        {reportData.reduce((total, item) => {
          return item.total_current_asset > item.total_liability 
            ? total + parseFloat(item.total_current_asset || 0) 
            : total + parseFloat(item.total_liability || 0);
        }, 0)}
      </td>
    </tr>
  </tfoot>
</table>


            <table  style={{ border: '1px solid #dddddd', width: '50%', color: 'black', fontSize: 'medium' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>ASSETS</th>
                        <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>AMOUNT</th>
                        <th style={{ border: '1.5px solid #dddddd', padding: '8px' }}>AMOUNT</th>
                    </tr>
                </thead>
      
                  <tbody>
            {reportData.length > 0 &&
                reportData.map((data, index) => (
                    <React.Fragment key={index}>
                        {/* Fixed Assets Section */}
                        <ExpandableRow title="FIXED ASSETS" amount1="" amount2={data.fixed_assets_total || "0"} headerStyle={{ fontWeight: "700", fontSize: "medium" }} contentStyle={{ opacity: 0.7, fontSize: 'small' }} >
                            <tr className="hidden-row"style={{ opacity: 0.7, fontSize: 'small' }}>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>COMPUTER</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{data.computer || "0"}</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
                            </tr>
                            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>FURNITURE</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{data.furniture || "0"}</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
                            </tr>
                            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>LAND AND BUILDING</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{data.land_building || "0"}</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
                            </tr>
                            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>MARUTI VAN</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{data.maruti_van || "0"}</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
                            </tr>
                        </ExpandableRow>

                        {/* Investments Section */}
                        <ExpandableRow title="INVESTMENTS" amount1="" amount2={data.investments_total || "0"}  headerStyle={{ fontWeight: "700", fontSize: "medium" }} contentStyle={{ opacity: 0.7, fontSize: 'small' }} >
                            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>COMMODITIES</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">{data.commodities || "0"}</td>
                                <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
                            </tr>
                        </ExpandableRow>

                        {/* Current Assets Section */}
                        <ExpandableRow title="CURRENT ASSETS" amount1="" amount2={data.total_current_asset || "0"}  headerStyle={{ fontWeight: "700", fontSize: "medium",border: '1px solid #dddddd', padding: '8px' }} contentStyle={{ opacity: 0.7, fontSize: 'small' }} >
                            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
                                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>LOANS AND ADVANCES (ASSETS)</td>
                                <td className="text-center" style={{ border: '1px solid #dddddd', padding: '8px' }} >{data.total_loan_advance || "0"}</td>
                                <td style={{ border: '1px solid #dddddd', padding: '8px' }}></td>
                            </tr>
                            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
                                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>CASH-IN-HAND</td>
                                <td className="text-center" style={{ border: '1px solid #dddddd', padding: '8px' }} >{data.total_cashinhand || "0"}</td>
                                <td style={{ border: '1px solid #dddddd', padding: '8px' }}></td>
                            </tr>
                            <tr className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
                                <td style={{ border: '1px solid #dddddd', padding: '8px' }}>BANK ACCOUNTS</td>
                                <td className="text-center" style={{ border: '1px solid #dddddd', padding: '8px' }} >{data.total_bank_account || "0"}</td>
                                <td style={{ border: '1px solid #dddddd', padding: '8px' }}></td>
                            </tr>

                            <ExpandableRow 
                                className="text-center"
                                title="CLOSING STOCK" 
                                amount1={data.total_closing_stock || "0"} 
                                headerStyle={{ opacity: 0.7, fontSize: 'small',border: '1px solid #dddddd', padding: '8px',textAlign:"center" }} 
                                contentStyle={{ opacity: 0.7, fontSize: 'small' }} 
                            >
                                {data.closing_stock.map((item, idx) => (
                                    <tr key={idx} className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px' }}>{item.name}</td>
                                        <td className="text-center" style={{ border: '1px solid #dddddd', padding: '8px' }}>{item.current_stock || "0"}</td>
                                        <td style={{ border: '1px solid #dddddd', padding: '8px' }}></td>
                                    </tr>
                                ))}
                            </ExpandableRow>

                            
                            <ExpandableRow 
          title="SUNDRY DEBTORS" 
          amount1={data.total_sundry_debitors} 
          headerStyle={{ opacity: 0.7, fontSize: 'small' }} 
          contentStyle={{ opacity: 0.7, fontSize: 'small' }}
        >
          {/* Map through the vendors under Sundry Creditors */}
          {data.customers.map((customers, customersIndex) => (
            <tr key={customersIndex} className="hidden-row" style={{ opacity: 0.7, fontSize: 'small' }}>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>
                {customers.first_name} {customers.last_name}
              </td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">
                {customers.current_balance}
              </td>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}></td>
            </tr>
          ))}
        </ExpandableRow>
                        </ExpandableRow>
                        <tr style={{ fontWeight: '600', fontSize: 'medium' }}>
          {data.total_liability  > data.total_current_asset? (
            <>
              <td className="expandable-one">LOSS</td>
              <td className="expandable-one"></td>
              <td className="expandable-one text-center">{data.difference}</td>
            </>
          ) : (
            <>
              <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="expandable-one">--</td>
              <td className="expandable-one text-center">--</td>
              <td className="expandable-one text-center">--</td>
            </>
          )}
        </tr>

                    </React.Fragment>
                ))}
        </tbody>




        <tfoot>
    <tr style={{ fontWeight: 600, fontSize: 'x-large' }}>
      <td style={{ border: '1.5px solid #dddddd', padding: '8px' }}>Total</td>
      <td></td>
      <td style={{ border: '1.5px solid #dddddd', padding: '8px' }} className="text-center">
        {reportData.reduce((total, item) => {
          return item.total_current_asset > item.total_liability 
            ? total + parseFloat(item.total_current_asset || 0) 
            : total + parseFloat(item.total_liability || 0);
        }, 0)}
      </td>
    </tr>
  </tfoot>


            </table>
        </div>

                          
                        </section>

                        <section className="balance-info text-dark pt-1 pb-1">
                          <div className="row p-4">
                            <div className="col-10">
                              
                              <div className="row ">
                                <div className="col-4 "></div>
                                <div className="col-4"></div>
                               
                              </div>
                            </div>
                            <div className="d-flex justify-content-around">
 

</div>

                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100 "
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                    
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Horizontal_balance_sheet;