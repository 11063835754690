import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Link, useNavigate,useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/Items.css";
import html2pdf from "html2pdf.js";



function View_vendor () {
    const ID = Cookies.get("Login_id");
    const { id } = useParams();
    const [vendor,setVendor] = useState([]);
    const [company,setCompany] = useState([]);
    const [terms,setTerms] = useState([]);
    const [history,setHistory] =  useState([]);
    const [comments, setComments] = useState([]);
    const [balance, setBalance] = useState(0);
    const [transactions, setTransactions] = useState([]);

    const [emailIds, setEmailIds] = useState("");
    const [emailMessage, setEmailMessage] = useState("");


    const navigate = useNavigate();
    const viewvendor = () =>{
        axios.get(`${config.base_url}/view_vendor/${id}/${ID}/`).then((res)=>{
            console.log("Data......",res.data);
            
            if(res.data.status){
              var vendor = res.data.vendors;
              var company = res.data.company;
              var term = res.data.payment_term;
              var hist = res.data.history;
              var cmt = res.data.comments;
              var trans = res.data.all_transactions;
              var bal = res.data.Balance;
              
            
                setVendor(vendor);
                setComments(cmt);
                setCompany(company);
                setTerms(term);
                if (hist) {
                    setHistory(hist);
                }
                if(trans){
                setTransactions(trans);
                }
                setBalance(bal)
            }
          }).catch((err)=>{
            console.log('ERR',err)
          })
        }
        useEffect(()=>{
            viewvendor();
          },[])
   
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [filteredBalance, setFilteredBalance] = useState(0);
  
  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };
  
  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };
  
  const calculateBalance = (transactions) => {

    let initialBalance = 0; 
    transactions.forEach(transaction => {
      if(transaction.Type === 'Opening Balance'){
        initialBalance += parseFloat(transaction.Balance);
      }
      else if(transaction.Type === 'Purchase Order'){
        initialBalance -= parseFloat(transaction.Balance);
      }
      else if(transaction.Type === 'Bill'){
        initialBalance -= parseFloat(transaction.Balance);
      }
      else if(transaction.Type === 'Expense'){
        
        if(transaction.Amounttype === 'Credit'){
          initialBalance -= parseFloat(transaction.Balance);
        }
        else{
          initialBalance += parseFloat(transaction.Balance);
        }
        
      }
      else if(transaction.Type === 'Debit Note'){
        initialBalance += parseFloat(transaction.Balance);
      }
      else if(transaction.Type === 'Recurring Bill'){
        initialBalance -= parseFloat(transaction.Balance);
      }
    });
    
    return initialBalance;
  };
  
  const filterTransactions = () => {
    const from = new Date(fromDate);
    const to = new Date(toDate);

    const parseTransactionDate = (dateString) => {
        const [day, month, year] = dateString.split('-');
        return new Date(`${year}-${month}-${day}`);
    };

    const filtered = transactions.filter((transaction) => {
        const transactionDate = parseTransactionDate(transaction.Date);

        return transactionDate >= from && transactionDate <= to;
    });

    
    setFilteredTransactions(filtered);
    const newBalance = calculateBalance(filtered);
    setFilteredBalance(newBalance);
  };

  useEffect(() => {
   
    setFilteredTransactions(transactions);
    setFilteredBalance(calculateBalance(transactions));
  }, [transactions]);

    function overview() {
        document.getElementById('overview').style.display = 'block';
        document.getElementById('transaction').style.display = 'none';
        document.getElementById('statement').style.display = 'none';
        document.getElementById('overviewBtn').style.backgroundColor='rgba(22,37,50,255)'
        document.getElementById('transactionBtn').style.backgroundColor='transparent';
        document.getElementById('statementBtn').style.backgroundColor='transparent';
        document.getElementById('shareBtn').style.display = 'none';
        document.getElementById('printBtn').style.display = 'none';
        document.getElementById('pdfBtn').style.display = 'none';
        document.getElementById('editBtn').style.display = 'block';
        document.getElementById('exportBtn').style.display = 'none';
        document.getElementById('deleteBtn').style.display = 'block';
        document.getElementById('historyBtn').style.display = 'block';
        document.getElementById('statusBtn').style.display = 'block';

        document.getElementById('commentsBtn').style.display = 'block';

    }
    function transaction() {
        document.getElementById('overview').style.display = 'none';
        document.getElementById('statement').style.display = 'none';
        document.getElementById('transaction').style.display = 'block';
        document.getElementById('overviewBtn').style.backgroundColor='transparent';
        document.getElementById('statementBtn').style.backgroundColor='transparent';
        document.getElementById('transactionBtn').style.backgroundColor='rgba(22,37,50,255)';
        document.getElementById('shareBtn').style.display = 'none';
        document.getElementById('printBtn').style.display = 'none';
        document.getElementById('pdfBtn').style.display = 'none';
        document.getElementById('editBtn').style.display = 'none';
        document.getElementById('exportBtn').style.display = 'block';
        document.getElementById('deleteBtn').style.display = 'none';
        document.getElementById('historyBtn').style.display = 'none';
        document.getElementById('statusBtn').style.display = 'none';
        document.getElementById('commentsBtn').style.display = 'none';

    }
    function statement() {
        document.getElementById('overview').style.display = 'none';
        document.getElementById('transaction').style.display = 'none';
        document.getElementById('statement').style.display = 'block';
        document.getElementById('overviewBtn').style.backgroundColor='transparent';
        document.getElementById('transactionBtn').style.backgroundColor='transparent';
        document.getElementById('statementBtn').style.backgroundColor="rgba(22,37,50,255)";
        document.getElementById('shareBtn').style.display = 'block';
        document.getElementById('printBtn').style.display = 'block';
        document.getElementById('pdfBtn').style.display = 'block';
        document.getElementById('editBtn').style.display = 'none';
        document.getElementById('exportBtn').style.display = 'none';
        document.getElementById('deleteBtn').style.display = 'none';
        document.getElementById('historyBtn').style.display = 'none';
        document.getElementById('statusBtn').style.display = 'none';
        document.getElementById('commentsBtn').style.display = 'none';

    }
    const Change_vendor_status = async (id,status) =>{
        try{
            const response = await axios.post(`${config.base_url}/change_vendor_status/${id}/${status}/`)
            console.log('Changed');
            viewvendor();
        } catch (error) {
            console.log('Error');
        }
    }
    function exportToExcel() {
        const Table = document.getElementById("transactionTable");
        const ws = XLSX.utils.table_to_sheet(Table);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, `${vendor.First_name} ${vendor.Last_name}_Transactions.xlsx`);
      }

    function reportPDF() {

      var element = document.getElementById("printContent");
      var opt = {
        margin: [0.5, 0.3, 0.3, 0.5],
        filename: `${vendor.First_name} ${vendor.Last_name}_transactions` ,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
      };
      html2pdf().set(opt).from(element).save();
    }
  


      function printSheet() {
        var divToPrint = document.getElementById("printContent");
        var printWindow = window.open("", "", "height=700,width=1000");
    
        printWindow.document.write("<html><head><title></title>");
        printWindow.document.write(`
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
        `);
        printWindow.document.write("</head>");
        printWindow.document.write("<body>");
        printWindow.document.write(divToPrint.outerHTML);
        printWindow.document.write("</body>");
        printWindow.document.write("</html>");
        printWindow.document.close();
        printWindow.print();
        printWindow.addEventListener('afterprint', function() {
          printWindow.close();
        });
    
      }
      const currentUrl = window.location.href;
      const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
        currentUrl
      )}`;
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });


      async function handleShareEmail(e) {
        e.preventDefault();
    
        const emails = emailIds.trim().split(",").map(email => email.trim());
        const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
        const invalidEmails = emails.filter(email => !emailRegex.test(email));
    
        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
          return;
        }
    
        const element = document.getElementById("printContent");
        const opt = {
          margin: [0.5, 0.3, 0.3, 0.5],
          filename: `${vendor.First_name} ${vendor.Last_name}_transactions`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
        };
    
        const pdfBlob = await html2pdf().set(opt).from(element).outputPdf('blob');
    
        const formData = new FormData();
        formData.append('Id', ID);
        formData.append("pdf", pdfBlob, `${vendor.First_name} ${vendor.Last_name}_transactions.pdf`);
        formData.append("email_ids", emailIds);
        formData.append("id", id,)
    
        axios
          .post(`${config.base_url}/share_vendor_transaction_mail/`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((res) => {
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Shared via mail.",
              });
              setEmailIds("");
              setEmailMessage("");
            }
          })
          .catch((err) => {
            if (err.response && err.response.data && !err.response.data.status) {
              Toast.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }


      function searchTable(){
        var rows = document.querySelectorAll('#transactionTable tbody tr');
        var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
        rows.forEach(function(row) {
          var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
          row.style.display = text.includes(val) ? '' : 'none';
        });
      }
      const EditVendor = async  (id) => {
        try{
            navigate(`/edit_vendor/${id}/`)
        } catch (error) {
            console.log('Failed')
        }
      }
      function DeleteVendor(id) {
        Swal.fire({
            title: `Delete Vendor - ${vendor.First_name}?`,
            text: "All datas will be deleted.!",
            icon: "warning",
            showCancelButton: true,
            cancelButtonColor: "#3085d6",
            confirmButtonColor: "#d33",
            confirmButtonText: "Delete",
          }).then((result) => {
            if (result.isConfirmed) {
              axios
                .delete(`${config.base_url}/delete_vendor/${id}/`)
                .then((res) => {
                  console.log(res);
      
                  Toast.fire({
                    icon: "success",
                    title: "Vendor Deleted successfully",
                  });
                  navigate("/vendors");
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          });
      }
      const [comment, setComment] = useState("");
      const saveItemComment = (e) => {
        e.preventDefault();
        var cmt = {
          Id: ID,
          id:id,
          comments: comment,
        };
        axios
          .post(`${config.base_url}/add_vendor_comment/`, cmt)
          .then((res) => {
            console.log(res);
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Comment Added",
              });
              console.log('comment',res.data.data);
              setComments("");
              
              viewvendor();
            }
            
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      };
      // function deleteComment(id) {
      //   Swal.fire({
      //     title: "Delete Comment?",
      //     text: "Are you sure you want to delete this.!",
      //     icon: "warning",
      //     showCancelButton: true,
      //     cancelButtonColor: "#3085d6",
      //     confirmButtonColor: "#d33",
      //     confirmButtonText: "Delete",
      //   }).then((result) => {
      //     if (result.isConfirmed) {
      //       axios
      //         .delete(`${config.base_url}/delete_item_comment/${id}/`)
      //         .then((res) => {
      //           console.log(res);
    
      //           Toast.fire({
      //             icon: "success",
      //             title: "Comment Deleted",
      //           });
      //           viewvendor();
      //         })
      //         .catch((err) => {
      //           console.log(err);
      //         });
      //     }
      //   });
      // }
      
      const handleclick = async (id) =>{
        try{
          navigate(`/vendor_history/${id}/`);
        } catch (error) {
          console.log('none....')
        }
       
      } 

    return (
        <>
        <FinBase />
        <div className="page-content" style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
        <span className="d-flex justify-content-end p-2" style={{cursor:'pointer'}} ><i class="fa fa-times-circle text-white" style={{fontSize:'1.2rem'}}></i></span>
        <div className="card radius-15" style={{ backgroundColor: "#213b52" }}>
        <div className="card-body" style={{width:'100%'}}>
            <div className="card-title">
                <div className="container-fluid" >
                    <div className="row">
                        <div className="col-md-6">

                            <Link style={{padding:'10px',cursor:'pointer',borderRadius:'1vh',backgroundColor:'rgba(22,37,50,255)'}} onClick={() =>overview()} id="overviewBtn">Overview</Link>

                            <Link style={{padding:'10px',cursor:'pointer',borderRadius:'1vh'}} onClick={() => transaction()} id="transactionBtn">Transactions</Link>

                            <Link style={{padding:'10px',cursor:'pointer',borderRadius:'1vh'}} onClick={() => statement()} id="statementBtn">Statement</Link>
                        </div> 
                        <div className="col-md-6 d-flex justify-content-end">
                        {vendor.status == 'Active' ? (
                            <Link className="ml-2 fa fa-check-circle btn btn-outline-secondary text-grey" role="button" style={{ height: "fit-content",width: "fit-content" }} id="statusBtn" onClick={() => Change_vendor_status(vendor.id,'Inactive')}>&nbsp;Active</Link> 
                        ): (
                            <Link className="ml-2 fa fa-ban btn btn-outline-secondary text-grey" role="button" style={{ height: "fit-content",width: "fit-content" }} id="statusBtn" onClick={() => Change_vendor_status(vendor.id,'Active')}>&nbsp;Inactive</Link>   
                        )}
                        <Link className="ml-2 btn btn-outline-secondary text-grey fa fa-table" role="button" id="exportBtn" style={{display:'none',height: "fit-content",width: "fit-content"}} onClick={() =>exportToExcel()}>&nbsp;Export</Link>
                        <Link className="ml-2 btn btn-outline-secondary text-grey fa fa-file" role="button" id="pdfBtn" style={{display:'none',height: "fit-content",width: "fit-content"}} onClick={reportPDF}> &nbsp;PDF</Link> 
                        <Link className="ml-2 btn btn-outline-secondary text-grey fa fa-print" role="button" id="printBtn" style={{display:'none',height: "fit-content",width: "fit-content"}} onClick={() => printSheet()}>&nbsp;Print</Link>

                            <div
                                className="dropdown p-0 nav-item"
                                id="shareBtn"
                                style={{ display: "none" }}
                            >
                                <li
                                    className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                                    data-toggle="dropdown"
                                    style={{
                                    height: "fit-content",
                                    width: "fit-content",
                                    }}
                                >
                                    &nbsp;Share
                                </li>
                                <ul
                                    className="dropdown-menu"
                                    style={{ backgroundColor: "black" }}
                                    id="listdiv"
                                >
                                    <a
                                    href={shareUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    >
                                    <li
                                        style={{
                                        textAlign: "center",
                                        color: "#e5e9ec",
                                        cursor: "pointer",
                                        }}
                                    >
                                        WhatsApp
                                    </li>
                                    </a>
                                    <li
                                    style={{
                                        textAlign: "center",
                                        color: "#e5e9ec",
                                        cursor: "pointer",
                                    }}
                                    data-toggle="modal"
                                    data-target="#shareToEmail"
                                    >
                                    Email
                                    </li>
                                </ul>
                            </div>
                        <Link class="ml-2 fa fa-pencil btn btn-outline-secondary text-grey" id="editBtn" role="button" style={{ height: "fit-content",width: "fit-content" }} onClick={() =>EditVendor(vendor.id)}>&nbsp;Edit</Link>
                        <Link class="ml-2 btn btn-outline-secondary text-grey fa fa-trash" id="deleteBtn" role="button"  style={{ height: "fit-content",width: "fit-content" }} onClick={() =>DeleteVendor(vendor.id)}>&nbsp;Delete</Link>
                        <Link href="#"  class="ml-2 btn btn-outline-secondary text-grey fa fa-comments" id="commentsBtn" role="button" data-toggle="modal" data-target="#commentModal" style={{ height: "fit-content",width: "fit-content" }}> Comment</Link>
                        <Link class="ml-2 btn btn-outline-secondary text-grey fa fa-history" id="historyBtn" role="button" style={{ height: "fit-content",width: "fit-content"}} onClick={() => handleclick(vendor.id)}>&nbsp;History</Link>
                            
                        </div>
                    </div> 
                </div>
                <center>
                    <h3 className="card-title" >VENDOR OVERVIEW</h3>
                </center>
            </div>
        </div>
    </div>
    <div className="card card-registration card-registration-2" style={{borderRadius:'15px'}}>
        <div className="card-body p-0">

            <div id="overview">
                <div className="row g-0 mx-0">
                    <div className="col-lg-8">
                        <div className="history_highlight pt-3 px-2 d-flex">
                            <div className="col-12 d-flex justify-content-start align-items-center">
                                {history.action == 'Created' ? (<p className="text-success m-0" style={{fontSize:'1.07rem',fontWeight:'500'}}>Created by :</p>):(<p class="text-warning m-0" style={{fontSize:'1.07rem',fontWeight:'500'}}>Last Edited by :</p>)}
                                <span className="ml-2" style={{fontSize:'1.07rem',fontWeight:'500'}}>{history.doneBy}</span>
                                <span className="ml-5">{history.date}</span>
                            </div>
                        </div>
                        <div className="pb-3 px-2">
                            <div className="card-body">
                                <div className="card-title">
                                    <div className="row">
                                        <div className="col mt-3">
                                            {/* <h2 class="mb-0">{{ vendor.title }}. {{ vendor.first_name }} {{ vendor.last_name }}</h2> */}
                                            <h2 class="mb-0"> {vendor.Title}. {vendor.First_name}  {vendor.Last_name}</h2>
                                        </div>
                                    </div>
                                </div>
                                <hr />

                                <div class="row mb-4 d-flex justify-content-between align-items-center">
                                    <div class="col-md-2 mt-3">
                                        <h6 class="mb-0">Company</h6>
                                    </div>
                                    <div class="col-md-1 mt-3">
                                        :
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <p class="mb-0">{ vendor.Company_Name }</p>
                                    </div>
                                    <div class="col-md-2 mt-3 vl">
                                        <h6 class="mb-0">Email</h6>
                                    </div>
                                    <div class="col-md-1 mt-3">
                                        :
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <p class="mb-0">{ vendor.Vendor_email }</p>
                                    </div>
                                </div>
                                <div class="row mb-4 d-flex justify-content-between align-items-center">
                                    <div class="col-md-2 mt-3">
                                        <h6 class="mb-0">Mobile</h6>
                                    </div>
                                    <div class="col-md-1 mt-3">
                                        :
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <p class="mb-0">{ vendor.Mobile }</p>
                                    </div>
                                    <div class="col-md-2 mt-3 vl">
                                        <h6 class="mb-0">Website</h6>
                                    </div>
                                    <div class="col-md-1 mt-3">
                                        :
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <p class="mb-0">{ vendor.Website }</p>
                                    </div>
                                </div>
                                <div class="row mb-4 d-flex justify-content-between align-items-center">
                                    <div class="col-md-2 mt-3">
                                        <h6 class="mb-0">Location</h6>
                                    </div>
                                    <div class="col-md-1 mt-3">
                                        :
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <p class="mb-0">{ vendor.Location }</p>
                                    </div>
                                    <div class="col-md-2 mt-3 vl">
                                        <h6 class="mb-0">Place of Supply</h6>
                                    </div>
                                    <div class="col-md-1 mt-3">
                                        :
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <p class="mb-0">{ vendor.Place_of_supply }</p>
                                    </div>
                                </div>
                                <div class="row mb-4 d-flex justify-content-between align-items-center">
                                    <div class="col-md-2 mt-3">
                                        <h6 class="mb-0">Payment Terms</h6>
                                    </div>
                                    <div class="col-md-1 mt-3">
                                        :
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <p class="mb-0">{terms}</p>
                                    </div>
                                    <div class="col-md-2 mt-3 vl">
                                        <h6 class="mb-0">Price List</h6>
                                    </div>
                                    <div class="col-md-1 mt-3">
                                        :
                                    </div>
                                    <div class="col-md-3 mt-3">
                                        <p class="mb-0"></p>
                                    </div>
                                </div>
                                <hr />
                                <div class="row ">
                                    <div class="col-md-6" >
                                        <div class="card-content bg-transparent border-0 ml-4" >
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h5 class="ml-3" style={{textAlign:'center'}}>Billing Address</h5>
                                                    <hr />
                                                </div>
                                            </div><br />
                                            <div class="row mb-3">
                                                <div class="col-md-3" >
                                                    <h6 class="mb-0">Street</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Billing_street }</p>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-3">
                                                    <h6 class="mb-0">City</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Billing_city }</p>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-3">
                                                    <h6 class="mb-0">State</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Billing_state }</p>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-3">
                                                    <h6 class="mb-0">Pincode</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Billing_pincode }</p>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-3">
                                                    <h6 class="mb-0">Country</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Billing_country }</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card-content bg-transparent border-0" >
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <h5 class="ml-3" style={{textAlign:'center'}}>Shipping Address</h5>
                                                    <hr />
                                                </div>
                                            </div><br />
                                            <div class="row mb-3">
                                                <div class="col-md-3" >
                                                    <h6 class="mb-0">Street</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Shipping_street }</p>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-3">
                                                    <h6 class="mb-0">City</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Shipping_city }</p>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-3">
                                                    <h6 class="mb-0">State</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Shipping_state }</p>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-3">
                                                    <h6 class="mb-0">Pincode</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Shipping_pincode }</p>
                                                </div>
                                            </div>
                                            <div class="row mb-3">
                                                <div class="col-md-3">
                                                    <h6 class="mb-0">Country</h6>
                                                </div>
                                                <div class="col-md-1">
                                                    :
                                                </div>
                                                <div class="col-md-8">
                                                    <p class="mb-0" style={{textAlign:'right'}}>{ vendor.Shipping_country }</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 bg-grey">

                        <div className="px-3">
                            <h3 className="fw-bold mb-2 mt-4 pt-1">Vendor Details</h3>
                            <hr className="my-4" />
                            <div className="d-flex justify-content-between mb-4">
                                <h6 className="">Status</h6>
                                {vendor.status == 'Active' ? (
                                    <i className="fa fa-check-circle text-success">&nbsp;ACTIVE</i>
                                ) : (
                                    <i className="fa fa-ban text-danger">&nbsp;INACTIVE</i>
                                )}
                            </div>
                            <div className="d-flex justify-content-between mb-4">
                                <h6 className="">GST Type</h6>
                                { vendor.GST_Treatment }
                            </div>
                            <div className="col-md-4">
                                <p>
                                    {vendor.GST_Treatment == 'Unregistered Business' ? (
                                        <h6 className=""></h6>
                                    ) : (
                                        
                                    <h6 className="" style={{position:'relative',right:'15px'}}>GSTIN {vendor.GST_Number}</h6>
                                     )}
                                </p>

                            </div>


                            
                            
                            <div className="d-flex justify-content-between mb-4">
                                <h6 className="">PAN</h6>
                                { vendor.Pan_Number}
                            </div>
                            <div className="d-flex justify-content-between mb-4">
                                <h6 className="">Opening Bal.</h6>
                                { vendor.Opening_balance}
                            </div>
                            <div className="d-flex justify-content-between mb-4">
                                <h6 className="">Credit Limit</h6>
                                { vendor.Credit_limit}
                            </div>
                            <div className="d-flex justify-content-between mb-4">
                                <h6 className="">Balance</h6>
                                { vendor.Opening_balance}
                            </div>
                            
                        </div>

                    </div>
                </div>
            </div>


            <div id="transaction" style={{ display: "none" }}>
              <div id="printContent1">
                <div className="mt-5">
                  <center>
                    <h3 className="mt-3">
                      <em>
                      {vendor.Title}. {vendor.First_name} {vendor.Last_name}
                      </em>
                    </h3>
                  </center>
                </div>

                <div className="mt-5">
                  <div className="d-flex justify-content-between px-5">
                    <div className="customer_data">
                      <p>EMAIL: {vendor.Vendor_email}</p>
                      {vendor.GST_Number? <p>GSTIN: {vendor.GST_Number}</p>:<p></p>}
                      <p>
                        ADDRESS:
                        <br />
                        {vendor.Billing_street}, {vendor.Billing_city}, {vendor.Billing_state}
                        <br />
                        {vendor.Billing_country}-{vendor.Billing_pincode}
                      </p>
                    </div>
                    <div className="customer_data">
                      <p>MOBILE: +91 {vendor.Mobile}</p>
                      <p>CREDIT LIMIT: {vendor.Credit_limit}</p>
                      <strong><p style={{fontWeight:900}}>BALANCE: {filteredBalance}</p></strong>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="row mt-4 d-flex align-items-center" style={{gap: '5px'}}>
                    <div className="col-4">
                        <h5 style={{marginLeft: '50px'}}><strong>TRANSACTIONS</strong></h5>
                    </div>
                    <div className="col-4 ml-auto">
                        <input 
                            type="text" 
                            id="search" 
                            className="form-control w-100" 
                            placeholder="Search.." 
                            autoComplete="off" 
                            onKeyUp={searchTable}
                        />
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className="table-responsive px-2 mt-5">
                  <table className="table table-bordered" id="transactionTable">
                    <thead>
                      <tr>
                        <th className="text-center text-uppercase">Sl No.</th>
                        <th className="text-center text-uppercase">Date</th>
                        <th className="text-center text-uppercase">Type</th>
                        <th className="text-center text-uppercase">Number</th>
                        <th className="text-center text-uppercase">Total</th>
                        <th className="text-center text-uppercase">Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                    {transactions.map((transaction, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Date}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Type}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Number}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Total}</td>
                        <td style={{ textAlign: "center" }}>{transaction.Balance}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


            <div id="statement" style={{display:'none'}} >
                <div className="container-fluid" >
                    <div className="py-5">
                        <div className="row">
                            <div className="col-sm-4 d-flex justify-content-between align-items-center">
                                <div className="date_range d-flex">
                                    <div className="form-group">
                                        <label for="from">From</label>
                                        <input type="date" className="form-control" id="fromDate" value={fromDate} onChange={handleFromDateChange} />
                                    </div>
                                    <div className="form-group ml-2">
                                        <label for="from">To</label>
                                        <input type="date" className="form-control" id="toDate" value={toDate} onChange={handleToDateChange} />
                                    </div>
                                </div>
                                <br />
                                <div className="run_report">
                                    <button className="btn btn-outline-secondary text-white btn-sm ml-2" onClick={filterTransactions}>Run Report</button>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-4">
                            <div className="col-md-7" style={{margin:'auto'}}>
                                <div className="card">
                                    <div className="card-block1">
                                        <div id="printContent" className="print-container card-body">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-sm-12 col-sm-offset-1" style={{padding:'20px'}}>
                                                        <div className="widget-box">
                                                            <div className="widget-header widget-header-large py-3 px-4" style={{backgroundColor:'#343a40',padding:'1.1rem 1rem', textAlign: 'center'}}>
                                                                <h5 className="widget-title mb-4 print-visible" style={{color:'white'}}>
                                                                    <strong><span style={{color:'#fff',fontSize:'1.3rem',fontWeight:'bold'}}>Statement of {vendor.Title}. {vendor.First_name} {vendor.Last_name}</span></strong>
                                                                </h5>  
                                                            </div>
                                            
                                                            <div className="widget-body bg-white" style={{padding:'1rem 0.5rem'}}>
                                                                <div className="widget-main">
                                                                    <div className="row"  style={{padding:'20px'}} >
                                                                        <div className="col-sm-12 justify-content-center" style={{display:'flex', gap:'10px'}}>
                                                                            <div className="col-4" style={{width:'50%',paddingTop:'1rem'}}>
                                                                                <p style={{color:'black'}}>
                                                                                    <strong style={{fontSize:'20px'}}>{ company.Company_name }</strong> <br />
                                                                                    {company.Address}, {company.City} <br />
                                                                                    {company.State},{company.Country} <br />
                                                                                    {company.Pincode}<br />
                                                                                    {company.Email}<br />
                                                                                </p>  
                                                                            </div>
                                                                            <div className="col-2"></div>
                                                                            <div className="col-4" style={{width:'50%',paddingTop:'1rem'}}>
                                                                                <p style={{color:'black'}}>
                                                                                    <strong style={{fontSize:'20px'}}>{vendor.First_name} {vendor.Last_name}</strong><br />
                                                                                    {vendor.Billing_street}, {vendor.Billing_city}<br />
                                                                                    {vendor.Billing_state}, {vendor.Billing_country}<br />
                                                                                    {vendor.Billing_pincode }<br />
                                                                                    {vendor.Vendor_email}<br />
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="row">
                                                                        <div className="col-6"></div>
                                                                        <div className="col-4" style={{display:'flex',width:'75%',justifyContent:'end'}}>
                                                                            <table style={{color:'black'}}>
                                                                                <tr>
                                                                                    <th className="text-black"><p>Account Summary</p></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Opening balance :</th>
                                                                                    <th>{vendor.Opening_balance}</th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th>Balance :</th>
                                                                                    <th>{filteredBalance}</th>
                                                                                </tr>
                                                                            </table>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="col-md-12" style={{marginBottom:'1.5rem',marginTop:'1rem'}}>

                                                                    <table id="logic" className="table table-hover" width={'100%'}>
                                                                        <thead className="" style={{background:'#22b8d1'}}>
                                                                            <tr>
                                                            
                                                                                <th style={{padding:'1.2rem 0.5rem',color:'black'}}>Date</th>
                                                                                <th style={{padding:'1.2rem 0.5rem',color:'black'}}>Type</th>
                                                                                <th style={{padding:'1.2rem 0.5rem',color:'black'}}>Number</th>
                                                                                <th style={{padding:'1.2rem 0.5rem',color:'black'}}>Total</th>
                                                                                <th style={{padding:'1.2rem 0.5rem',color:'black'}}>Balance</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {filteredTransactions.map((transaction, index) => (
                                                                                <tr style={{padding:'1.2rem 0.5rem'}}>
                                                                                    <td style={{padding:'1.2rem 0.5rem',color:'black', borderBottom:'none'}}>{transaction.Date}</td>
                                                                                    <td style={{padding:'1.2rem 0.5rem',color:'black',fontFamily:'monospace', borderBottom:'none'}}><strong>{transaction.Type}</strong></td>
                                                                                    <td style={{padding:'1.2rem 0.5rem',color:'black', borderBottom:'none'}}>{transaction.Number}</td>
                                                                                    <td style={{padding:'1.2rem 0.5rem',color:'black', borderBottom:'none'}}>{transaction.Total}</td>
                                                                                    <td style={{padding:'1.2rem 0.5rem',color:'black',borderBottom:'none'}}>{transaction.Balance}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>


     <div
        className="modal fade"
        id="commentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Add Comments
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={saveItemComment} className="px-1">
              <div className="modal-body w-100">
                <textarea
                  type="text"
                  className="form-control"
                  name="comment"
                  value={comment}
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
                {comments.length > 0 ? (
                  <div className="container-fluid">
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th className="text-center">sl no.</th>
                          <th className="text-center">Comment</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comments.map((c, index) => (
                          <tr className="table-row">
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{c.comments}</td>
                            
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="my-2 font-weight-bold d-flex justify-content-center">
                    No Comments.!
                  </span>
                )}
              </div>

              <div className="modal-footer w-100">
                <button
                  type="button"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn"
                  id="commentSaveBtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>        



<div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Vendor Transactions</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Bill details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>



      
                                    
                       
                        
    
        </>
    )
}
export default View_vendor;