import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import html2pdf from "html2pdf.js";
import axios from "axios";
import * as XLSX from "xlsx";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/salesbyItemreport.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

function Trialbalance() {
  const ID = Cookies.get("Login_id");

  const [reportData, setReportData] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [item, setItem] = useState([]);
  const [otherDetails, setOtherDetails] = useState({});
  const [showCategoryDetails, setShowCategoryDetails] = useState({});

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [report, setReport] = useState("");

  const fetchSalesByItemReport = () => {
    axios
      .get(`${config.base_url}/Trial_balance/${ID}/`)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setCustomers(res.data.customers);
          setItem(res.data.item);
          setOtherDetails(res.data.otherDetails);
          setStartDate("");
          setEndDate("");
          setReport("");
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchSalesByItemReport();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  const handleCustomize = (e) => {
    e.preventDefault();

    var det = {
      Id: ID,
      start_date: startDate,
      end_date: endDate,
      report: report,
    };

    axios
      .post(`${config.base_url}/Trial_balancecustomized/`, det)
      .then((res) => {
        console.log("REPRT DATA=", res);
        if (res.data.status) {
          setReportData(res.data.reportData);
          setCustomers(res.data.customers);
          setItem(res.data.item);
          setOtherDetails(res.data.otherDetails);
          setStartDate(res.data.startDate);
          setEndDate(res.data.endDate);
          setReport(res.data.report);

          var contentDiv = document.getElementById("contentDiv");
          if (contentDiv.style.display === "block") {
            toggleContent();
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function printSection() {
    var divToPrint = document.getElementById("printReport");
    var printWindow = window.open("", "", "height=700,width=1000");

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function reportPDF() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    var element = document.getElementById("printReport");
    var opt = {
      margin: [0.5, 0.3, 0.3, 0.5],
      filename: "Report_Trialbalance" + date,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "cm", format: "letter", orientation: "portrait" },
    };
    html2pdf().set(opt).from(element).save();
  }

  function ExportToExcel() {
    var st = startDate;
    var en = endDate;
    var date = "";
    if (st != "" && en != "") {
      date = `_${startDate}` + "_" + `${endDate}`;
    }
    const Table = document.getElementById("reportTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Report_Trialbalance" + date + ".xlsx");
  }

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var st = startDate;
    var end = endDate;
    var rpt = report;

    if ((st != "" && end == "") || (st == "" && end != "")) {
      alert("Please select both date inputs or select none");
      return;
    } else {
      var emailsString = emailIds.trim();

      var emails = emailsString.split(",").map(function (email) {
        return email.trim();
      });

      var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

      var invalidEmails = [];
      if (emailsString === "") {
        alert("Enter valid email addresses.");
      } else {
        for (var i = 0; i < emails.length; i++) {
          var currentEmail = emails[i];

          if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
            invalidEmails.push(currentEmail);
          }
        }

        if (invalidEmails.length > 0) {
          alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
        } else {
          // document.getElementById("share_to_email_form").submit();
          var em = {
            Id: ID,
            start: st,
            end: end,
            report: rpt,
            email_ids: emailIds,
            email_message: emailMessage,
          };
          axios
            .post(`${config.base_url}/shareTrial_balanceToEmail/`, em)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Shared via mail.",
                });
                setEmailIds("");
                setEmailMessage("");
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (
                err.response &&
                err.response.data &&
                !err.response.data.status
              ) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
        }
      }
    }
  }
  function toggleContent() {
    var contentDiv = document.getElementById("contentDiv");
    if (contentDiv.style.display === "block") {
      contentDiv.style.display = "none";
    } else {
      contentDiv.style.display = "block";
      // Position the div just below the button
      // var buttonRect = event.target.getBoundingClientRect();
      // contentDiv.style.top = (buttonRect.bottom + window.scrollY) + "px";
      // contentDiv.style.left = buttonRect.left + "px";
    }
  }

  const handleCategoryToggle = (category) => {
    setShowCategoryDetails(prevState => ({
      ...prevState,
      [category]: !prevState[category]
    }));
  };

  function searchTable() {
    var rows = document.querySelectorAll("#reportTable tbody tr");
    var val = document
      .getElementById("search")
      .value.trim()
      .replace(/ +/g, " ")
      .toLowerCase();
    rows.forEach(function (row) {
      var text = row.textContent.replace(/\s+/g, " ").toLowerCase();
      row.style.display = text.includes(val) ? "" : "none";
    });
  }
  
  return (
    <>
      <FinBase />
      <div
        className="page-content mt-0 pt-0"
        id="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <center>
                <h2 className="text-uppercase" id="headline">
                Trial Balance
                </h2>
              </center>
              <hr />
            </div>

            <div className="bar">
              <div className=" left d-flex justify-content-start">
                <div className="position-relative mr-2">
                  <button
                    className="btn btn-secondary"
                    onClick={toggleContent}
                    style={{ width: "fit-content", height: "fit-content" }}
                  >
                    <i className="fas fa-solid fa-gear"></i> Customize Report
                  </button>
                  <div id="contentDiv" className="salescontent">
                    <h6>Customize Report</h6>
                    <form
                      onSubmit={handleCustomize}
                      className="form reportCustomizeForm px-1"
                      method="get"
                    >
                      <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>From</label>
                        <br />
                        <input
                          className="inputdate form-control"
                          type="date"
                          name="start_date"
                          id="startDate"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          required={endDate != "" ? true : false}
                        />
                      </div>
                      <div className="px-2 w-100">
                        <label style={{ textAlign: "left" }}>To</label>
                        <br />
                        <input
                          type="date"
                          className="inputdate form-control"
                          name="end_date"
                          id="endDate"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          required={startDate != "" ? true : false}
                        />
                      </div>
                    
                      <div className="d-flex px-2 mt-3 mb-4 w-100">
                        <button
                          type="submit"
                          className="btn btn-outline-light w-50"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Run Report
                        </button>
                        <button
                          type="reset"
                          onClick={toggleContent}
                          className="btn btn-outline-light ml-1 w-50"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="right d-flex">
                <a
                  className="btn btn-outline-secondary text-grey fa fa-file"
                  role="button"
                  id="pdfBtn"
                  onClick={reportPDF}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  {" "}
                  &nbsp;PDF
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                  role="button"
                  id="printBtn"
                  onClick={printSection}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Print
                </a>
                <a
                  className="ml-2 btn btn-outline-secondary text-grey fa fa-table"
                  role="button"
                  id="exportBtn"
                  onClick={ExportToExcel}
                  style={{ width: "fit-content", height: "fit-content" }}
                >
                  &nbsp;Export
                </a>
                <div className="dropdown p-0 nav-item" id="shareBtn">
                  <li
                    className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                    data-toggle="dropdown"
                    style={{
                      height: "fit-content",
                      width: "fit-content",
                    }}
                  >
                    &nbsp;Share
                  </li>
                  <ul
                    className="dropdown-menu"
                    style={{ backgroundColor: "black" }}
                    id="listdiv"
                  >
                    <a
                      href={shareUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <li
                        style={{
                          textAlign: "center",
                          color: "#e5e9ec",
                          cursor: "pointer",
                        }}
                      >
                        WhatsApp
                      </li>
                    </a>
                    <li
                      style={{
                        textAlign: "center",
                        color: "#e5e9ec",
                        cursor: "pointer",
                      }}
                      data-toggle="modal"
                      data-target="#shareToEmail"
                    >
                      Email
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card radius-15 print-only" id="pdf-card">
          <div className="card-body">
            <div className="container-fluid">

            <div className="col-md-3">
            <div className="d-flex align-items-center">
              <input
                type="text"
                id="search"
                className="form-control"
                placeholder="Search.."
                autoComplete="off"
                onKeyUp={searchTable}
              />
              </div>
              </div>


              <div
                id="printReport"
                className="printReportTemplate"
                style={{ display: "block " }}
              >
                <div className="my-5 page" size="A4">
                  <div id="printdiv2">
                    <div className="py-4 bg-secondary">
                      <div className="col-12">
                        <center className="h5 text-white">
                          <b>{otherDetails.cmpName}</b>
                        </center>
                        <center className="h3 text-white">
                          <b>TRIAL BALANCE</b>
                        </center>
                        {startDate != "" && endDate != "" ? (
                          <center className="h6 text-white">
                            {startDate} {"TO"} {endDate}
                          </center>
                        ) : null}
                      </div>
                    </div>
                    

                <div className="row px-1 py-1">
                <div className="col-12">
                    <section className="product-area mt-2">
                        <table className="table table-responsive-md mt-4 table-hover" id="reportTable">
                        <thead>
                            <tr>
                                <th className="text-center">PARTICULARS</th>
                                <th className="text-center">DEBIT</th>
                                <th className="text-center">CREDIT</th>
                            </tr>
                        </thead>
                        <tbody>
                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle("capitalAccount")}>
                  <td className="text-left"><b>CAPITAL ACCOUNT</b></td>
                  <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                  <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                </tr>

                {showCategoryDetails.capitalAccount && (
                  <>
                    <tr className="sub-category">
                      <td className="text-left">DRAWINGS OF OWNER</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.loan?.toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr className="account">
                      <td className="text-left">OWNER'S CAPITAL</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                    </tr>
                  </>
                )}

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle("loans")}>
                  <td className="text-left"><b>LOANS</b></td>
                  <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                  <td className="text-center">{otherDetails.loanamt?.toFixed(2) || "0.00"}</td>
                </tr>

                {showCategoryDetails.loans && (
                  <>
                    <tr className="sub-category">
                      <td className="text-left">BANK A/C</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.loanbankbal?.toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr className="account">
                      <td className="text-left">SECURED LOANS</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr className="account">
                      <td className="text-left">BANK LOANS</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.loan?.toFixed(2) || "0.00"}</td>
                    </tr>
                  </>
                )}

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle("currentLiabilities")}>
                  <td className="text-left"><b>CURRENT LIABILITIES</b></td>
                  <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                  <td className="text-center">{otherDetails.total_VENDORbalance?.toFixed(2) || "0.00"}</td>
                </tr>

                {showCategoryDetails.currentLiabilities && (
                  <>
                    <tr className="sub-category">
                      <td className="text-left">DUTIES AND TERMS</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr className="account">
                      <td className="text-left">PROVISIONS</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr className="account" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle("sundarycreditors")}>
                      <td className="text-left">SUNDRY CREDITORS</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.total_VENDORbalance?.toFixed(2) || "0.00"}</td>
                    </tr>
                  </>
                )}

               {showCategoryDetails.sundarycreditors && (
                <>
                {customers.map(customer => (
                  customer.total_balance !== 0 && (
                    <tr key={customer.id} className="account category-heading">
                      <td className="text-left">{customer.name}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{customer.total_balance?.toFixed(2) || "0.00"}</td>
                    </tr>
                  )
                ))}
                </>
                )}

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle("fixedAssets")}>
                  <td className="text-left"><b>FIXED ASSETS</b></td>
                  <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                  <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                </tr>

                {showCategoryDetails.fixedAssets && (
                  <>
                    <tr className="sub-category">
                      <td className="text-left">COMPUTER</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr className="account">
                      <td className="text-left">FURNITURE</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr className="account">
                      <td className="text-left">LAND AND BUILDING</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                    </tr>
                    <tr className="account">
                      <td className="text-left">MARUTI VAN</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                      <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                    </tr>
                  </>
                )}
                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('investments')}>
                    <td className="text-left"><b>INVESTMENTS</b></td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                </tr>
                {showCategoryDetails.investments && (
                    <tr className="sub-category">
                    <td className="text-left">COMMODITIES</td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    </tr>
                )}

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('income')}>
                    <td className="text-left"><b>INCOME</b></td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    <td className="text-center">{otherDetails.Totpurchasediscount?.toFixed(2) || '0.00'}</td>
                </tr>
                {showCategoryDetails.income && (
                    <>
                    <tr className="account">
                        <td className="text-left">DIRECT INCOME</td>
                        <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                        <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    </tr>
                    <tr className="SUNDRY" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('indirectincome')}>
                        <td className="text-left">INDIRECT INCOME</td>
                        <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                        <td className="text-center">{otherDetails.Totpurchasediscount?.toFixed(2) || '0.00'}</td>
                    </tr>
                    {showCategoryDetails.indirectincome && (
                    <tr className="account">
                        <td className="text-left">DISCOUNT RECEIVED</td>
                        <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                        <td className="text-center">{otherDetails.Totpurchasediscount?.toFixed(2) || '0.00'}</td>
                    </tr>
                    )}
                    </>
                )}

                {/* <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('salesAccounts')}>
                    <td className="text-left"><b>SALES ACCOUNTS</b></td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    <td className="text-center">{otherDetails.totCashInsale?.toFixed(2) || '0.00'}</td>
                </tr> */}

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('currentAssets')}>
                    <td className="text-left"><b>CURRENT ASSETS</b></td>
                    <td className="text-center">{otherDetails.CURRENT_ASSETS?.toFixed(2) || '0.00'}</td>
                    <td className="text-center">{otherDetails.totbankbal?.toFixed(2) || '0.00'}</td>
                </tr>
                {showCategoryDetails.currentAssets && (
                    <>
                    <tr className="sub-category" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('openingstock')}>
                        <td className="text-left">OPENING STOCK</td>
                        <td className="text-center">{otherDetails.Itemstock?.toFixed(2) || '0.00'}</td>
                        <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    </tr>

                    {showCategoryDetails.openingstock && (
                    <>
                    {item.map(i=> (                   
                        <tr key={i.id} className="account category-heading">
                          <td className="text-left">{i.name}</td>
                          <td className="text-center">{i.quantity?.toFixed(2) || "0.00"}</td>
                          <td className="text-center">{otherDetails.totalSale?.toFixed(2) || "0.00"}</td>
                        </tr>                    
                    ))}
                    </>
                    )}

                    <tr className="account">
                        <td className="text-left">DEPOSITS (ASSETS)</td>
                        <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                        <td className="text-center">{otherDetails.totbankbal?.toFixed(2) || '0.00'}</td>
                    </tr>
                    <tr className="account">
                        <td className="text-left">LOANS & ADVANCES (ASSETS)</td>
                        <td className="text-center">{otherDetails.emploan?.toFixed(2) || '0.00'}</td>
                        <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    </tr>
                    <tr className="SUNDRY" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle("sundarydebtors")}>
                        <td className="text-left">SUNDRY DEBTORS</td>
                        <td className="text-center">{otherDetails.total_CUSTbalance?.toFixed(2) || '0.00'}</td>
                        <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                    </tr>
                    </>
                )}
                    {showCategoryDetails.sundarydebtors && (
                    <>
                    {otherDetails.cust?.map((customer) => (
                        customer.total_balance !== 0 && (
                        <tr className="account category-heading" key={customer.name}>
                            <td className="text-left">{customer.name}</td>
                            <td className="text-center">{customer.total_balance?.toFixed(2) || '0.00'}</td>
                            <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                        </tr>
                        )
                    ))}
                    </>
                    )}

                {/* <tr className="category-heading SUNDRY" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('salesAccounts')}>
                <td className="text-left"><b>SALES ACCOUNTS</b></td>
                <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                <td className="text-center">{otherDetails.totCashInsale?.toFixed(2) || '0.00'}</td>
                </tr> */}

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('salesAccountsAlternate')}>
                <td className="text-left"><b>SALES ACCOUNTS</b></td>
                <td className="text-center">{otherDetails.totCashOutsale?.toFixed(2) || '0.00'}</td>
                <td className="text-center">{otherDetails.totCashInsale?.toFixed(2) || '0.00'}</td>
                </tr>

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('purchaseAccounts')}>
                <td className="text-left"><b>PURCHASE ACCOUNTS</b></td>
                <td className="text-center">{otherDetails.totCashOutpr?.toFixed(2) || '0.00'}</td>
                <td className="text-center">{otherDetails.totCashIndbt?.toFixed(2) || '0.00'}</td>
                </tr>

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('expenses')}>
                <td className="text-left"><b>EXPENSES</b></td>
                <td className="text-center">{otherDetails.EXPENCE?.toFixed(2) || '0.00'}</td>
                <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                </tr>

                {showCategoryDetails.expenses && (
                <>
                <tr className="account" style={{ cursor: 'pointer' }}>
                    <td className="text-left">DIRECT EXPENSES</td>
                    <td className="text-center">{otherDetails.totalExpense?.toFixed(2) || '0.00'}</td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                </tr>
                <tr className="SUNDRY" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('indirectexpenses')}>
                    <td className="text-left">INDIRECT EXPENSES</td>
                    <td className="text-center">{otherDetails.Totsalediscount?.toFixed(2) || '0.00'}</td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                </tr>
                {showCategoryDetails.indirectexpenses && (
                <tr className="account category-heading">
                    <td className="text-left">DISCOUNT PAID</td>
                    <td className="text-center">{otherDetails.Totsalediscount?.toFixed(2) || '0.00'}</td>
                    <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                </tr>
                )}
                </>
                )}

                {/* <tr className="category-heading SUNDRY" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('salesAccountsFinal')}>
                <td className="text-left"><b>SALES ACCOUNTS</b></td>
                <td className="text-center">{otherDetails.totalSale?.toFixed(2) || '0.00'}</td>
                <td className="text-center">{otherDetails.totCashInsale?.toFixed(2) || '0.00'}</td>
                </tr> */}

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('balanceDifference')}>
                <td className="text-left"><b>DIFFERENCE IN OPENING BALANCE</b></td>
                <td className="text-center">{otherDetails.balance?.toFixed(2) || '0.00'}</td>
                <td className="text-center">{otherDetails.balance2?.toFixed(2) || '0.00'}</td>
                </tr>

                <tr className="category-heading" style={{ cursor: 'pointer' }} onClick={() => handleCategoryToggle('totalAmount')}>
                <td className="text-left"><b>TOTAL AMOUNT</b></td>
                <td className="text-center"><b>{otherDetails.debit?.toFixed(2) || '0.00'}</b></td>
                <td className="text-center"><b>{otherDetails.credit?.toFixed(2) || '0.00'}</b></td>
                </tr>
                </tbody>
            </table>
              </section>
          </div>
      </div>




                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Report</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Report details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Trialbalance;
