import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import * as XLSX from "xlsx";
import { Await, Link, useNavigate,useParams } from "react-router-dom";
import Cookies from 'js-cookie';
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import Select from "react-select";


function Edit_Eway_Bill() {
    const ID = Cookies.get("Login_id");
    const {id} = useParams();
    const [terms, setTerms] = useState([]);
    const [customers,setCustomers] = useState([]);
    const [refNO,setRefNo] = useState([]);
    const [transport,setTransport] = useState([]);
    const [items, setItems] = useState([]);
    const [cmpState, setCmpState] = useState("");
    const [EWAYNO,setEWAYNO] = useState("");
    const [customerPriceLists, setCustomerPriceLists] = useState([]);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });

    const Fetch_EwayBill_data = () =>{
        axios
      .get(`${config.base_url}/EwayBill_Data/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          let cust = res.data.customers;
          let ewayno = res.data.ewayBillno;
          let refno = res.data.ref_no;
          let trans = res.data.transp;
          let item = res.data.items;
          let plst = res.data.lst;
          setCustomers([]);
          const newCustOptions = cust.map((item) => ({
            label: item.first_name + " " + item.last_name,
            value: item.id,
          }));
          setCustomers(newCustOptions);
          setRefNo(refno);
          setTransport([]);
              trans.map((i) => {
                setTransport((prevState) => [...prevState, i]);
              });
          setItems([]);
          const newOptions = item.map((ite) => ({
            label: ite.name,
            value: ite.id,
          }));
          setItems(newOptions);
          setCmpState(res.data.state);
          setEWAYNO(ewayno);
          setCustomerPriceLists([]);
          plst.map((c) => {
            setCustomerPriceLists((prevState) => [...prevState, c]);
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    }
    useEffect(() => {
        Fetch_EwayBill_data();
      }, []);
      function fetchPaymentTerms() {
        axios
          .get(`${config.base_url}/fetch_purchase_order_data/${ID}/`)
          .then((res) => {
            if (res.data.status) {
              let trms = res.data.paymentTerms;
              setTerms([]);
              trms.map((i) => {
                setTerms((prevState) => [...prevState, i]);
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      useEffect(() => {
        fetchPaymentTerms();
      }, []);
      const customStyles = {
        control: (provided) => ({
          ...provided,
          backgroundColor: "rgb(255 255 255 / 14%)",
        }),
        singleValue: (provided, state) => ({
          ...provided,
          color: "white",
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: "white",
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected
            ? "lightgray"
            : state.isFocused
            ? "lightgray"
            : "white",
          color: state.isSelected ? "black" : "black",
        }),
        input: (provided) => ({
          ...provided,
          color: "white",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "white",
        }),
      };
    
    
      var currentDate = new Date();
      var formattedDate = currentDate.toISOString().slice(0, 10);
      const [title, setTitle] = useState("Mr");
    const [customer, setCustomer] = useState("");
    const [email, setEmail] = useState("");
    const [billingAddress, setBillingAddress] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [company, setCompany] = useState("");
    const [location, setLocation] = useState("");
    const [customerPlaceOfSupply, setCustomerPlaceOfSupply] = useState("");
    const [placeOfSupply, setPlaceOfSupply] = useState("");
    const [priceListId, setPriceListId] = useState("");
    const [priceList, setPriceList] = useState(false);
    const [customerGstType, setCustomerGstType] = useState("");
    const [customerGstIn, setCustomerGstIn] = useState("");
    const [panNo, setPanNo] = useState("");
    const [oBalType, setOBalType] = useState("");
    const [oBal, setOBal] = useState("");
    const [creditLimit, setCreditLimit] = useState("");
    const [paymentTerm, setPaymentTerm] = useState("");
    const [customerPriceList, setCustomerPriceList] = useState("");
    const [customerEmail, setCustomerEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [mobile, setMobile] = useState("");
  
    const [bStreet, setBStreet] = useState("");
    const [bCity, setBCity] = useState("");
    const [bState, setBState] = useState("");
    const [bPincode, setBPincode] = useState("");
    const [bCountry, setBCountry] = useState("");
  
    const [sStreet, setSStreet] = useState("");
    const [sCity, setSCity] = useState("");
    const [sState, setSState] = useState("");
    const [sPincode, setSPincode] = useState("");
    const [sCountry, setSCountry] = useState("");
    const [taxAmount, setTaxAmount] = useState(0.0);
    function placeShipAddress() {
      var chkbtn = document.getElementById("shipAddress");
      if (chkbtn.checked == true) {
        setSStreet(bStreet);
        setSCity(bCity);
        setSPincode(bPincode);
        setSCountry(bCountry);
        setSState(bState);
      } else {
        setSStreet("");
        setSCity("");
        setSPincode("");
        setSCountry("");
        setSState("");
      }
    }
  
    function checkLastName() {
      var fName = firstName.replace(/\d/g, "");
      var lName = lastName.replace(/\d/g, "");
      if (fName != "" && lName != "") {
        checkCustomerName(fName, lName);
      } else {
        alert("Please enter a valid Full Name.!");
        return false;
      }
    }
    function checkFirstName() {
      var fName = firstName.replace(/\d/g, "");
      var lName = lastName.replace(/\d/g, "");
      if (fName != "" && lName != "") {
        checkCustomerName(fName, lName);
      } else if (fName == "" && lName != "") {
        alert("Please enter a valid First Name.!");
      }
    }
  
    function checkCustomerName(fname, lname) {
      if (fname != "" && lname != "") {
        var u = {
          Id: ID,
          fName: fname,
          lName: lname,
        };
        axios
          .get(`${config.base_url}/check_customer_name/`, { params: u })
          .then((res) => {
            console.log(res);
            if (res.data.is_exist) {
              Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
              });
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status && err.response.data.message) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  
    function checkCustomerGSTIN(gstin) {
      var gstNo = gstin;
      if (gstNo != "") {
        var u = {
          Id: ID,
          gstin: gstNo,
        };
        axios
          .get(`${config.base_url}/check_gstin/`, { params: u })
          .then((res) => {
            console.log(res);
            if (res.data.is_exist) {
              Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
              });
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status && err.response.data.message) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  
    function checkCustomerPAN(pan) {
      var panNo = pan;
      if (panNo != "") {
        var u = {
          Id: ID,
          pan: panNo,
        };
        axios
          .get(`${config.base_url}/check_pan/`, { params: u })
          .then((res) => {
            console.log(res);
            if (res.data.is_exist) {
              Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
              });
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status && err.response.data.message) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  
    function checkCustomerPhone(phone) {
      var phoneNo = phone;
      if (phoneNo != "") {
        var u = {
          Id: ID,
          phone: phoneNo,
        };
        axios
          .get(`${config.base_url}/check_phone/`, { params: u })
          .then((res) => {
            console.log(res);
            if (res.data.is_exist) {
              Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
              });
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status && err.response.data.message) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  
    function checkCustomerEmail(email) {
      var custEmail = email;
      if (custEmail != "") {
        var u = {
          Id: ID,
          email: custEmail,
        };
        axios
          .get(`${config.base_url}/check_email/`, { params: u })
          .then((res) => {
            console.log(res);
            if (res.data.is_exist) {
              Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
              });
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status && err.response.data.message) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  
    function handleGstType(value) {
      setCustomerGstType(value);
      checkGstType(value);
    }
  
    function checkGstType(value) {
      var gstTypeElement = document.getElementById("gstType");
      var gstINElement = document.getElementById("gstIN");
      var gstRowElements = document.getElementsByClassName("gstrow");
  
      var x = value;
      if (x === "Unregistered Business" || x === "Overseas" || x === "Consumer") {
        Array.prototype.forEach.call(gstRowElements, function (element) {
          element.classList.remove("d-block");
          element.classList.add("d-none");
        });
        gstINElement.required = false;
      } else {
        gstINElement.required = true;
        Array.prototype.forEach.call(gstRowElements, function (element) {
          element.classList.remove("d-none");
          element.classList.add("d-block");
        });
      }
    }
  
    function checkgst(val) {
      var gstinput = val;
      var gstregexp =
        "[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9A-Za-z]{1}[Z]{1}[0-9a-zA-Z]{1}";
  
      if (gstinput.length === 15) {
        if (gstinput.match(gstregexp)) {
          document.getElementById("warngst").innerHTML = "";
          checkCustomerGSTIN(val);
        } else {
          document.getElementById("warngst").innerHTML =
            "Please provide a valid GST Number";
          alert("Please provide a valid GST Number");
        }
      } else {
        document.getElementById("warngst").innerHTML =
          "Please provide a valid GST Number";
        alert("Please provide a valid GST Number");
      }
    }
  
    function checkpan(val) {
      var paninput = val;
      var panregexp = ["[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}"];
      if (val != "") {
        if (paninput.match(panregexp)) {
          document.getElementById("warnpan").innerHTML = "";
          checkCustomerPAN(val);
        } else {
          document.getElementById("warnpan").innerHTML =
            "Please provide a valid PAN Number";
          alert("Please provide a valid PAN Number");
        }
      }
    }
  
    function checkweb(val) {
      var webinput = val;
      var webregexp = "www.";
      if (val != "") {
        if (webinput.startsWith(webregexp)) {
          document.getElementById("warnweb").innerHTML = "";
        } else {
          document.getElementById("warnweb").innerHTML =
            "Please provide a valid Website Address";
          alert("Please provide a valid Website Address");
        }
      }
    }
  
    function checkphone(val) {
      var phoneinput = val;
      var phoneregexp = /^\d{10}$/;
      if (val != "") {
        if (phoneinput.match(phoneregexp)) {
          document.getElementById("warnphone").innerHTML = "";
          checkCustomerPhone(val);
        } else {
          document.getElementById("warnphone").innerHTML =
            "Please provide a valid Phone Number";
          alert("Please provide a valid Phone Number");
        }
      }
    }
  
    function checkemail(val) {
      var emailinput = val;
      var emailregexp = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
      if (val != "") {
        if (emailinput.match(emailregexp)) {
          //   document.getElementById("warnemail").innerHTML = "";
          checkCustomerEmail(val);
        } else {
          //   document.getElementById("warnemail").innerHTML =
          //     "Please provide a valid Email ID";
          alert("Please provide a valid Email id");
        }
      }
    }
  
    function setOpeningBalanceValue(value) {
      var openbal = value;
      if (oBalType == "credit") {
        if (openbal.slice(0, 1) != "-") {
          if (parseFloat(openbal) != 0) {
            setOBal(-1 * openbal);
          } else {
            setOBal(openbal);
          }
        } else {
          if (parseFloat(openbal) != 0) {
            setOBal(openbal);
          } else {
            setOBal(-1 * parseFloat(openbal));
          }
        }
      } else {
        setOBal(openbal);
      }
    }
  
    function handleOpenBalType(val) {
      setOBalType(val);
      changeOpenBalType(val);
    }
    
  
    function changeOpenBalType(type) {
      var openbal = oBal;
      if (openbal != "") {
        if (type == "credit") {
          if (parseFloat(openbal) != 0) {
            setOBal(-1 * openbal);
          } else {
            setOBal(openbal);
          }
        } else {
          if (parseFloat(openbal) < 0) {
            setOBal(Math.abs(openbal));
          } else {
            setOBal(openbal);
          }
        }
      }
    }
  
    const handleNewCustomerModalSubmit = (e) => {
        e.preventDefault();
    
        var dt = {
          Id: ID,
          title: title,
          first_name: firstName,
          last_name: lastName,
          company: company,
          location: location,
          place_of_supply: customerPlaceOfSupply,
          gst_type: customerGstType,
          gstin: customerGstIn,
          pan_no: panNo,
          email: customerEmail,
          mobile: mobile,
          website: website,
          price_list: customerPriceList,
          payment_terms: paymentTerm,
          opening_balance: oBal,
          open_balance_type: oBalType,
          current_balance: oBal,
          credit_limit: creditLimit,
          billing_street: bStreet,
          billing_city: bCity,
          billing_state: bState,
          billing_pincode: bPincode,
          billing_country: bCountry,
          ship_street: sStreet,
          ship_city: sCity,
          ship_state: sState,
          ship_pincode: sPincode,
          ship_country: sCountry,
          status: "Active",
        };
    
        axios
          .post(`${config.base_url}/create_new_customer/`, dt)
          .then((res) => {
            console.log("CUST RES=", res);
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Customer Created",
              });
              Fetch_EwayBill_data();
            }
            if (!res.data.status && res.data.message != "") {
              Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
              });
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      };
      const handleCustomerChange = (value) => {
        setCustomer(value);
        getCustomerData(value);
      };
      function getCustomerData(customer) {
        var cst = {
          Id: ID,
          c_id: customer,
        };
    
        if (customer != "") {
          axios
            .get(`${config.base_url}/get_customer_data/`, { params: cst })
            .then((res) => {
              if (res.data.status) {
                setEmail("");
                setCustomerGstType("");
                setCustomerGstIn("");
                setBillingAddress("");
                setCustomerPlaceOfSupply("");
                var cust = res.data.customerDetails;
                setEmail(cust.email);
                setCustomerGstType(cust.gstType);
                setCustomerGstIn(cust.gstIn);
                setCustomerPlaceOfSupply(cust.placeOfSupply);
                setBillingAddress(cust.address);
                refreshTax(cust.placeOfSupply);
              }
            })
            .catch((err) => {
              console.log("ERROR", err);
            });
        } else {
          setEmail("");
          setCustomerGstType("");
          setCustomerGstIn("");
          setBillingAddress("");
          setCustomerPlaceOfSupply("");
        }
      }
      function refreshTax(plc) {
        var cmp = cmpState;
        if (cmp == plc) {
          document.querySelectorAll(".tax_ref").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".tax_ref_gst").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("taxamountCGST").style.display = "flex";
          document.getElementById("taxamountSGST").style.display = "flex";
          document.getElementById("taxamountIGST").style.display = "none";
        } else {
          document.querySelectorAll(".tax_ref").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".tax_ref_igst").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("taxamountCGST").style.display = "none";
          document.getElementById("taxamountSGST").style.display = "none";
          document.getElementById("taxamountIGST").style.display = "flex";
        }
        calc2(plc);
      }
      const calc3 = (ewayitems) => {
        const updatedItems = ewayitems.map((item) => {
          console.log("CALC3==", item);
    
          let qty = parseInt(item.quantity || 0);
          let price = priceList
            ? parseFloat(item.priceListPrice || 0)
            : parseFloat(item.price || 0);
          let dis = parseFloat(item.discount || 0);
    
          let tax =
            placeOfSupply === cmpState
              ? parseInt(item.taxGst || 0)
              : parseInt(item.taxIgst || 0);
    
          let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
          let taxAmt = (qty * price - dis) * (tax / 100);
    
          return {
            ...item,
            total: total,
            taxAmount: taxAmt,
          };
        });
    
        calc_total(updatedItems);
      };
      async function applyPriceList(priceListId) {
        if (priceListId === "") {
          document.getElementById("custPriceListAlert").style.display = "block";
          document.getElementById("custPriceListAlert").innerText =
            "Select a Price List..";
          document.getElementById("custPriceListName").innerText = "";
          setPriceList(false);
          calc3(ewayitems);
        } else {
          let updatedItems = await Promise.all(
            ewayitems.map(async (pItem) => {
              var itemId = pItem.item;
              var plc = placeOfSupply;
              var PLId = priceListId;
    
              if (PLId !== "") {
                if (plc !== "") {
                  document.getElementById("custPriceListAlert").style.display =
                    "none";
                  document.getElementById("custPriceListName").innerText =
                    "Applied: " +
                    document.querySelector("#priceListIds option:checked")
                      .textContent;
    
                  var itm = {
                    Id: ID,
                    item: itemId,
                    listId: PLId,
                  };
    
                  try {
                    let res = await axios.get(
                      `${config.base_url}/get_table_item_data/`,
                      { params: itm }
                    );
                    if (res.data.status) {
                      var itemData = res.data.itemData;
                      pItem.price = itemData.sales_rate;
                      pItem.priceListPrice = itemData.PLPrice;
                      pItem.taxGst = itemData.gst;
                      pItem.taxIgst = itemData.igst;
                      pItem.hsnSac = itemData.hsnSac;
                    }
                  } catch (err) {
                    console.log("ERROR", err);
                  }
                } else {
                  alert("Select Place of Supply.!");
                }
              } else {
                document.getElementById("custPriceListAlert").style.display =
                  "block";
                document.getElementById("custPriceListAlert").innerText =
                  "Select a Price List..";
                document.getElementById("custPriceListName").innerText = "";
                setPriceList(false);
              }
              return pItem;
            })
          );
    
          setEwaybillitems(updatedItems);
          refreshIndexes(updatedItems)
          refreshTax(placeOfSupply);
          calc3(updatedItems);
        }
      }
      function calc2(customerPlaceOfSupply) {
        const updatedItems = Ewaybillitems.map((item) => {
          var qty = parseInt(item.quantity || 0);
          if (priceList) {
            var price = parseFloat(item.priceListPrice || 0);
          } else {
            var price = parseFloat(item.price || 0);
          }
          var dis = parseFloat(item.discount || 0);
    
          if (customerPlaceOfSupply == cmpState) {
            var tax = parseInt(item.taxGst || 0);
          } else {
            var tax = parseInt(item.taxIgst || 0);
          }
          let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
          let taxAmt = (qty * price - dis) * (tax / 100);
          return {
            ...item,
            total: total,
            taxAmount: taxAmt,
          };
        });
    
        setEwaybillitems(updatedItems);
        refreshIndexes(updatedItems)
        calc_total2(updatedItems, placeOfSupply);
      }
      const [deliver,setDeliver] = useState("");
      
      function toggleDeliveryInfo(val) {
        setDeliver(val);
        var deliverToDifferentAddress = document.getElementById("deliver_to_different_address");
        var deliveryInfo = document.getElementById("deliveryInfo");
        var deliveryAddressInfo = document.getElementById("deliveryAddressInfo");
        var deliveryPhoneInfo = document.getElementById("deliveryPhoneInfo");
        var deliveryEmailInfo = document.getElementById("deliveryEmailInfo");
        if (deliverToDifferentAddress.value === "1") {
            deliveryInfo.style.display = "block";
            deliveryAddressInfo.style.display = "block";
            deliveryPhoneInfo.style.display = "block";
            deliveryEmailInfo.style.display = "block";
            
        } else {
            deliveryInfo.style.display = "none";
            deliveryAddressInfo.style.display = "none";
            deliveryPhoneInfo.style.display = "none";
            deliveryEmailInfo.style.display = "none"; 
        }
    }

    function fetchItems() {
      axios
        .get(`${config.base_url}/EwayBill_Data/${ID}/`)
        .then((res) => {
          if (res.data.status) {
            let items = res.data.items;
            setItems([]);
            const newOptions = items.map((item) => ({
              label: item.name,
              value: item.id,
            }));
            setItems(newOptions);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [unit, setUnit] = useState("");
    const [hsn, setHsn] = useState("");
    const [sac, setSac] = useState("");
    const [taxRef, setTaxRef] = useState("");
    const [interStateTax, setInterStateTax] = useState("");
    const [intraStateTax, setIntraStateTax] = useState("");
    const [purchasePrice, setPurchasePrice] = useState(0);
    const [purchaseAccount, setPurchaseAccount] = useState("");
    const [purchaseDescription, setPurchaseDescription] = useState("");
    const [salesPrice, setSalesPrice] = useState(0);
    const [salesAccount, setSalesAccount] = useState("");
    const [salesDescription, setSalesDescription] = useState("");
    const [inventoryAccount, setInventoryAccount] = useState("");
    const [stock, setStock] = useState(0);
    const [stockUnitRate, setStockUnitRate] = useState(0);
    const [minStock, setMinStock] = useState(0);
    const [subTotal, setSubTotal] = useState(0.0);
    const [shippingCharge, setShippingCharge] = useState(0.0);
    const [adjustment, setAdjustment] = useState(0.0);
    const [grandTotal, setGrandTotal] = useState(0.0);
    const [paid, setPaid] = useState(0.0);
    const [balance, setBalance] = useState(0.0);
    const [igst, setIgst] = useState(0.0);
    const [cgst, setCgst] = useState(0.0);
    const [sgst, setSgst] = useState(0.0);

    const handleItemModalSubmit = (e) => {
      e.preventDefault();
  
      var dt = {
        Id: ID,
        name: name,
        item_type: type,
        unit: unit,
        hsn: hsn,
        sac: sac,
        tax_reference: taxRef,
        intra_state_tax: intraStateTax,
        inter_state_tax: interStateTax,
        sales_account: salesAccount,
        selling_price: salesPrice,
        sales_description: salesDescription,
        purchase_account: purchaseAccount,
        purchase_price: purchasePrice,
        purchase_description: purchaseDescription,
        min_stock: minStock,
        inventory_account: inventoryAccount,
        opening_stock: stock,
        current_stock: stock,
        stock_in: 0,
        stock_out: 0,
        stock_unit_rate: stockUnitRate,
        status: "Active",
      };
  
      axios
        .post(`${config.base_url}/create_new_item/`, dt)
        .then((res) => {
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "Item Created",
            });
            fetchItems();
          }
          if (!res.data.status && res.data.message != "") {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    };
    const [units, setUnits] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [Ewaybillitems, setEwaybillitems] = useState([
      {
        id: 1,
        item: "",
        hsnSac: "",
        quantity: "",
        price: "",
        taxGst: "",
        taxIgst: "",
        discount: "",
        total: "",
        taxAmount: "",
      },
    ]);
    const fetchItemUnits = () => {
      axios
        .get(`${config.base_url}/get_company_item_units/${ID}/`)
        .then((res) => {
          if (res.data.status) {
            let unt = res.data.units;
            setUnits([]);
            unt.map((i) => {
              let obj = {
                name: i.name,
              };
              setUnits((prevState) => [...prevState, obj]);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
      fetchItemUnits();
    }, []);
    const fetchPurchaseAccounts = () => {
      axios
        .get(`${config.base_url}/get_company_accounts/${ID}/`)
        .then((res) => {
          if (res.data.status) {
            let acc = res.data.accounts;
            setAccounts([]);
            acc.map((i) => {
              let obj = {
                account_name: i.account_name,
              };
              setAccounts((prevState) => [...prevState, obj]);
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
  
    useEffect(() => {
      fetchPurchaseAccounts();
    }, []);
    function itemTypeChange() {
      var value = document.getElementById("itemType").value;
      var sacField = document.getElementById("sacField");
      var hsnField = document.getElementById("hsnField");
      var hsnDiv = document.getElementById("hsnDiv");
      var sacDiv = document.getElementById("sacDiv");
      var sacError = document.getElementById("sacError");
      var hsnError = document.getElementById("hsnError");
      if (value === "Goods") {
        sacField.value = "";
        hsnField.required = true;
        sacField.required = false;
        hsnDiv.style.display = "block";
        sacDiv.style.display = "none";
        sacError.textContent = "";
        sacField.style.borderColor = "white";
      } else {
        hsnField.value = "";
        hsnField.required = false;
        sacField.required = true;
        sacDiv.style.display = "block";
        hsnDiv.style.display = "none";
        hsnError.textContent = "";
        hsnField.style.borderColor = "white";
      }
    }
    function validateHSN() {
      var hsnField = document.getElementById("hsnField");
      var errorText = document.getElementById("hsnError");
      var hsnValue = hsnField.value;
  
      if (hsnValue.length < 6) {
        errorText.innerText = "HSN must contain at least 6 digits";
        hsnField.setCustomValidity("HSN must contain at least 6 digits");
        hsnField.style.borderColor = "red";
      } else {
        errorText.innerText = "";
        hsnField.setCustomValidity("");
        hsnField.style.borderColor = "";
      }
    }
    function validateSAC() {
      var sacField = document.getElementById("sacField");
      var errorText = document.getElementById("sacError");
      var sacValue = sacField.value;
  
      if (sacValue.length < 6) {
        errorText.innerText = "SAC must contain at least 6 digits";
        sacField.setCustomValidity("SAC must contain at least 6 digits");
        sacField.style.borderColor = "red";
      } else {
        errorText.innerText = "";
        sacField.setCustomValidity("");
        sacField.style.borderColor = "";
      }
    }
    function showdiv() {
      document.getElementById("taxableDiv").style.display = "flex";
    }
  
    function hidediv() {
      document.getElementById("taxableDiv").style.display = "none";
    }

    const handleEwaybillItemsInputChange = (id, field, value) => {
      setEwaybillitems((prevItems) =>
        prevItems.map((item) =>
          item.id === id ? { ...item, [field]: value } : item
        )
      );
    };
    const handleItemChange = (value, id) => {
      var exists = itemExists(value);
      if (!exists) {
        if (customerPlaceOfSupply != "") {
          handleEwaybillItemsInputChange(id, "item", value);
          getItemData(value, id);
        } else {
          alert("Select Place of Supply.!");
        }
      } else {
        alert(
          "Item already exists, choose another or change quantity.!"
        );
      }
    };
  
    const itemExists = (itemToCheck) => {
      for (const item of Ewaybillitems) {
        if (item.item === itemToCheck) {
          return true;
        }
      }
      return false;
    };
    function getItemData(item, id) {
      var exists = itemExists(item);
      var plc = customerPlaceOfSupply;
      var PLId = priceListId;
  
      if (!exists) {
        if (plc != "") {
          if (priceList && PLId == "") {
            handleEwaybillItemsInputChange(id, "item", "");
            alert("Select a Price List from the dropdown..!");
          } else {
            var itm = {
              Id: ID,
              item: item,
              listId: PLId,
            };
  
            axios
              .get(`${config.base_url}/get_table_item_data/`, { params: itm })
              .then((res) => {
                if (res.data.status) {
                  var itemData = res.data.itemData;
  
                  setEwaybillitems((prevItems) =>
                    prevItems.map((item) =>
                      item.id === id
                        ? {
                            ...item,
                            price: itemData.sales_rate,
                            priceListPrice: itemData.PLPrice,
                            taxGst: itemData.gst,
                            taxIgst: itemData.igst,
                            hsnSac: itemData.hsnSac,
                          }
                        : item
                    )
                  );
                }
              })
              .catch((err) => {
                console.log("ERROR", err);
              });
          }
        } else {
          alert("Select Place of Supply.!");
        }
      } else {
        alert(
          "Item already exists, choose another or change quantity.!"
        );
      }
    }
    function refreshValues() {
      checkPriceList(priceList);
      refreshTax2();
      calc();
    }
    function checkPriceList(priceList) {
      if (priceList) {
        if (priceListId != "") {
          document.querySelectorAll(".price").forEach(function (ele) {
            ele.style.display = "none";
          });
          document.querySelectorAll(".priceListPrice").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.getElementById("custPriceListName").style.display =
            "inline-flex";
        } else {
          document.querySelectorAll(".price").forEach(function (ele) {
            ele.style.display = "block";
          });
          document.querySelectorAll(".priceListPrice").forEach(function (ele) {
            ele.style.display = "none";
          });
          // document.getElementById("custPriceListName").style.display = "none";
        }
      } else {
        document.querySelectorAll(".price").forEach(function (ele) {
          ele.style.display = "block";
        });
        document.querySelectorAll(".priceListPrice").forEach(function (ele) {
          ele.style.display = "none";
        });
        // document.getElementById("custPriceListName").style.display = "none";
      }
      calc();
    }
    function refreshTax2() {
      if (cmpState == customerPlaceOfSupply) {
        document.querySelectorAll(".tax_ref").forEach(function (ele) {
          ele.style.display = "none";
        });
        document.querySelectorAll(".tax_ref_gst").forEach(function (ele) {
          ele.style.display = "block";
        });
        document.getElementById("taxamountCGST").style.display = "flex";
        document.getElementById("taxamountSGST").style.display = "flex";
        document.getElementById("taxamountIGST").style.display = "none";
      } else {
        document.querySelectorAll(".tax_ref").forEach(function (ele) {
          ele.style.display = "none";
        });
        document.querySelectorAll(".tax_ref_igst").forEach(function (ele) {
          ele.style.display = "block";
        });
        document.getElementById("taxamountCGST").style.display = "none";
        document.getElementById("taxamountSGST").style.display = "none";
        document.getElementById("taxamountIGST").style.display = "flex";
      }
    }
    const calc = () => {
      const updatedItems = Ewaybillitems.map((item) => {
        var qty = parseInt(item.quantity || 0);
        if (priceList) {
          var price = parseFloat(item.priceListPrice || 0);
        } else {
          var price = parseFloat(item.price || 0);
        }
        var dis = parseFloat(item.discount || 0);
  
        if (customerPlaceOfSupply == cmpState) {
          var tax = parseInt(item.taxGst || 0);
        } else {
          var tax = parseInt(item.taxIgst || 0);
        }
        let total = parseFloat(qty) * parseFloat(price) - parseFloat(dis);
        let taxAmt = (qty * price - dis) * (tax / 100);
        return {
          ...item,
          total: total,
          taxAmount: taxAmt,
        };
      });
  
      setEwaybillitems(updatedItems);
      refreshIndexes(updatedItems);
      calc_total(updatedItems);
    };
    function refreshIndexes(items){
      const itms = items.map((item, index) => ({
        ...item,
        id: index + 1,
      }));
  
      setEwaybillitems(itms)
    }
    function calc_total(Ewaybillitems) {
      var total = 0;
      var taxamount = 0;
      Ewaybillitems.map((item) => {
        total += parseFloat(item.total || 0);
      });
      Ewaybillitems.map((item) => {
        taxamount += parseFloat(item.taxAmount || 0);
      });
      setSubTotal(total.toFixed(2));
      setTaxAmount(taxamount.toFixed(2));
  
      var ship = parseFloat(shippingCharge || 0);
      var adj_val = parseFloat(adjustment || 0);
      var gtot = taxamount + total + ship + adj_val;
  
      setGrandTotal(gtot.toFixed(2));
  
      var adv_val = parseFloat(paid || 0);
      var bal = gtot - adv_val;
      setBalance(bal.toFixed(2));
      splitTax(taxamount, customerPlaceOfSupply);
    }
    function calc_total2(Ewaybillitems, customerPlaceOfSupply) {
      var total = 0;
      var taxamount = 0;
      Ewaybillitems.map((item) => {
        total += parseFloat(item.total || 0);
      });
      Ewaybillitems.map((item) => {
        taxamount += parseFloat(item.taxAmount || 0);
      });
      setSubTotal(total.toFixed(2));
      setTaxAmount(taxamount.toFixed(2));
  
      var ship = parseFloat(shippingCharge || 0);
      var adj_val = parseFloat(adjustment || 0);
      var gtot = taxamount + total + ship + adj_val;
  
      setGrandTotal(gtot.toFixed(2));
  
      var adv_val = parseFloat(paid || 0);
      var bal = gtot - adv_val;
      setBalance(bal.toFixed(2));
      splitTax2(taxamount, customerPlaceOfSupply);
    }
    function splitTax(taxamount, customerPlaceOfSupply) {
      var d = 0;
      if (customerPlaceOfSupply == cmpState) {
        var gst = taxamount / 2;
        setCgst(parseFloat(gst.toFixed(2)));
        setSgst(parseFloat(gst.toFixed(2)));
        setIgst(parseFloat(d.toFixed(2)));
      } else {
        setIgst(taxamount.toFixed(2));
        setCgst(d.toFixed(2));
        setSgst(d.toFixed(2));
      }
    }
  function splitTax2(taxamount, customerPlaceOfSupply) {
      var d = 0;
      if (customerPlaceOfSupply == cmpState) {
        var gst = taxamount / 2;
        setCgst(parseFloat(gst.toFixed(2)));
        setSgst(parseFloat(gst.toFixed(2)));
        setIgst(parseFloat(d.toFixed(2)));
      } else {
        setIgst(taxamount.toFixed(2));
        setCgst(d.toFixed(2));
        setSgst(d.toFixed(2));
      }
    }
    const [documenttype,setDocumenttype] = useState("");
    const [transactiontype,setTransactiontype] = useState("");
    const [ewayno,setEwayno] = useState("");
    const [date,setDatechange] = useState("");
    const [kilometer,setKilometer] = useState("");
    const [status, setStatus] = useState("");
    const [trans,setTransportation] = useState("");
    function handleDocumenttype(val) {
      setDocumenttype(val);
    }
    function handleTransactiontype(val) {
      setTransactiontype(val);
    }
    function handleEwayno(val) {
      setEwayno(val);
      CheckEwayno(val);
    }
    function CheckEwayno(val) {
      document.getElementById("ewayNoErr").innerText = "";
      var so_num = val;
      if (so_num != "") {
        var s = {
          Id: ID,
          EwayNum: so_num,
        };
        axios
          .get(`${config.base_url}/Fin_check_eway_billNo/`, { params: s })
          .then((res) => {
            console.log("SO NUM Res=", res);
            if (!res.data.status) {
              document.getElementById("ewayNoErr").innerText = res.data.message;
            } else {
              document.getElementById("ewayNoErr").innerText = "";
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    function handleDatechange(date) {
      setDatechange(date);
    }
    function handleKilometer(km) {
      setKilometer(km);
    }
    function handleAdjustment(val) {
      setAdjustment(val);
    }
    function handleShipping(val) {
      setShippingCharge(val);
    }
    function handleTransport(val) {
      FetchTransportData(val);
      setTransportation(val);
    }
    function  FetchTransportData(val) {
      var td = val;
      axios
          .get(`${config.base_url}/Fetch_Transport_Details/${td}/`)
          .then((res) => {
            if (res.data.status) {
              var trs = res.data.trans;
              if (trs.Type == 'Road'){
                document.getElementById('vehicle_number_segment').style.display = 'block';
              } else {
                document.getElementById('vehicle_number_segment').style.display = 'none';
              }
            } else {
              console.log('rr')
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
    const [vehiclenumber,setVehiclenumber] = useState("");
    function handleVehicleNumber(val) {
      setVehiclenumber(val);
    }
    const [deli_name,setDeliname] = useState("");
    const [deli_adr,setDeliadr] = useState("");
    const [deli_no,setDelino] = useState("");
    const [deli_mail,setDelimail] = useState("");
    const [file, setFile] = useState(null);
    const [description, setDescription] = useState("");
    function handledeliv_name(val) {
      setDeliname(val);
    }
    function handledeliv_adr(val) {
      setDeliadr(val);
    }
    function handledeliv_no(val) {
      setDelino(val);
    }
    function handledeliv_mail(val) {
      setDelimail(val);
    }
    const Create_new_Ewaybill = (e) =>{
      e.preventDefault(e);
      var dt = {
        Id:ID,
        customer:customer,
        doctype:documenttype,
        transtype:transactiontype,
        ewayno:ewayno,
        refno:refNO,
        date:date,
        trans:trans,
        kilo:kilometer,
        items:Ewaybillitems,
        igst:igst,
        cgst:cgst,
        sgst:sgst,
        tax:taxAmount,
        grand:grandTotal,
        sub:subTotal,
        adjs:adjustment,
        ship:shippingCharge,
        sta:status,
        deliver:deliver,
        dname:deli_name,
        dadr:deli_adr,
        dno:deli_no,
        dmai:deli_mail,
        file:file,
        descri:description,
      }
      axios
        .post(`${config.base_url}/Create_New_Eway_Bill/`, dt)
        .then((res) => {
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "Eway Bill Created",
            });
          }
          if (!res.data.status && res.data.message != "") {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    };
    const removeRow = (id) => {
      setEwaybillitems((prevItems) => {
        const updatedItems = prevItems.filter((item) => item.id !== id);
  
        return updatedItems.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
      });
    };
    const addNewRow = () => {
      var newItem = {
        id: "",
        item: "",
        hsnSac: "",
        quantity: "",
        price: "",
        taxGst: "",
        taxIgst: "",
        discount: "",
        total: "",
        taxAmount: "",
      };
      setEwaybillitems((prevItems) => {
        const updatedItems = [...prevItems, newItem];
  
        return updatedItems.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
      });
    };
    const [newPaymentTerm, setNewPaymentTerm] = useState("");
    const [newPaymentTermDays, setNewPaymentTermDays] = useState("");
    function handlePaymentTermModalSubmit(e) {
      e.preventDefault();
      var name = newPaymentTerm;
      var dys = newPaymentTermDays;
      if (name != "" && dys != "") {
        var u = {
          Id: ID,
          term_name: newPaymentTerm,
          days: newPaymentTermDays,
        };
        axios
          .post(`${config.base_url}/create_new_company_payment_term/`, u)
          .then((res) => {
            console.log("PTRM RES=", res);
            if (!res.data.status && res.data.message != "") {
              Swal.fire({
                icon: "error",
                title: `${res.data.message}`,
              });
            }
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Term Created",
              });
              fetchPaymentTerms();
  
              setNewPaymentTerm("");
              setNewPaymentTermDays("");
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (!err.response.data.status) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      } else {
        alert("Invalid");
      }
    }
    const [newUnit, setNewUnit] = useState("");
  function handleUnitModalSubmit(e) {
    e.preventDefault();
    var name = newUnit;
    if (name != "") {
      var u = {
        Id: ID,
        name: newUnit,
      };
      axios
        .post(`${config.base_url}/create_new_unit/`, u)
        .then((res) => {
          console.log("UNIT RES=", res);
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "Unit Created",
            });
            fetchItemUnits();
            setUnit(u.name);
            setNewUnit("");
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    } else {
      alert("Invalid");
    }
  }
  function accountTypeChange(val) {
    var selectElement = document.getElementById("Account_type");
    // var selectedValue = selectElement.value;
    var selectedValue = val;
    var Acnt_desc = document.getElementById("acnt-desc");
    var acctype = document.getElementById("acctype");

    switch (selectedValue) {
      case "Expense":
        Acnt_desc.innerHTML = `
                <b>Expense</b> <br>Reflects expenses incurred for running normal business operations, such as :<br/>
                <ul>
                    <li>1.Advertisements and Marketing</li>
                    <li>2.Business Travel Expenses</li>
                    <li>3.License Fees</li>
                    <li>4.Utility Expenses</li>
                </ul>`;
        acctype.value = "Expense";
        break;
      case "Cost Of Goods Sold":
        Acnt_desc.innerHTML = `
                <b>Expense</b> <br>This indicates the direct costs attributable to the production of the goods sold by a company such as:<br/>
                <ul>
                    <li>1.Material and Labor costs</li>
                    <li>2.Cost of obtaining raw materials</li>
                </ul>`;
        acctype.value = "Expense";
        break;
      case "Other Expense":
        Acnt_desc.innerHTML = `
                <b>Expense</b> <br>Track miscellaneous expenses incurred for activities other than primary business operations or create additional accounts to track default expenses like insurance or contribution towards charity.<br/>`;
        acctype.value = "Expense";
        break;

      default:
        Acnt_desc.innerHTML = `<b>Account Type</b> <br>Select an account type..<br/>`;
    }

    if (selectedValue != "") {
      document.getElementById("subAccountCheck").style.display = "none";
      document.getElementById("subAccountCheckBox").checked = false;
      document.getElementById("parentAccountValue").style.display = "none";

      var a = {
        Id: ID,
        type: selectedValue,
      };
      console.log("ACC DATA==", a);
      axios
        .get(`${config.base_url}/check_accounts/`, { params: a })
        .then((res) => {
          console.log("P ACC==", res);
          if (res.data.status) {
            document.getElementById("subAccountCheck").style.display = "block";
            var pAcc = res.data.accounts;
            setParentAccounts([]);
            pAcc.map((i) => {
              setParentAccounts((prevState) => [...prevState, i]);
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    }
  }
  function showParentAccounts() {
    var parentAccountValue = document.getElementById("parentAccountValue");
    var parentAccount = document.getElementById("parentAccount");

    if (document.getElementById("subAccountCheckBox").checked) {
      setSubAcc(true);
      parentAccountValue.style.display = "block";
      parentAccount.required = true;
    } else {
      setSubAcc(false);
      parentAccountValue.style.display = "none";
      parentAccount.required = false;
    }
  }
  const [accType, setAccType] = useState("Expense");
  const [accName, setAccName] = useState("");
  const [parentAccount, setParentAccount] = useState("");
  const [subAcc, setSubAcc] = useState(false);
  const [accCode, setAccCode] = useState("");
  const [accDesc, setAccDesc] = useState("");
  const [parentAccounts, setParentAccounts] = useState([]);

  function setAccData() {
    var Acnt_desc = document.getElementById("acnt-desc");
    Acnt_desc.innerHTML = `
        <b>Expense</b> <br>Reflects expenses incurred for running normal business operations, such as :<br/>
        <ul>
            <li>1.Advertisements and Marketing</li>
            <li>2.Business Travel Expenses</li>
            <li>3.License Fees</li>
            <li>4.Utility Expenses</li>
        </ul>`;
    var selectedValue = accType;
    var a = {
      Id: ID,
      type: selectedValue,
    };
    axios
      .get(`${config.base_url}/check_accounts/`, { params: a })
      .then((res) => {
        console.log("P ACC==", res);
        if (res.data.status) {
          document.getElementById("subAccountCheck").style.display = "block";
          var pAcc = res.data.accounts;
          setParentAccounts([]);
          pAcc.map((i) => {
            setParentAccounts((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }

  useEffect(() => {
    setAccData();
  }, []);

  function handleAccountTypeChange(value) {
    setAccType(value);
    accountTypeChange(value);
  }
  function handleNewAccSubmit(e) {
    e.preventDefault();
    var ac = {
      Id: ID,
      account_type: accType,
      account_name: accName,
      account_code: accCode,
      description: accDesc,
      sub_account: subAcc,
      parent_account: parentAccount,
    };
    if (subAcc && parentAccount == "") {
      alert("Select a parent account.!");
      return;
    }
    if (accName != "" && accType != "") {
      axios
        .post(`${config.base_url}/create_new_account_from_items/`, ac)
        .then((res) => {
          console.log("ACC RES=", res);
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "Account Created",
            });
            fetchPurchaseAccounts();
            setPurchaseAccount(ac.account_name);
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    } else {
      alert("Account name or Type cannot be blank.!");
    }
  }
      const [bills,setBills] = useState({});
      const [companydet,setCompanydet] = useState({});
      const [transportdet,setTransportdet] = useState({});
      const [ewayitems,setEwayItems] = useState({});
      const [itemsdet,setItemsdet] = useState([]);
      const [fileUrl, setFileUrl] = useState(null);
      const [customervalue,setCustomerValue] = useState({});
      const Fetch_Eway_Bill_Details = () =>{
        axios
          .get(`${config.base_url}/Fetch_Eway_Bill_Details/${id}/`)
          .then((res) => {
            if (res.data.status) {
              var bill = res.data.bills;
              var cmp = res.data.com;
              var trans = res.data.trans;
              var eway_item = res.data.eway_items;
              var item = res.data.items;
            //   if (bill.File) {
            //     var url = `${config.base_url}/${bill.File}`;
            //     setFileUrl(url);
            //   }
            var c= {
                value:bill.Customer,
                label:bill.CustomerName
              }
              setCustomerValue(c)
              setDocumenttype(bill.DocumentType);
              setTransactiontype(bill.TransactionSubtype);
              setCustomer(bill.Customer);
              setEmail(bill.CustomerEmail);
              setBillingAddress(bill.BillingAddress);
              setCustomerGstType(bill.Customer_GstType);
              setCustomerGstIn(bill.Customer_GstNumber);
              setCustomerPlaceOfSupply(bill.Customer_PlaceOfSupply);
              setEwayno(bill.Ewaybill_No);
              setDatechange(bill.Date);
              setDeliver(bill.DeliverToDifferentAddress);
              setTransportation(bill.Transportation);
              setKilometer(bill.Kilometer);
              setCgst(bill.Cgst);
              setIgst(bill.Igst);
              setSgst(bill.Sgst);
              setSubTotal(bill.SubTotal);
              setTaxAmount(bill.TaxAmount);
              setShippingCharge(bill.ShippingCharge);
              setAdjustment(bill.Adjustment);
              setGrandTotal(bill.GrandTotal);
              setRefNo(bill.ReferenceNumber);
              setVehiclenumber(bill.VehicleNumber);
              setEwaybillitems([]);
              const ewayitems = item.map((i)=>{
                if(i.item_type == "Goods"){
                  var hsnSac = i.hsn
                }else{
                  var hsnSac = i.sac
                }
                return {
                  id: 1,
                  item: i.itemId,
                  hsnSac: hsnSac,
                  quantity: i.quantity,
                  price: i.price,
                  taxGst: i.tax,
                  taxIgst: i.tax,
                  discount: i.discount,
                  total: i.total,
                  taxAmount: "",
                }
              })
              setEwaybillitems(ewayitems);
              console.log('items',ewayitems)
              refreshIndexes(ewayitems);
             
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
          });
      };
      useEffect(() => {
        Fetch_Eway_Bill_Details();
      }, []);
      const navigate = useNavigate();
      const Update_eway_bill = (e) =>{
        e.preventDefault();
        var dt = {
            Id:ID,
            eId:id,
            customer:customer,
            doctype:documenttype,
            transtype:transactiontype,
            ewayno:ewayno,
            refno:refNO,
            date:date,
            trans:trans,
            kilo:kilometer,
            items:Ewaybillitems,
            igst:igst,
            cgst:cgst,
            sgst:sgst,
            tax:taxAmount,
            grand:grandTotal,
            sub:subTotal,
            adjs:adjustment,
            ship:shippingCharge,
            sta:status,
            deliver:deliver,
            dname:deli_name,
            dadr:deli_adr,
            dno:deli_no,
            dmai:deli_mail,
            file:file,
            descri:description,
            Cplace:customerPlaceOfSupply,
            vnum:vehiclenumber,
            gstno:customerGstIn,
          }
          axios
            .put(`${config.base_url}/Update_Eway_Bill/`, dt)
            .then((res) => {
              if (res.data.status) {
                Toast.fire({
                  icon: "success",
                  title: "Eway Bill Updated",
                });
                navigate("/Eway_bill");
              }
              if (!res.data.status && res.data.message != "") {
                Swal.fire({
                  icon: "error",
                  title: `${res.data.message}`,
                });
              }
            })
            .catch((err) => {
              console.log("ERROR=", err);
              if (!err.response.data.status) {
                Swal.fire({
                  icon: "error",
                  title: `${err.response.data.message}`,
                });
              }
            });
      };

    return(
        <>
        <FinBase />
        <form className="needs-validation" onSubmit={Update_eway_bill}  style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}>
  <div className="page-content pt-0" >

  <div className="d-flex justify-content-end mb-1">
          <Link to={"/Eway_bill"}>
            <i
              className="fa fa-times-circle text-white mx-4 p-1"
              style={{ fontSize: "1.2rem", marginRight: "0rem !important" }}
            ></i>
          </Link>
          </div>
    <div className="card radius-15 h-20">
        <div className="row">
          <div className="col-md-12">
            <center><h2 className="mt-3">EDIT E-WAY BILL</h2></center>
            <hr/>
          </div>
        </div>
      </div>
    <div className="card radius-15">
      <div className="card-body">
        <div id="ewaybill">
            <div className="row">
                <div className="col-md-4 mt-2">
                    <label for="document_type">Document Type</label>
                    <div className="d-flex">
                        <select name="document_type" id="document_type" className="custom-select" required value={documenttype} onChange={(e) =>handleDocumenttype(e.target.value)}>
                            <option value="" disabled selected>Select Document Type</option>
                            <option value="Invoice">Invoice</option>
                            <option value="Credit Notes">Credit Notes</option>
                            <option value="Delivery Challan">Delivery Challan</option>
                        </select>
                    </div>
                </div>
                <div className="col-md-4 mt-2">
                    <label for="transaction_subtype">Transaction Sub Type</label>
                    <div className="d-flex">
                        <select name="transaction_subtype" id="transaction_subtype" className="custom-select"  required value={transactiontype} onChange={(e) =>handleTransactiontype(e.target.value)}>
                            <option value="" disabled selected>Select Transaction SubType</option>
                            <option value="Supply">Supply</option>
                            <option value="Export">Export</option>
                            <option value="SKD/CKD">SKD/CKD</option>
                        </select>
                    </div>
                </div>
            </div>
            <hr />
            <div className="customer-details-section">
                <br />
                <h5>CUSTOMER DETAILS</h5>
                <div className="row">
                    <div className="col-md-4 mt-3">
                        <label className="">Select Customer</label><span className="text-danger ml-3" id="custErr"></span>
                        <div className="d-flex">
                            <Select
                                options={customers}
                                styles={customStyles}
                                value={customervalue || null}
                                name="customer"
                                className="w-100"
                                id="customer"
                                required
                                onChange={(selectedOption) =>
                                  handleCustomerChange(
                                    selectedOption ? selectedOption.value : ""
                                  )
                                }
                                isClearable
                                isSearchable
                              />
                            <button type="button" data-toggle="modal" data-target="#newCustomer" className="btn btn-outline-secondary ml-1" style={{width:'fit-content',height:'fit-content',position:'relative',bottom:'10px'}}>+</button>
                        </div>

                        <label className="mt-3">GST Type</label>
                        <input type="text" className="form-control" id="gstType" name="gst_type" placeholder="GST Treatment" style={{backgroundColor:'#43596c'}} readonly value={customerGstType} />
                    </div>

                    <div className="col-md-4 mt-3">
                        <label className="">Email</label>
                        <input className="form-control" type="email" name="customer_email" placeholder="Email" style={{backgroundColor:'#43596c',color:'white'}} id="customerEmail" readonly value={email} />
                        {customerGstIn ? (
                          <div className="mt-3" id="gstInDisplay" style={{display:'block'}}>
                          <label className="">GSTIN</label>
                          <input type="text" className="form-control" id="gstin" name="gstin" placeholder="GSTIN" style={{backgroundColor:'#43596c'}} readonly value={customerGstIn}  />
                      </div>
                        ): null}
                        
                        
                    </div>

                    <div className="col-md-4 mt-3">
                        <label className="">Billing Address</label>
                        <textarea className="form-control" name="bill_address" id="billAddress" rows="4" style={{backgroundColor:'#43596c',color:'white'}} readonly value={billingAddress}></textarea>
                    </div>
                    <div className="col-md-4 mt-3">
                        <input hidden value="{{com.State}}" id="cmpState" />
                        <label className="">Place of supply</label>
                        <select type="text" className="form-control" id="placeOfSupply" name="place_of_supply" style={{backgroundColor:'#43596c',color:'white'}} required value={customerPlaceOfSupply}>
                            <option value="" selected>--Choose--</option>
                            <option value="Andaman and Nicobar Islads">Andaman and Nicobar Islads</option>
                            <option value="Andhra Predhesh">Andhra Predhesh</option>
                            <option value="Arunachal Predesh">Arunachal Predesh</option>
                            <option value="Assam">Assam</option>
                            <option value="Bihar">Bihar</option>
                            <option value="Chandigarh">Chandigarh</option>
                            <option value="Chhattisgarh">Chhattisgarh</option>
                            <option value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                            <option value="Damn anad Diu">Damn anad Diu</option>
                            <option value="Delhi">Delhi</option>
                            <option value="Goa">Goa</option>
                            <option value="Gujarat">Gujarat</option>
                            <option value="Haryana">Haryana</option>
                            <option value="Himachal Predesh">Himachal Predesh</option>
                            <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                            <option value="Jharkhand">Jharkhand</option>
                            <option value="Karnataka">Karnataka</option>
                            <option value="Kerala">Kerala</option>
                            <option value="Ladakh">Ladakh</option>
                            <option value="Lakshadweep">Lakshadweep</option>
                            <option value="Madhya Predesh">Madhya Predesh</option>
                            <option value="Maharashtra">Maharashtra</option>
                            <option value="Manipur">Manipur</option>
                            <option value="Meghalaya">Meghalaya</option>
                            <option value="Mizoram">Mizoram</option>
                            <option value="Nagaland">Nagaland</option>
                            <option value="Odisha">Odisha</option>
                            <option value="Puducherry">Puducherry</option>
                            <option value="Punjab">Punjab</option>
                            <option value="Rajasthan">Rajasthan</option>
                            <option value="Sikkim">Sikkim</option>
                            <option value="Tamil Nadu">Tamil Nadu</option>
                            <option value="Telangana">Telangana</option>
                            <option value="Tripura">Tripura</option>
                            <option value="Uttar Predesh">Uttar Predesh</option>
                            <option value="Uttarakhand">Uttarakhand</option>
                            <option value="West Bengal">West Bengal</option>
                            <option value="Other Territory">Other Territory</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 mt-3">
                    <div className="d-flex">
                        <label className="">E-Way Bill No.</label><span className="text-danger ml-3" id="ewayNoErr"></span>
                    </div>
                    <input type="text" className="form-control" name="Ewaybill_No"  id="ewayNumber" style={{backgroundColor:'#43596c'}} required value={ewayno} onChange={(e) =>handleEwayno(e.target.value)} placeholder={EWAYNO} />
                </div>
                <div className="col-md-4 mt-3">
                    <label className="">Reference Number</label>
                    <input type="text" className="form-control" name="reference_number" value={refNO} style={{backgroundColor:'#43596c'}} readonly />
                </div> 
                <div className="col-md-4 mt-3">
                    <label className="">Date</label>
                    <input type="date" className="form-control" name="date" id="date" style={{backgroundColor:'#43596c'}} value={date} onChange={(e) =>handleDatechange(e.target.value)} required />
                </div>
                
            </div>
            <hr />
            <div className="billing-details-section">
                <br />
                <h5>BILLING DETAILS</h5>
                <br />
                <div className="row">
                    <div className="col-md-4 mt-2">
                        <label for="deliver_to_different_address">Deliver to different address</label>
                        <div className="d-flex">
                            <select name="deliver_to_different_address" id="deliver_to_different_address" className="custom-select" onChange={(e) =>toggleDeliveryInfo(e.target.value)} value={deliver} >
                                <option value="" disabled selected>Select</option>
                                <option value="0">No</option>
                                <option value="1">Yes</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 mt-2" id="deliveryInfo" style={{display:'none'}}>
                        <label for="delivery_name">Delivery Name</label>
                        <input type="text" className="form-control" name="delivery_name" id="delivery_name" placeholder="Delivery Name" value={deli_name} onChange={(e) =>handledeliv_name(e.target.value)}  />
                    </div>
                    <div className="col-md-4 mt-2" id="deliveryAddressInfo" style={{display:'none'}}>
                        <label for="delivery_address">Delivery Address</label>
                        <textarea className="form-control" name="delivery_address" id="delivery_address" value={deli_adr} onChange={(e) =>handledeliv_adr(e.target.value)} ></textarea>
                    </div>
                    <div className="col-md-4 mt-2" id="deliveryPhoneInfo" style={{display:'none'}}>
                        <label for="delivery_phone">Delivery Phone</label>
                        <input type="text" className="form-control" name="delivery_phone" id="delivery_phone" placeholder="Delivery Phone" pattern="[0-9]{10}" title="Please enter a valid 10-digit phone number" value={deli_no} onChange={(e) =>handledeliv_no(e.target.value)} />
                    </div>
                    <div className="col-md-4 mt-2" id="deliveryEmailInfo" style={{display:'none'}}>
                        <label for="delivery_email">Delivery Email</label>
                        <input type="email" className="form-control" name="delivery_email" id="delivery_email" placeholder="Delivery Email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" title="Please enter a valid email address" value={deli_mail} onChange={(e) =>handledeliv_mail(e.target.value)} />
                    </div>
                    
            </div>
            <hr />
            <div className="transportation-details-section">
                <br />
                <h5>TRANSPORTATION DETAILS</h5>
                <br />
                <div className="row">
                    
                    <div className="col-md-3 mt-2">
                        <label for="">Transportation</label>
                        <div className="d-flex">
                            <select className="custom-select" name="transport_mode" id="transport_mode"  required value={trans} onChange={(e) =>handleTransport(e.target.value)} >
                                <option selected disabled>Choose Transportation</option>
                                {transport && transport.map((tran) => (
                                    <option value={tran.id}>
                                      {tran.Name}
                                    </option>
                                  ))}
                                
                            </select>
                            <a className="btn btn-outline-secondary ml-1" data-target="#new_transport" data-toggle="modal" style={{width:'fit-content',height:'fit-content',position:'relative',bottom:'10px'}}>+</a>
                        </div>
                        <div id="transportationError" style={{color:'red'}}></div>
                    </div>
                    {vehiclenumber ? (
                      <div className="col-md-3 mt-2" id="vehicle_number_segment" style={{flexDirection:'column'}}>
                      <label for="vehicle_number">Vehicle Number</label>
                      <div className="d-flex">
                          <input type="text" className="form-control" id="vehicle_number" name="vehicle_number" onChange={(e) =>handleVehicleNumber(e.target.value)} value={vehiclenumber} />
                      </div>
                      <div className="text-danger m-2" id="warnv_num"></div>
                  </div>
                    )  : null}
                    
                    <div className="col-md-3 mt-2">
                        <label for="kilometer_input">Kilometers</label>
                        <div className="d-flex">
                            <input type="number" className="form-control" id="kilometer_input" name="kilometer" required value={kilometer} onChange={(e) =>handleKilometer(e.target.value)} />
                        </div>
                    </div>
                </div>
            </div> 
            
            <hr />
            <div className="item-details-section">
                <br />
                <h5>ITEM DETAILS</h5>
                <div className="row clearfix ">
                    <div className="col-md-12 table-responsive-md mt-3">
                        <table className="table table-bordered table-hover mt-3" id="ewaybillItemsTable">
                            <thead>
                                <tr>
                                    <th className="text-center">#</th>
                                    <th className="text-center">PRODUCT / SERVICE</th>
                                    <th className="text-center">HSN / SAC</th>
                                    <th className="text-center">QTY</th>
                                    <th className="text-center">PRICE</th>
                                    <th className="text-center">TAX (%)</th>
                                    <th className="text-center">DISCOUNT</th>
                                    <th className="text-center">TOTAL</th>
                                </tr>
                            </thead>
                            <tbody id="items-table-body">
                              {Ewaybillitems.map((row) =>{
                                const selectedOptionI = items.find(
                                    (option) => option.value === row.item
                                  )
                                  return(
                                <tr key={row.id} id={`tab_row${row.id}`}>
                                    <td className="nnum" style={{textAlign:'center'}}>{row.id}</td>
                                    <td style={{width:'20%'}}>
                                        <div className="d-flex align-items-center">
                                                        <Select
                                                          options={items}
                                                          styles={customStyles}
                                                          name="item"
                                                          className="w-100"
                                                          id={`item${row.id}`}
                                                          value={selectedOptionI}
                                                          required
                                                          onChange={(selectedOption) =>
                                                            handleItemChange(
                                                              selectedOption
                                                                ? selectedOption.value
                                                                : "",
                                                              row.id
                                                            )
                                                          }
                                                          onBlur={refreshValues}
                                                          isClearable
                                                          isSearchable
                                                        />
                                                    
                                            <a href="#">
                                                <button type="button" className="btn btn-outline-secondary ml-1" data-target="#newItem" data-toggle="modal" style={{width:'fit-content',height:'fit-content',position:'relative',bottom:'1px'}}>+</button>
                                            </a>
                                        </div>
                                    
                                    </td>
                                    <td>
                              <input
                                type="text"
                                name="hsnSac"
                                value={row.hsnSac}
                                id={`hsn${row.id}`}
                                placeholder="HSN/SAC Code"
                                className="form-control HSNCODE"
                                style={{
                                  backgroundColor: "#43596c",
                                  color: "white",
                                }}
                                readOnly
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="qty[]"
                                id={`qty${row.id}`}
                                className="form-control qty"
                                step="0"
                                min="1"
                                style={{
                                  backgroundColor: "#43596c",
                                  color: "white",
                                }}
                                value={row.quantity}
                                onChange={(e) =>
                                  handleEwaybillItemsInputChange(
                                    row.id,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                onBlur={refreshValues}
                                required
                              />
                            </td>
                            <td>
                              <input
                                type="number"
                                name="price"
                                id={`price${row.id}`}
                                className="form-control price"
                                step="0.00"
                                min="0"
                                style={{
                                  backgroundColor: "#43596c",
                                  color: "white",
                                  display: "block",
                                }}
                                value={row.price}
                                readOnly
                              />
                              <input
                                type="number"
                                name="priceListPrice"
                                id={`priceListPrice${row.id}`}
                                className="form-control priceListPrice"
                                step="0.00"
                                min="0"
                                style={{
                                  backgroundColor: "#43596c",
                                  color: "white",
                                  display: "none",
                                }}
                                value={row.priceListPrice}
                                readOnly
                              />
                            </td>

                            <td style={{ width: "13%" }}>
                              <select
                                name="taxGST"
                                id={`taxGST${row.id}`}
                                className="form-control tax_ref tax_ref_gst"
                                style={{ display: "block" }}
                                value={row.taxGst}
                                onChange={(e) =>
                                  handleEwaybillItemsInputChange(
                                    row.id,
                                    "taxGst",
                                    e.target.value
                                  )
                                }
                                onBlur={refreshValues}
                              >
                                <option value="">Select GST</option>
                                <option value="28">28.0% GST</option>
                                <option value="18">18.0% GST</option>
                                <option value="12">12.0% GST</option>
                                <option value="5">05.0% GST</option>
                                <option value="3">03.0% GST</option>
                                <option value="0">0.0% GST</option>
                              </select>
                              <select
                                name="taxIGST"
                                id={`taxIGST${row.id}`}
                                className="form-control tax_ref tax_ref_igst"
                                style={{ display: "none" }}
                                value={row.taxIgst}
                                onChange={(e) =>
                                  handleEwaybillItemsInputChange(
                                    row.id,
                                    "taxIgst",
                                    e.target.value
                                  )
                                }
                                onBlur={refreshValues}
                              >
                                <option value="">Select IGST</option>
                                <option value="28">28.0% IGST</option>
                                <option value="18">18.0% IGST</option>
                                <option value="12">12.0% IGST</option>
                                <option value="5">05.0% IGST</option>
                                <option value="3">03.0% IGST</option>
                                <option value="0">0.0% IGST</option>
                              </select>
                            </td>
                            <td>
                              <input
                                type="number"
                                name="discount"
                                placeholder="Enter Discount"
                                id={`disc${row.id}`}
                                value={row.discount}
                                onChange={(e) =>
                                  handleEwaybillItemsInputChange(
                                    row.id,
                                    "discount",
                                    e.target.value
                                  )
                                }
                                onBlur={refreshValues}
                                className="form-control disc"
                                step="0"
                                min="0"
                                style={{
                                  backgroundColor: "#43596c",
                                  color: "white",
                                }}
                              />
                            </td>

                            <td>
                              <input
                                type="number"
                                name="total"
                                id={`total${row.id}`}
                                className="form-control total"
                                value={row.total}
                                readOnly
                                style={{
                                  backgroundColor: "#43596c",
                                  color: "white",
                                }}
                              />
                              <input
                                type="hidden"
                                id={`taxamount${row.id}`}
                                className="form-control itemTaxAmount"
                                value={row.taxAmount}
                              />
                            </td>
                            <td>
                              <button
                                type="button"
                                id={`${row.id}`}
                                style={{
                                  width: "fit-content",
                                  height: "fit-content",
                                }}
                                onClick={() => removeRow(row.id)}
                                className="btn btn-danger remove_row px-2 py-1 mx-1 fa fa-close"
                                title="Remove Row"
                              ></button>
                            </td>
                                </tr>
                                )
                                })}
                            </tbody>
                            <tr>
                                <td style={{border:'none'}}><a className="btn btn-outline-secondary ml-1" role="button" id="add" style={{width:'fit-content',height:'fit-content'}} onClick={addNewRow}>+</a></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div> 
            <div className="row clearfix" style={{marginTop:'20px'}}>
                <div className="col-md-6">
                    <textarea className="form-control mt-3"  id="" name="note" placeholder="Note" style={{height:'190px'}} value={description}
                      onChange={(e) => setDescription(e.target.value)}></textarea>
                    <input type="file" name="file"  style={{marginTop:'10px',width:'70%'}} onChange={(e) => setFile(e.target.files[0])} />
                </div>            
                <div className="col-md-1"></div>
                <div className="col-md-5 table-responsive-md mt-3 " id="ewaybillItemsTableTotal" style={{backgroundColor:'rgba(0,0,0,.4)',border:'1px solid rgba(128, 128, 128, 0.6)',marginLeft:'-2vh'}}>
                    <div className="p-3">
                        <div className="row container-fluid p-2 m-0">
                            <div className="col-sm-4 mt-2"><label className="text-center">Sub Total</label></div>
                            <div className="col-sm-1 mt-2">:</div>
                            <div className="col-sm-7 mt-2"><input type="number" step="any" name='subtotal' value={subTotal} readonly style={{backgroundColor:'#37444f'}} className="form-control" id="sub_total" /></div>
                        </div>
                        <div className="row container-fluid p-2 m-0" id="taxamountIGST" style={{display:'flex'}}>
                            <div className="col-sm-4 mt-2"><label for="a" className="text-center">IGST</label></div>
                            <div className="col-sm-1 mt-2">:</div>
                            <div className="col-sm-7 mt-2"><input type="number" name='igst' step="any" id="igstAmount" value={igst} readonly style={{backgroundColor:'#37444f'}} className="form-control"/></div>
                        </div>
                        <div className="row container-fluid p-2 m-0" style={{display:'none'}} id="taxamountCGST">
                            <div className="col-sm-4 mt-2"><label for="a" className="text-center">CGST</label></div>
                            <div className="col-sm-1 mt-2">:</div>
                            <div className="col-sm-7 mt-2"><input type="number" name='cgst' step="any" id="cgstAmount" value={cgst} readonly style={{backgroundColor:'#37444f'}} className="form-control"/></div>
                        </div>
                        <div className="row container-fluid p-2 m-0" style={{display:'none'}} id="taxamountSGST">
                            <div className="col-sm-4 mt-2"><label for="a" className="text-center">SGST</label></div>
                            <div className="col-sm-1 mt-2">:</div>
                            <div className="col-sm-7 mt-2"><input type="number" name='sgst' step="any" id="sgstAmount" value={sgst} readonly style={{backgroundColor:'#37444f'}} className="form-control"/></div>
                        </div>
                        <div className="row container-fluid p-2 m-0">
                            <div className="col-sm-4 mt-2"><label for="a" className="text-center">Tax Amount</label></div>
                            <div className="col-sm-1 mt-2">:</div>
                            <div className="col-sm-7 mt-2"><input type="number" step="any" name='taxamount' id="tax_amount" value={taxAmount} readonly style={{backgroundColor:'#37444f'}} className="form-control"/></div>
                        </div>
                        
                        <div className="row container-fluid p-2 m-0">
                            <div className="col-sm-4 mt-2"><label for="a" className="text-center">Shipping Charge</label></div>
                            <div className="col-sm-1 mt-2">:</div>
                            <div className="col-sm-7 mt-2"><input type="number" step="any" name='ship' id="ship" value={shippingCharge} className="form-control" onChange={(e) =>handleShipping(e.target.value)} /></div>
                        </div>
                        <div className="row container-fluid p-2 m-0">
                            <div className="col-sm-4 mt-2"><label for="a" className="text-center">Adjustment</label></div>
                            <div className="col-sm-1 mt-2">:</div>
                            <div className="col-sm-7 mt-2"><input type="number" step="any" name='adj' id="adj" value={adjustment} className="form-control" onChange={(e) =>handleAdjustment(e.target.value)} /></div>
                        </div>
                        <div className="row container-fluid p-2 m-0">
                            <div className="col-sm-4 mt-2"><label for="a" className="text-center">Grand Total</label></div>
                            <div className="col-sm-1 mt-2">:</div>
                            <div className="col-sm-7 mt-2"><input type="number" name='grandtotal' id="grandtotal" value={grandTotal} readonly style={{backgroundColor:'#37444f'}} className="form-control"/></div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-md-7 mt-3">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="agreeTerms" required style={{backgroundColor:'#43596c'}} />
                        <label for="agreeTerms">Agree to terms and conditions</label>
                        <div className="invalid-feedback">You must agree before submitting.</div>
                    </div>
                </div>
                <div className="col-md-5 mt-3 d-flex">
                     <input type="submit" className="btn btn-outline-secondary w-50 text-light" name="Cancel" value="Cancel" onClick={() =>navigate(`/edit_eway_bill/${id}`)} /> 
                    <input type="submit" className="btn btn-outline-secondary w-50 ml-1 text-light" name="Save" value="Save" onClick={() =>setStatus("Save")} />
                </div>
            </div>
            <div className="notices mt-3">
                <div className="text-muted">NOTICE:</div>
                <div className="text-muted">Accuhub Terms and Conditions Apply</div>
            </div>
            <footer className="text-muted">E-WayBill was created on a computer and is valid without the signature and seal.</footer>
        </div>
      </div>
    </div>
  </div>
  </div>
</form>


<div className="modal fade" id="newCustomer">
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">New Customer</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form method="post" id="newCustomerForm" className="px-1">
                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="title">Title</label>
                      <select
                        name="title"
                        id="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        className="form-control"
                      >
                        <option value="Mr">Mr</option>
                        <option value="Mrs">Mrs</option>
                        <option value="Miss">Miss</option>
                        <option value="Ms">Ms</option>
                      </select>
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="firstName">First Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="firstName"
                        name="first_name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        onBlur={checkFirstName}
                        required
                        style={{ backgroundColor: "#43596c", color: "white" }}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="lastName">Last Name*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="lastName"
                        name="last_name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        onBlur={checkLastName}
                        required
                        style={{ backgroundColor: "#43596c", color: "white" }}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                  </div>

                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="companyName">Company</label>
                      <input
                        type="text"
                        className="form-control"
                        id="companyName"
                        name="company_name"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                        style={{ backgroundColor: "#43596c", color: "white" }}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="location">Location</label>
                      <input
                        type="text"
                        className="form-control"
                        id="location"
                        name="location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        style={{ backgroundColor: "#43596c", color: "white" }}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <div className="col-md-4">
                      <label for="custPlaceOfSupply">Place of Supply*</label>
                      <select
                        className="custom-select form-control"
                        id="custPlaceOfSupply"
                        name="place_of_supply"
                        value={customerPlaceOfSupply}
                        onChange={(e) =>
                          setCustomerPlaceOfSupply(e.target.value)
                        }
                        style={{ backgroundColor: "#43596c", color: "white" }}
                        required
                      >
                        <option selected value="">
                          Select Place of Supply
                        </option>
                        <option value="Andaman and Nicobar Islads">
                          Andaman and Nicobar Islands
                        </option>
                        <option value="Andhra Predhesh">Andhra Predhesh</option>
                        <option value="Arunachal Predesh">
                          Arunachal Predesh
                        </option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Dadra and Nagar Haveli">
                          Dadra and Nagar Haveli
                        </option>
                        <option value="Damn anad Diu">Damn anad Diu</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Predesh">
                          Himachal Predesh
                        </option>
                        <option value="Jammu and Kashmir">
                          Jammu and Kashmir
                        </option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Ladakh">Ladakh</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Madhya Predesh">Madhya Predesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Predesh">Uttar Predesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                        <option value="Other Territory">Other Territory</option>
                      </select>
                      <div className="invalid-feedback">
                        Please select a valid registration type.
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="gstType">GST Type*</label>
                      <select
                        className="form-control"
                        id="custGstType"
                        name="gst_type"
                        value={customerGstType}
                        onChange={(e) => handleGstType(e.target.value)}
                        style={{ backgroundColor: "#43596c", color: "white" }}
                        required
                      >
                        <option selected value="">
                          Select GST Type
                        </option>
                        <option value="Registered Business - Regular">
                          Registered Business - Regular{" "}
                          <span>
                            <i>(Business that is registered under gst)</i>
                          </span>
                        </option>
                        <option value="Registered Business - Composition">
                          Registered Business - Composition (Business that is
                          registered under composition scheme in gst)
                        </option>
                        <option value="Unregistered Business">
                          Unregistered Business (Business that has not been
                          registered under gst)
                        </option>
                        <option value="Overseas">
                          Overseas (Import/Export of supply outside india)
                        </option>
                        <option value="Consumer">Consumer</option>
                        <option value="Special Economic Zone (SEZ)">
                          Special Economic Zone (SEZ) (Business that is located
                          in a special economic zone of india or a SEZ
                          developer)
                        </option>
                        <option value="Demed Exports">
                          Demed Exports (Supply of woods to an exports oriented
                          unit or againsed advanced authorization or export
                          promotion capital woods)
                        </option>
                        <option value="Tax Deductor">
                          Tax Deductor (State of central gov,government agencies
                          or local authority)
                        </option>
                        <option value="SEZ Developer">
                          SEZ Developer (A person or organization who owns
                          atleast 26% equality in creating business units in
                          special economic zone)
                        </option>
                      </select>
                      <div className="invalid-feedback">
                        Please select a valid registration type.
                      </div>
                    </div>

                    <div className="col-md-4 gstrow d-block" id="gstInValue">
                      <div>
                        <label for="custGstIN">GSTIN*</label>
                        <input
                          type="text"
                          className="form-control"
                          value={customerGstIn}
                          onChange={(e) => setCustomerGstIn(e.target.value)}
                          onBlur={(e) => checkgst(e.target.value)}
                          id="gstIN"
                          name="gstin"
                          style={{ backgroundColor: "#43596c", color: "white" }}
                          placeholder="29APPCK7465F1Z1"
                        />
                        <a
                          data-toggle="modal"
                          href="#exampleModal"
                          style={{ color: "#3dd5f3" }}
                        >
                          Get Taxpayer Details
                        </a>
                        <div className="text-danger m-2" id="warngst"></div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <label for="panNo">PAN No.*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="panNo"
                        name="pan_no"
                        style={{ backgroundColor: "#43596c", color: "white" }}
                        required
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                        onBlur={(e) => checkpan(e.target.value)}
                        placeholder="APPCK7465F"
                      />
                      <div className="text-danger m-2" id="warnpan"></div>
                    </div>
                  </div>

                  <div className="row w-100">
                    <div className="col-md-4 mt-3">
                      <label for="validationCustom05">Opening Balance</label>
                      <div className="d-flex">
                        <select
                          name="balance_type"
                          id="bal"
                          className="form-select text-white mr-1 px-1"
                          value={oBalType}
                          onChange={(e) => handleOpenBalType(e.target.value)}
                          style={{
                            backgroundColor: "#243e54",
                            width: "25%",
                            borderRadius: "5px",
                          }}
                        >
                          <option value="debit">Debit</option>
                          <option value="credit">Credit</option>
                        </select>
                        <input
                          type="text"
                          className="form-control"
                          name="open_balance"
                          id="openbalance"
                          value={oBal}
                          onChange={(e) => setOBal(e.target.value)}
                          onBlur={(e) => setOpeningBalanceValue(e.target.value)}
                          step="any"
                          style={{ backgroundColor: "#43596c", color: "white" }}
                        />
                        <div className="text-danger m-2"></div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label for="creditLimit">Credit Limit</label>
                      <input
                        type="text"
                        className="form-control"
                        name="credit_limit"
                        style={{ backgroundColor: "#43596c", color: "white" }}
                        step="any"
                        value={creditLimit}
                        onChange={(e) => setCreditLimit(e.target.value)}
                        id="creditLimit"
                      />
                      <div className="text-danger m-2"></div>
                    </div>

                    <div className="col-md-4 mt-2">
                      <label for="custPaymentTerms" style={{position:'relative',top:'10px'}}>Payment Terms</label>
                      <div className="d-flex align-items-center">
                        <select
                          name="payment_terms"
                          id="custPaymentTerms"
                          value={paymentTerm}
                          onChange={(e) => setPaymentTerm(e.target.value)}
                          className="form-control"
                        >
                          <option value="" selected>
                            Choose
                          </option>
                          {terms &&
                          terms.map((term) => (
                            <option value={term.id} text={term.days}>
                              {term.term_name}
                            </option>
                          ))}
                        </select>
                        <a
                          href="#newCustomerPaymentTerm"
                          data-dismiss="modal"
                          data-toggle="modal"
                          style={{
                            width: "fit-content",
                            height: "fit-content",
                          }}
                          className="btn btn-outline-secondary ml-1"
                        >
                          +
                        </a>
                      </div>
                    </div>

                    <div className="col-md-4 mt-3">
                      <label for="priceList">Price List</label>
                      <select
                        name="price_list"
                        id="priceList"
                        value={customerPriceList}
                        onChange={(e) => setCustomerPriceList(e.target.value)}
                        className="form-control"
                      >
                        <option value="" selected>
                          Choose
                        </option>
                        {customerPriceLists.map((l) => (
                          <option value={l.id}>{l.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row mt-3 w-100">
                    <div className="col-md-4">
                      <label for="custEmail">Email*</label>
                      <input
                        type="email"
                        className="form-control"
                        required
                        id="custEmail"
                        name="email"
                        value={customerEmail}
                        onChange={(e) => setCustomerEmail(e.target.value)}
                        onBlur={(e) => checkemail(e.target.value)}
                        style={{ backgroundColor: "#43596c", color: "white" }}
                        placeholder="accuhub@gmail.com"
                      />
                      <div id="warnemail" className="text-danger"></div>
                    </div>
                    <div className="col-md-4">
                      <label for="custWebsite">Website</label>
                      <input
                        type="text"
                        className="form-control"
                        id="custWebsite"
                        required
                        placeholder="www.accuhub.com"
                        value={website}
                        onChange={(e) => setWebsite(e.target.value)}
                        onBlur={(e) => checkweb(e.target.value)}
                        name="website"
                        style={{ backgroundColor: "#43596c", color: "white" }}
                      />
                      <div id="warnweb" className="text-danger"></div>
                    </div>
                    <div className="col-md-4">
                      <label for="custMobile">Mobile*</label>
                      <input
                        type="text"
                        className="form-control"
                        id="custMobile"
                        required
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        onBlur={(e) => checkphone(e.target.value)}
                        name="mobile"
                        style={{ backgroundColor: "#43596c", color: "white" }}
                      />
                      <div className="text-danger m-2" id="warnphone"></div>
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-5 w-100">
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 card-title">
                          <h5 className="mb-0">Billing Address</h5>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="form-row">
                            <label for="street">Street*</label>
                            <textarea
                              className="form-control street"
                              required
                              id="street"
                              value={bStreet}
                              onChange={(e) => setBStreet(e.target.value)}
                              name="street"
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Street
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="city">City*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="city"
                              name="city"
                              value={bCity}
                              onChange={(e) => setBCity(e.target.value)}
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                              placeholder="City"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid City
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="state">State*</label>
                            <select
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              required
                              value={bState}
                              onChange={(e) => setBState(e.target.value)}
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                            >
                              <option value="" selected hidden>
                                Choose
                              </option>
                              <option value="Andaman and Nicobar Islads">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Andhra Predhesh">
                                Andhra Predhesh
                              </option>
                              <option value="Arunachal Predesh">
                                Arunachal Predesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadra and Nagar Haveli">
                                Dadra and Nagar Haveli
                              </option>
                              <option value="Damn anad Diu">
                                Damn anad Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Predesh">
                                Himachal Predesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Ladakh">Ladakh</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Madhya Predesh">
                                Madhya Predesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Predesh">
                                Uttar Predesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                              <option value="Other Territory">
                                Other Territory
                              </option>
                            </select>
                            <div className="invalid-feedback">
                              Please provide a valid State
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="pinco">Pin Code*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="pinco"
                              value={bPincode}
                              onChange={(e) => setBPincode(e.target.value)}
                              name="pincode"
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                              placeholder="PIN code"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Pin Code
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="country">Country*</label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              id="country"
                              name="country"
                              value={bCountry}
                              onChange={(e) => setBCountry(e.target.value)}
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                              placeholder="Country"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Country
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-12 d-flex">
                          <h5>Shipping Address</h5>
                          <input
                            className="ml-4 ml-5"
                            type="checkbox"
                            onClick={placeShipAddress}
                            id="shipAddress"
                            name="ship_address"
                          />
                          <label className="ml-2 mt-1 ml-2" for="shipAddress">
                            Same As Billing Address
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <div className="form-row">
                            <label for="shipstreet">Street</label>
                            <textarea
                              className="form-control"
                              id="shipstreet"
                              name="shipstreet"
                              value={sStreet}
                              onChange={(e) => setSStreet(e.target.value)}
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Street
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipcity">City</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shipcity"
                              value={sCity}
                              onChange={(e) => setSCity(e.target.value)}
                              name="shipcity"
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                              placeholder="City"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid City
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipstate">State</label>
                            <select
                              type="text"
                              className="form-control"
                              id="shipState"
                              value={sState}
                              onChange={(e) => setSState(e.target.value)}
                              name="shipstate"
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                            >
                              <option value="" selected>
                                Choose
                              </option>
                              <option value="Andaman and Nicobar Islads">
                                Andaman and Nicobar Islands
                              </option>
                              <option value="Andhra Predhesh">
                                Andhra Predhesh
                              </option>
                              <option value="Arunachal Predesh">
                                Arunachal Predesh
                              </option>
                              <option value="Assam">Assam</option>
                              <option value="Bihar">Bihar</option>
                              <option value="Chandigarh">Chandigarh</option>
                              <option value="Chhattisgarh">Chhattisgarh</option>
                              <option value="Dadra and Nagar Haveli">
                                Dadra and Nagar Haveli
                              </option>
                              <option value="Damn anad Diu">
                                Damn anad Diu
                              </option>
                              <option value="Delhi">Delhi</option>
                              <option value="Goa">Goa</option>
                              <option value="Gujarat">Gujarat</option>
                              <option value="Haryana">Haryana</option>
                              <option value="Himachal Predesh">
                                Himachal Predesh
                              </option>
                              <option value="Jammu and Kashmir">
                                Jammu and Kashmir
                              </option>
                              <option value="Jharkhand">Jharkhand</option>
                              <option value="Karnataka">Karnataka</option>
                              <option value="Kerala">Kerala</option>
                              <option value="Ladakh">Ladakh</option>
                              <option value="Lakshadweep">Lakshadweep</option>
                              <option value="Madhya Predesh">
                                Madhya Predesh
                              </option>
                              <option value="Maharashtra">Maharashtra</option>
                              <option value="Manipur">Manipur</option>
                              <option value="Meghalaya">Meghalaya</option>
                              <option value="Mizoram">Mizoram</option>
                              <option value="Nagaland">Nagaland</option>
                              <option value="Odisha">Odisha</option>
                              <option value="Puducherry">Puducherry</option>
                              <option value="Punjab">Punjab</option>
                              <option value="Rajasthan">Rajasthan</option>
                              <option value="Sikkim">Sikkim</option>
                              <option value="Tamil Nadu">Tamil Nadu</option>
                              <option value="Telangana">Telangana</option>
                              <option value="Tripura">Tripura</option>
                              <option value="Uttar Predesh">
                                Uttar Predesh
                              </option>
                              <option value="Uttarakhand">Uttarakhand</option>
                              <option value="West Bengal">West Bengal</option>
                              <option value="Other Territory">
                                Other Territory
                              </option>
                            </select>
                            <div className="invalid-feedback">
                              Please provide a valid State
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shippinco">Pin Code</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shippinco"
                              value={sPincode}
                              onChange={(e) => setSPincode(e.target.value)}
                              name="shippincode"
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                              placeholder="PIN code"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Pin Code
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <div className="form-row">
                            <label for="shipcountry">Country</label>
                            <input
                              type="text"
                              className="form-control"
                              id="shipcountry"
                              name="shipcountry"
                              value={sCountry}
                              onChange={(e) => setSCountry(e.target.value)}
                              style={{
                                backgroundColor: "#43596c",
                                color: "white",
                              }}
                              placeholder="Country"
                            />
                            <div className="invalid-feedback">
                              Please provide a valid Country
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 w-100">
                    <div className="col-4"></div>
                    <div className="col-4 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-secondary text-grey w-75"
                        onClick={handleNewCustomerModalSubmit}
                        data-dismiss="modal"
                        type="button"
                        id="newCustomerSave"
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-4"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="newCustomerPaymentTerm">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">New Customer Payment Term</h5>
              <button
                type="button"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                data-target="#newCustomer"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                  method="post"
                  id="newCustomerPaymentTermForm"
                  onSubmit={handlePaymentTermModalSubmit}
                >
                  <div className="row mt-2 w-100">
                    <div className="col-6">
                      <label for="name">Term Name</label>
                      <input
                        type="text"
                        name="term_name"
                        value={newPaymentTerm}
                        onChange={(e) => setNewPaymentTerm(e.target.value)}
                        id="custTermName"
                        className="form-control w-100"
                      />
                    </div>
                    <div className="col-6">
                      <label for="name">Days</label>
                      <input
                        type="number"
                        name="days"
                        id="custTermDays"
                        className="form-control w-100"
                        min="0"
                        value={newPaymentTermDays}
                        onChange={(e) => setNewPaymentTermDays(e.target.value)}
                        step="1"
                      />
                    </div>
                  </div>
                  <div className="row mt-4 w-100">
                    <div className="col-4"></div>
                    <div className="col-4 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-secondary text-grey w-75"
                        onClick={handlePaymentTermModalSubmit}
                        data-toggle="modal"
                        data-target="#newCustomer"
                        type="button"
                        id="saveCustomerPaymentTerm"
                      >
                        Save
                      </button>
                    </div>
                    <div className="col-4"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

<div className="modal fade" id="new_transport">
    <div className="modal-dialog">
        <div className="modal-content" style={{backgroundColor:'#213b52'}}>
            <div className="modal-header">
                <h5 className="m-3">New Transportation Mode</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <div className="card p-3 m-3">
                    <form action="" method="post" autocomplete="off" id="new_transport_form">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="transportation_name">Transportation Name</label>
                                    <input type="text" name="transportation_name" className="form-control" id="transportation_name" />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label for="transportation_type">Transportation Type</label>
                                    <select name="transport_type" id="transportation_type" className="form-control" required>
                                        <option selected disabled>--Type--</option>
                                        <option value="Air Cargo">Air Cargo</option>
                                        <option value="Road">Road</option>
                                        <option value="Rail">Rail</option>
                                        <option value="Waterborne">Waterborne</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-center">
                                <button className="btn btn-outline-info text-grey mb-3" data-dismiss="modal" id="savetransport" style={{width:'fit-content',height:'fit-content'}}>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div className="modal fade" id="newItem">
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">New Item</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                  className="needs-validation px-1"
                  validate
                >
                  <div className="row w-100">
                    <div className="col-md-12 mx-0">
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label for="itemName" style={{ color: "white" }}>
                            Name
                          </label>
                          <input
                            type="text"
                            id="itemName"
                            name="name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            autocomplete="off"
                            required
                          />
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="itemType" style={{ color: "white" }}>
                            Type
                          </label>
                          <select
                            name="type"
                            className="form-control"
                            id="itemType"
                            value={type}
                            onChange={(e) => {
                              setType(e.target.value);
                              itemTypeChange();
                            }}
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            required
                          >
                            <option selected disabled value="">
                              Choose...
                            </option>
                            <option value="Goods">Goods</option>
                            <option value="Services">Services</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-1">
                          <label for="itemUnit" style={{ color: "white",position:'relative',top:'10px' }}>
                            Unit
                          </label>
                          <div className="d-flex align-items-center">
                            <select
                              className="custom-select"
                              name="unit"
                              id="itemUnit"
                              value={unit}
                              onChange={(e) => setUnit(e.target.value)}
                              required
                              style={{
                                backgroundColor: "#2a4964",
                                color: "white",
                              }}
                            >
                              <option selected disabled value="">
                                Choose...
                              </option>
                              {units &&
                                units.map((i) => (
                                  <option
                                    value={i.name}
                                    className="text-uppercase"
                                  >
                                    {i.name}
                                  </option>
                                ))}
                            </select>
                              <button
                                type="button"
                                className="btn btn-outline-secondary ml-1"
                                data-toggle="modal"
                                data-dismiss="modal"
                                data-target="#createNewUnit"
                                style={{
                                  width: "fit-content",
                                  height: "fit-content",
                                }}
                              >
                                +
                              </button>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3" id="hsnDiv">
                          <label for="hsnField" style={{ color: "white" }}>
                            HSN Code
                          </label>
                          <input
                            type="number"
                            name="hsn"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            placeholder="Enter a valid HSN code"
                            required
                            value={hsn}
                            onChange={(e) => setHsn(e.target.value)}
                            id="hsnField"
                            onInput={validateHSN}
                          />
                          <div id="hsnError" style={{ color: "red" }}></div>
                        </div>
                        <div
                          className="col-md-6 mt-3"
                          id="sacDiv"
                          style={{ display: "none" }}
                        >
                          <label for="sacField" style={{ color: "white" }}>
                            SAC Code
                          </label>
                          <input
                            type="number"
                            name="sac"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            placeholder="Enter a valid SAC code"
                            required
                            value={sac}
                            onChange={(e) => setSac(e.target.value)}
                            id="sacField"
                            onInput={validateSAC}
                          />
                          <div id="sacError" style={{ color: "red" }}></div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-3 mt-3">
                          <label style={{ color: "white" }}>
                            Tax Reference
                          </label>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              name="taxref"
                              type="radio"
                              id="inclusive"
                              value="taxable"
                              onChange={(e) => setTaxRef(e.target.value)}
                              onClick={showdiv}
                              required
                            />
                            <label style={{ color: "white" }} for="inclusive">
                              taxable
                            </label>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="form-check mt-1">
                            <input
                              className="form-check-input"
                              name="taxref"
                              type="radio"
                              value="non taxable"
                              onChange={(e) => setTaxRef(e.target.value)}
                              id="check"
                              onClick={hidediv}
                            />
                            <label style={{ color: "white" }} for="check">
                              non taxable
                            </label>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row"
                        id="taxableDiv"
                        style={{ display: "none" }}
                      >
                        <div className="col-md-6 mt-3">
                          <label for="intraStateTax" style={{ color: "white" }}>
                            Intra State Tax Rate
                          </label>
                          <select
                            name="intra_st"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            id="intraStateTax"
                            value={intraStateTax}
                            onChange={(e) => setIntraStateTax(e.target.value)}
                          >
                            <option value="0">GST 0 (0%)</option>
                            <option value="3">GST 3 (3%)</option>
                            <option value="5">GST 5 (5%)</option>
                            <option value="12">GST 12 (12%)</option>
                            <option value="18">GST 18 (18%)</option>
                            <option value="28">GST 28 (28%)</option>
                          </select>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="interStateTax" style={{ color: "white" }}>
                            Inter State Tax Rate
                          </label>
                          <select
                            name="inter_st"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            id="interStateTax"
                            value={interStateTax}
                            onChange={(e) => setInterStateTax(e.target.value)}
                          >
                            <option value="0">IGST 0 (0%)</option>
                            <option value="3">IGST 3 (3%)</option>
                            <option value="5">IGST 5 (5%)</option>
                            <option value="12">IGST 12 (12%)</option>
                            <option value="18">IGST 18 (18%)</option>
                            <option value="28">IGST 28 (28%)</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label style={{ color: "white" }}>
                            Purchase Price
                          </label>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <input
                                type="text"
                                className="form-control mr-1"
                                value="INR"
                                style={{
                                  width: "60px",
                                  backgroundColor: "#2a4960",
                                  color: "white;",
                                }}
                              />
                              <input
                                type="number"
                                name="pcost"
                                className="form-control"
                                id="purprice"
                                style={{
                                  backgroundColor: "#2a4964",
                                  color: "white",
                                }}
                                value={purchasePrice}
                                onChange={(e) =>
                                  setPurchasePrice(e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label style={{ color: "white" }}>Sales Price</label>
                          <div className="row">
                            <div className="col-md-12 d-flex">
                              <input
                                type="text"
                                className="form-control mr-1"
                                value="INR"
                                style={{
                                  width: "60px",
                                  backgroundColor: "#2a4960",
                                  color: "white;",
                                }}
                              />
                              <input
                                type="text"
                                name="salesprice"
                                className="form-control"
                                id="saleprice"
                                style={{
                                  backgroundColor: "#2a4964",
                                  color: "white",
                                }}
                                value={salesPrice}
                                onChange={(e) => setSalesPrice(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label
                            for="purchaseAccount"
                            style={{ color: "white" }}
                          >
                            Account
                          </label>
                          <div className="d-flex align-items-center">
                            <select
                              name="pur_account"
                              className="form-control"
                              style={{
                                backgroundColor: "#2a4964",
                                color: "white",
                              }}
                              id="purchaseAccount"
                              value={purchaseAccount}
                              onChange={(e) =>
                                setPurchaseAccount(e.target.value)
                              }
                            >
                              <option value="" selected disabled>
                                --Choose--
                              </option>
                              {accounts &&
                                accounts.map((i) => (
                                  <option
                                    value={i.account_name}
                                    className="text-uppercase"
                                  >
                                    {i.account_name}
                                  </option>
                                ))}
                            </select>
                              <button
                                type="button"
                                className="btn btn-outline-secondary ml-1"
                                data-toggle="modal"
                                data-dismiss="modal"
                                data-target="#createNewAccount"
                                style={{
                                  width: "fit-content",
                                  height: "fit-content",
                                }}
                              >
                                +
                              </button>
                          </div>
                        </div>
                        <div className="col-md-6 mt-3">
                          <label for="salesAccount" style={{ color: "white" }}>
                            Account
                          </label>
                          <select
                            name="sale_account"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            id="salesAccount"
                            value={salesAccount}
                            onChange={(e) => setSalesAccount(e.target.value)}
                          >
                            <option value="" selected disabled>
                              --Choose--
                            </option>
                            <option value="General Income">
                              General Income
                            </option>
                            <option value="Interest Income">
                              Interest Income
                            </option>
                            <option value="Late Fee Income">
                              Late Fee Income
                            </option>
                            <option value="Discount Income">
                              Discount Income
                            </option>
                            <option value="Shipping Charges">
                              Shipping Charges
                            </option>
                            <option value="Other Charges">Other Charges</option>
                          </select>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <label
                            for="purchaseDescription"
                            style={{ color: "white" }}
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            name="pur_desc"
                            id="purchaseDescription"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            value={purchaseDescription}
                            onChange={(e) =>
                              setPurchaseDescription(e.target.value)
                            }
                          />
                        </div>
                        <div className="col-md-6 mt-3">
                          <label
                            for="salesDescription"
                            style={{ color: "white" }}
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            name="sale_desc"
                            id="salesDescription"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            value={salesDescription}
                            onChange={(e) =>
                              setSalesDescription(e.target.value)
                            }
                          />
                        </div>
                      </div>

                      <div className="row" id="inventorytrack">
                        <div className="col-md-6 mt-3">
                          <label style={{ color: "white" }}>
                            Inventory Account
                          </label>
                          <select
                            name="invacc"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            required
                            value={inventoryAccount}
                            onChange={(e) =>
                              setInventoryAccount(e.target.value)
                            }
                          >
                            <option selected disabled value="">
                              Choose...
                            </option>
                            <option value="Inventory Assets">
                              Inventory Assets
                            </option>
                          </select>
                        </div>
                        <div className="col-md-3 mt-3">
                          <label style={{ color: "white" }}>
                            Stock on hand
                          </label>
                          <input
                            type="number"
                            name="stock"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            value={stock}
                            onChange={(e) => setStock(e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-3 mt-3">
                          <label style={{ color: "white" }}>
                            Stock Rate per Unit
                          </label>
                          <input
                            type="number"
                            name="stock_rate"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            value={stockUnitRate}
                            onChange={(e) => setStockUnitRate(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-3 mt-3">
                          <label style={{ color: "white" }}>
                            Minimum Stock to maintain
                          </label>
                          <input
                            type="number"
                            name="min_stock"
                            className="form-control"
                            style={{
                              backgroundColor: "#2a4964",
                              color: "white",
                            }}
                            value={minStock}
                            onChange={(e) => setMinStock(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="row mt-4 w-100">
                        <div className="col-4"></div>
                        <div className="col-4 d-flex justify-content-center">
                          <button
                            className="btn btn-outline-secondary text-grey w-75"
                            onClick={handleItemModalSubmit}
                            data-dismiss="modal"
                            type="button"
                            id="newItemSave"
                          >
                            Save
                          </button>
                        </div>
                        <div className="col-4"></div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="createNewUnit">
        <div className="modal-dialog">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">New Item Unit</h5>
              <button
                type="button"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                data-target="#newItem"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100">
              <div className="card p-3">
                <form
                  onSubmit={handleUnitModalSubmit}
                  id="newUnitForm"
                  className="px-1"
                >
                  <div className="row mt-2 w-100">
                    <div className="col-12">
                      <label for="name">Unit Name</label>
                      <input
                        name="name"
                        id="unit_name"
                        value={newUnit}
                        onChange={(e) => setNewUnit(e.target.value)}
                        className="form-control text-uppercase w-100"
                      />
                    </div>
                  </div>
                  <div className="row mt-4 w-100">
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="btn btn-outline-info text-grey"
                        type="submit"
                        data-toggle="modal"
                        data-target="#newItem"
                        onClick={handleUnitModalSubmit}
                        id="saveItemUnit"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="createNewAccount">
        <div className="modal-dialog modal-xl">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">New Account</h5>
              <button
                type="button"
                className="close"
                data-toggle="modal"
                data-dismiss="modal"
                data-target="#newItem"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body w-100" style={{maxHeight:"75vh", overflowY:"auto"}}>
              <div className="card p-3 w-100">
                <form id="newAccountForm" className="px-1">
                  <div className="row mt-2 mb-2 w-100">
                    <div className="col-md-6">
                      <div className="row mt-2">
                        <div className="col-12">
                          <label for="acctyp">Account Type</label>
                          <input
                            type="text"
                            value="Assets"
                            id="acctype"
                            name="acctype"
                            hidden
                          />
                          <select
                            name="account_type"
                            id="Account_type"
                            value={accType}
                            className="custom-select-md form-control w-100"
                            onChange={(e) => {
                              handleAccountTypeChange(e.target.value);
                            }}
                            required
                          >
                            <optgroup
                              label="Expense"
                              style={{ backgroundColor: "rgb(47 81 111)" }}
                            >
                              <option value="Expense"> Expense </option>
                              <option value="Cost Of Goods Sold">
                                {" "}
                                Cost Of Goods Sold{" "}
                              </option>
                              <option value="Other Expense">
                                {" "}
                                Other Expense{" "}
                              </option>
                            </optgroup>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <label for="name">*Name</label>
                          <input
                            name="account_name"
                            id="accountName"
                            required
                            value={accName}
                            onChange={(e) => setAccName(e.target.value)}
                            className="custom-select-md form-control w-100"
                          />
                        </div>
                      </div>
                      <div
                        className="row mt-1"
                        id="subAccountCheck"
                        style={{ display: "none" }}
                      >
                        <div className="col-12">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              name="subAccountCheckBox"
                              className="form-check-input"
                              id="subAccountCheckBox"
                              onChange={showParentAccounts}
                            />
                            <label
                              className="form-check-label"
                              for="subAccountCheckBox"
                            >
                              Make this a sub-account
                            </label>
                            <span>
                              <i
                                className="fa fa-question-circle"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Select this option if you are creating a sub-account."
                              ></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="row mt-2"
                        id="parentAccountValue"
                        style={{ display: "none" }}
                      >
                        <div className="col-12">
                          <label for="parentAccount">Parent Account</label>
                          <select
                            name="parent_account"
                            id="parentAccount"
                            value={parentAccount}
                            onChange={(e) => setParentAccount(e.target.value)}
                            className="custom-select-md form-control w-100"
                          >
                            <option selected disabled value="">
                              --Choose--
                            </option>
                            {parentAccounts &&
                              parentAccounts.map((a) => (
                                <option value={a.name}>{a.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <label for="acc_code">Account Code</label>
                          <input
                            type="text"
                            name="account_code"
                            id="account_code"
                            value={accCode}
                            onChange={(e) => setAccCode(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-12">
                          <label>Description</label>
                          <textarea
                            className="form-control"
                            rows="3"
                            id="description"
                            name="description"
                            value={accDesc}
                            onChange={(e) => setAccDesc(e.target.value)}
                            placeholder="Max. 500 Characters"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-4">
                      <div
                        id="acnt-desc"
                        className="form-control"
                        name="detype"
                        style={{ fontSize: "small", height: "fit-content" }}
                      ></div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <div className="row w-100">
                      <div className="col-md-4"></div>
                      <div className="col-md-4 d-flex justify-content-center">
                        <button
                          type="button"
                          data-toggle="modal"
                          data-target="#newItem"
                          onClick={handleNewAccSubmit}
                          id="saveNewAccount"
                          className="btn btn-outline-info"
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}
export default Edit_Eway_Bill;