import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";

function Cashinhand_history() {
  const { id } = useParams();  // Get 'id' from URL params
  const [history, setHistory] = useState([]);

  const fetchInvoiceHistory = () => {
    axios
      .get(`${config.base_url}/cash_in_hand_history/${id}/`)  // Use 'id' from URL params
      .then((res) => {
        if (res.data.status) {
          setHistory(res.data.cash_in_hand_history);  // Directly set history data
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchInvoiceHistory();
  }, [id]);

  return (
    <>
      <FinBase />
      <div
        className="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <center>
                <h3
                  className="card-title"
                  style={{ textTransform: "Uppercase" }}
                >
                  CASH ADJUST HISTORY
                </h3>
              </center>
            </div>
          </div>
        </div>

        <div
          className="card card-registration card-registration-2"
          style={{ borderRadius: "15px" }}
        >
          <div className="card-body p-0">
            <div id="history">
              <center>
                <h3 className="mt-3 text-uppercase">
                  TRANSACTIONS
                </h3>
              </center>
              <div className="table-responsive px-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">SL NO.</th>
                      <th className="text-center">DATE</th>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">DONE BY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map((h, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                        <td style={{ textAlign: "center" }}>{h.Date}</td>
                        <td
                          className={`text-center ${
                            h.Action === "Created" ? "text-success" : "text-warning"
                          }`}
                        >
                          {h.Action}
                        </td>
                        <td style={{ textAlign: "center" }}>{h.done_by}</td>
                      </tr>
                    ))}
                  </tbody>

                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cashinhand_history;
