import React, { useEffect, useState } from "react";
import FinBase from "../FinBase";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import html2pdf from "html2pdf.js";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";
import "../../styles/SalaryDetails.css";

function ViewSalaryDetails() {
  const ID = Cookies.get("Login_id");
  const { salaryId } = useParams();
  const [salaryDetails, setSalaryDetails] = useState({});
  const [otherDetails, setOtherDetails] = useState({});
  const [comments, setComments] = useState([]);
  const [history, setHistory] = useState({
    action: "",
    date: "",
    doneBy: "",
  });

  const fetchSalaryDetails = () => {
    axios
      .get(`${config.base_url}/fetch_salary_details/${salaryId}/`)
      .then((res) => {
        console.log("SLRY DATA=", res);
        if (res.data.status) {
          var slry = res.data.salary;
          var hist = res.data.history;
          var cmt = res.data.comments;
          var other = res.data.otherDetails;

          setOtherDetails(other);
          setComments([]);
          cmt.map((c) => {
            setComments((prevState) => [...prevState, c]);
          });
          setSalaryDetails(slry);
          if (hist) {
            setHistory(hist);
          }
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchSalaryDetails();
  }, []);

  const currentUrl = window.location.href;
  const shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    currentUrl
  )}`;

  const navigate = useNavigate();

  function handleConvertSalaryDetails() {
    Swal.fire({
      title: `Convert Salary Details - ${otherDetails.employeeName}?`,
      text: "Are you sure you want to convert this.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Convert",
    }).then((result) => {
      if (result.isConfirmed) {
        var st = {
          id: salaryId,
        };
        axios
          .post(`${config.base_url}/change_salary_details_status/`, st)
          .then((res) => {
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Converted",
              });
              fetchSalaryDetails();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  const [comment, setComment] = useState("");
  const saveSalaryDetailsComment = (e) => {
    e.preventDefault();
    var cmt = {
      Id: ID,
      Salary: salaryId,
      employee: salaryDetails.Employee,
      comment: comment,
    };
    axios
      .post(`${config.base_url}/add_salary_details_comment/`, cmt)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Toast.fire({
            icon: "success",
            title: "Comment Added",
          });
          setComment("");
          fetchSalaryDetails();
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  function handleDeleteSalaryDetails(id) {
    Swal.fire({
      title: `Delete Salary Details - ${otherDetails.employeeName}?`,
      text: "Data cannot be restored.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_salary_details/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Salary Details Deleted.",
            });
            navigate("/salary_details");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  function deleteComment(id) {
    Swal.fire({
      title: "Delete Comment?",
      text: "Are you sure you want to delete this.!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonColor: "#3085d6",
      confirmButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${config.base_url}/delete_salary_details_comment/${id}/`)
          .then((res) => {
            console.log(res);

            Toast.fire({
              icon: "success",
              title: "Comment Deleted",
            });
            fetchSalaryDetails();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  }

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });

  function overview() {
    document.getElementById("overview").style.display = "block";
    document.getElementById("payslip").style.display = "none";
    document.getElementById("printBtn").style.display = "none";
    document.getElementById("pdfBtn").style.display = "none";
    document.getElementById("shareBtn").style.display = "none";
    document.getElementById("editBtn").style.display = "block";
    document.getElementById("deleteBtn").style.display = "block";
    document.getElementById("historyBtn").style.display = "block";
    document.getElementById("commentBtn").style.display = "block";
    if (salaryDetails.status == "Draft") {
      document.getElementById("statusBtn").style.display = "block";
    }
    document.getElementById("overviewBtn").style.backgroundColor =
      "rgba(22,37,50,255)";
    document.getElementById("payslipBtn").style.backgroundColor = "transparent";
  }

  function payslip() {
    document.getElementById("overview").style.display = "none";
    document.getElementById("payslip").style.display = "block";
    document.getElementById("printBtn").style.display = "block";
    document.getElementById("pdfBtn").style.display = "block";
    document.getElementById("shareBtn").style.display = "block";
    document.getElementById("editBtn").style.display = "none";
    document.getElementById("deleteBtn").style.display = "none";
    document.getElementById("historyBtn").style.display = "none";
    document.getElementById("commentBtn").style.display = "none";
    if (salaryDetails.status == "Draft") {
      document.getElementById("statusBtn").style.display = "none";
    }
    document.getElementById("overviewBtn").style.backgroundColor =
      "transparent";
    document.getElementById("payslipBtn").style.backgroundColor =
      "rgba(22,37,50,255)";
  }

  function printSheet() {
    var divToPrint = document.getElementById("printContent");
    var printWindow = window.open("", "", "height=700,width=1000");
    var styles = `
    #printContent
    {
        background-color: rgba(255, 255, 255, 0.926);
        padding: 40px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #whatToPrint
    {
        border: 1px solid #000000;
        width: 75%;
        
    }
    #whatToPrint h1{
        font-size: 23px;
        text-align: center;
        letter-spacing: 1px;
        color: #161515;
        font-weight: 600;
        padding-top: 10px;
        font-family:Georgia, 'Times New Roman', Times, serif
    }
    .employee-details
    {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: 1.3px solid #000000d9;
        padding: 3rem 0.10rem;
        border-bottom: 1.3px solid #000000d9;
        color: #000000;
    }
    .employee-details ul li{
        list-style: none;
        padding-top: 12px;
        font-size: 13px;
        letter-spacing: 1px;
        display: grid;
        grid-template-columns: 2fr 0.20fr 2fr;
        align-items: center;
        justify-content: space-between;
        gap: 1.5rem;
        font-weight: 600;
        font-family:'Courier New', Courier, monospace
    }
    .employee-details ul li span{
        text-align:start;
    }
    .salary-section h3{
        font-size: 14px;
        text-align: center;
        letter-spacing: 1px;
        color: #1f1d1dd4;
        font-weight: 600;
        padding-top: 1rem;
        padding-bottom: 1rem;
        font-family:Georgia, 'Times New Roman', Times, serif;
        text-transform: uppercase;
    }
    .paysliptable
    {
        border: 1px solid #161515;
        color: #2d2d2d;
    }
    .paysliptable tr td,
    .paysliptable tr th{
        border-right: 1px solid #161515;
    }
    .paysliptable tr td:nth-child(2)
    {
        text-align: center;
    }

    .authorization-section
    {
        display: grid;
        grid-template-columns: 2fr 0.80fr;
        padding: 20px;
    }
    .authorization-seal
    {
        text-align: center;
        padding: 1rem;
    }
    .authorization-sign img{
        padding-top: 1rem;
    }
    .authorization-sign p{
     color: #161515;
     font-size: 12px;
     padding-top: 18px;
     font-weight: 500;

    }
   .pay{
    color: black;
   }
    `;

    printWindow.document.write("<html><head><title></title>");
    printWindow.document.write(`
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.2/dist/css/bootstrap.min.css" integrity="sha384-xOolHFLEh07PJGoPkLv1IbcEPTNtaed2xpHsD9ESMhqIYd0nLMwNLD69Npy4HI+N" crossorigin="anonymous">
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Agbalumo&family=Black+Ops+One&family=Gluten:wght@100..900&family=Playball&display=swap" rel="stylesheet">
    `);
    printWindow.document.write("</head>");
    printWindow.document.write("<body>");
    printWindow.document.write("<style>");
    printWindow.document.write(styles);
    printWindow.document.write("</style>");
    printWindow.document.write(divToPrint.outerHTML);
    printWindow.document.write("</body>");
    printWindow.document.write("</html>");
    printWindow.document.close();
    printWindow.print();
    printWindow.addEventListener("afterprint", function () {
      printWindow.close();
    });
  }

  function salaryDetailsPdf() {
    var data = {
      Id: ID,
      sal_id: salaryId,
      emp_id: salaryDetails.Employee,
    };
    axios
      .get(`${config.base_url}/salary_details_pdf/`, {
        responseType: "blob",
        params: data,
      })
      .then((res) => {
        console.log("PDF RES=", res);

        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        const a = document.createElement("a");
        a.href = fileURL;
        a.download = `SalaryDetails_${otherDetails.employeeName}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (err.response && err.response.data && !err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  }

  const [emailIds, setEmailIds] = useState("");
  const [emailMessage, setEmailMessage] = useState("");

  function handleShareEmail(e) {
    e.preventDefault();

    var emailsString = emailIds.trim();

    var emails = emailsString.split(",").map(function (email) {
      return email.trim();
    });

    var emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    var invalidEmails = [];
    if (emailsString === "") {
      alert("Enter valid email addresses.");
    } else {
      for (var i = 0; i < emails.length; i++) {
        var currentEmail = emails[i];

        if (currentEmail !== "" && !emailRegex.test(currentEmail)) {
          invalidEmails.push(currentEmail);
        }
      }

      if (invalidEmails.length > 0) {
        alert("Invalid emails. Please check!\n" + invalidEmails.join(", "));
      } else {
        // document.getElementById("share_to_email_form").submit();
        var em = {
          sal_id: salaryId,
          emp_id: salaryDetails.Employee,
          Id: ID,
          email_ids: emailIds,
          email_message: emailMessage,
        };
        axios
          .post(`${config.base_url}/share_salary_details_email/`, em)
          .then((res) => {
            if (res.data.status) {
              Toast.fire({
                icon: "success",
                title: "Shared via mail.",
              });
              setEmailIds("");
              setEmailMessage("");
            }
          })
          .catch((err) => {
            console.log("ERROR=", err);
            if (
              err.response &&
              err.response.data &&
              !err.response.data.status
            ) {
              Swal.fire({
                icon: "error",
                title: `${err.response.data.message}`,
              });
            }
          });
      }
    }
  }

  return (
    <>
      <FinBase />
      <div
        className="page-content mt-0 pt-0"
        id="page-content"
        style={{ backgroundColor: "#2f516f", minHeight: "100vh" }}
      >
        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to="/salary_details"
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>
        <div className="card radius-15">
          <div className="card-body" style={{ width: "100%" }}>
            <div className="card-title">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6">
                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                        backgroundColor: "rgba(22,37,50,255)",
                      }}
                      onClick={overview}
                      id="overviewBtn"
                    >
                      Overview
                    </a>
                    <a
                      style={{
                        padding: "10px",
                        cursor: "pointer",
                        borderRadius: "1vh",
                      }}
                      onClick={payslip}
                      id="payslipBtn"
                    >
                      Payslip
                    </a>
                  </div>

                  <div className="col-md-6 d-flex justify-content-end">
                    {salaryDetails.status == "Draft" ? (
                      <a
                        onClick={handleConvertSalaryDetails}
                        id="statusBtn"
                        style={{
                          display: "block",
                          height: "fit-content",
                          width: "fit-content",
                        }}
                        className="ml-2 fa fa-check btn btn-outline-secondary text-grey "
                        role="button"
                      >
                        &nbsp;Convert
                      </a>
                    ) : null}
                    <a
                      onClick={salaryDetailsPdf}
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-file"
                      role="button"
                      id="pdfBtn"
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                    >
                      &nbsp;PDF
                    </a>
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-print"
                      role="button"
                      id="printBtn"
                      style={{
                        display: "none",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                      onClick={() => printSheet()}
                    >
                      &nbsp;Print
                    </a>

                    <div
                      className="dropdown p-0 nav-item"
                      id="shareBtn"
                      style={{ display: "none" }}
                    >
                      <li
                        className="ml-2 dropdown-toggle btn btn-outline-secondary text-grey fa fa-share-alt"
                        data-toggle="dropdown"
                        style={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                      >
                        &nbsp;Share
                      </li>
                      <ul
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                        id="listdiv"
                      >
                        <a
                          href={shareUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li
                            style={{
                              textAlign: "center",
                              color: "#e5e9ec",
                              cursor: "pointer",
                            }}
                          >
                            WhatsApp
                          </li>
                        </a>
                        <li
                          style={{
                            textAlign: "center",
                            color: "#e5e9ec",
                            cursor: "pointer",
                          }}
                          data-toggle="modal"
                          data-target="#shareToEmail"
                        >
                          Email
                        </li>
                      </ul>
                    </div>
                    <Link
                      to={`/edit_salary_details/${salaryId}/`}
                      className="ml-2 fa fa-pencil btn btn-outline-secondary text-grey"
                      id="editBtn"
                      role="button"
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;Edit
                    </Link>
                    <a
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-trash"
                      id="deleteBtn"
                      role="button"
                      onClick={() => handleDeleteSalaryDetails(salaryId)}
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;Delete
                    </a>
                    <a
                      href="#"
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-comments"
                      id="commentBtn"
                      role="button"
                      style={{
                        display: "block",
                        height: "fit-content",
                        width: "fit-content",
                      }}
                      data-toggle="modal"
                      data-target="#commentModal"
                    >
                      &nbsp;Comment
                    </a>
                    <Link
                      to={`/salary_details_history/${salaryId}/`}
                      className="ml-2 btn btn-outline-secondary text-grey fa fa-history"
                      id="historyBtn"
                      role="button"
                      style={{ height: "fit-content", width: "fit-content" }}
                    >
                      &nbsp;History
                    </Link>
                  </div>
                </div>
              </div>
              <center>
                <h3
                  className="card-title"
                  style={{ textTransform: "Uppercase" }}
                >
                  SALARY DETAILS OVERVIEW
                </h3>
              </center>
            </div>
          </div>
        </div>

        <div
          className="card card-registration card-registration-2"
          style={{ borderRadius: "15px" }}
        >
          <div className="card-body p-0">
            <div id="overview">
              <div
                className="row g-0"
                style={{ marginLeft: "1px", marginRight: "1px" }}
              >
                <div className="col-lg-8">
                  <div className="history_highlight px-4 pt-4 d-flex">
                    <div className="col-8 d-flex justify-content-start">
                      {history.action == "Created" ? (
                        <p
                          className="text-success"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Created by :
                        </p>
                      ) : (
                        <p
                          className="text-warning"
                          style={{ fontSize: "1.07rem", fontWeight: "500" }}
                        >
                          Last Edited by :
                        </p>
                      )}
                      <span
                        className="ml-2"
                        style={{ fontSize: "1.15rem", fontWeight: "500" }}
                      >
                        {history.doneBy}
                      </span>
                    </div>
                    <div className="col-4 d-flex justify-content-end">
                      <span>{history.date}</span>
                    </div>
                  </div>
                  <div className="pb-3 px-2">
                    <div className="card-body">
                      <div className="card-title">
                        <div className="row">
                          <div className="col mt-3">
                            <h2 className="mb-0">
                              {otherDetails.employeeName}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <hr />

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Employee ID</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.employeeId}
                          </p>
                        </div>
                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Designation</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.designation}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Age</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">{otherDetails.age}</p>
                        </div>
                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Date Of Joining</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.joiningDate}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Contact</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.contact}
                          </p>
                        </div>
                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Email</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {otherDetails.email}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Holidays</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {salaryDetails.holiday}
                          </p>
                        </div>
                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Leave</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {salaryDetails.leave}
                          </p>
                        </div>
                      </div>

                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Working Days</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {salaryDetails.total_working_days}
                          </p>
                        </div>
                        <div className="col-md-2 mt-3 vl">
                          <h6 className="mb-0">Casual Leave</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {salaryDetails.casual_leave}
                          </p>
                        </div>
                      </div>
                      <div className="row mb-4 d-flex justify-content-between align-items-center">
                        <div className="col-md-2 mt-3">
                          <h6 className="mb-0">Salary</h6>
                        </div>
                        <div className="col-md-4 mt-3">
                          <p className="mb-0 text-right">
                            {salaryDetails.total_salary}
                          </p>
                        </div>
                        <div className="col-md-2 mt-3 vl"></div>
                        <div className="col-md-4 mt-3"></div>
                      </div>

                      <hr className="my-4" />
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-4"
                  style={{
                    backgroundColor: "rgba(22,37,50,255)",
                    borderTopRightRadius: "2vh",
                    borderBottomRightRadius: "2vh",
                  }}
                >
                  <div className="px-3 py-4">
                    <h4 className="fw-bold mb-2 mt-4 pt-1">Salary Details</h4>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Status</h6>
                      {salaryDetails.status == "Draft" ? (
                        <span className="text-info h5 font-weight-bold">
                          DRAFT
                        </span>
                      ) : (
                        <span className="text-success h5 font-weight-bold">
                          SAVED
                        </span>
                      )}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Basic Salary</h6>
                      {salaryDetails.basic_salary}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Salary Date</h6>
                      {salaryDetails.salary_date}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Month-Year</h6>
                      {otherDetails.month}
                      {"-"}
                      {salaryDetails.year}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Bonus</h6>
                      {salaryDetails.add_bonus}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Other Cuttings</h6>
                      {salaryDetails.other_cuttings}
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">HRA</h6>
                      {salaryDetails.hra}
                    </div>

                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Conveyance Allowance</h6>
                      {salaryDetails.conveyance_allowance}
                    </div>
                    <div className="d-flex justify-content-between mb-4">
                      <h6 className="">Other Allowance</h6>
                      {salaryDetails.other_allowance}
                    </div>
                    <hr className="my-4" />
                  </div>
                </div>
              </div>
            </div>

            <div className="" id="payslip" style={{ display: "none" }}>
              <div id="printContent">
                <div id="whatToPrint" className="print-only">
                  <div className="page" size="A4">
                    <h1>PAYSLIP</h1>

                    <div className="employee-details">
                      <div>
                        <ul>
                          <li>
                            <span>Employee Name</span>
                            <span>:</span>
                            <span> {otherDetails.employeeFullName}</span>
                          </li>
                          <li>
                            <span>Employee Code</span>
                            <span>:</span>
                            <span> {otherDetails.employeeId}</span>
                          </li>
                          <li>
                            <span>Month-Year</span>
                            <span>:</span>
                            <span>
                              {otherDetails.month}-{salaryDetails.year}
                            </span>
                          </li>
                          <li>
                            <span>Designation</span>
                            <span>:</span>
                            <span> {otherDetails.designation}</span>
                          </li>
                        </ul>
                      </div>
                      <div>
                        <ul>
                          <li>
                            <span>Location</span>
                            <span>:</span>
                            <span>{otherDetails.current_location}</span>{" "}
                          </li>
                          <li>
                            <span>Date of Joining</span>
                            <span>:</span>
                            <span> {otherDetails.joiningDate}</span>
                          </li>
                          <li>
                            <span>Working Days</span>
                            <span>:</span>
                            <span>{salaryDetails.total_working_days}</span>{" "}
                          </li>
                          <li>
                            <span>Leave</span>
                            <span>:</span>{" "}
                            <span>{otherDetails.leave_minus_casual_leave}</span>{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="salary-section">
                      <h3>Confirmed Salary</h3>

                      <table className="table paysliptable">
                        <thead>
                          <tr style={{ borderRight: "1px solid #161515" }}>
                            <th className="pay" style={{ color: "#161515" }}>
                              Particulars
                            </th>
                            <th className="pay" style={{ color: "#161515" }}>
                              Gross Amount (Rs)
                            </th>
                            <th className="pay" style={{ color: "#161515" }}>
                              Deduction Amount (Rs)
                            </th>
                            <th className="pay" style={{ color: "#161515" }}>
                              Amount (Rs)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <p className="pay">Basic Salary</p>
                              <p className="pay">Conveyance Allowance</p>
                              <p className="pay">HRA</p>
                              <p className="pay">Other Allowance</p>
                              <p className="pay">
                                {salaryDetails.add_bonus ? "Bonus" : null}
                              </p>
                            </td>
                            <td>
                              <p className="pay">
                                {salaryDetails.basic_salary}
                              </p>
                              <p className="pay">
                                {salaryDetails.conveyance_allowance}
                              </p>
                              <p className="pay">{salaryDetails.hra}</p>
                              <p className="pay">
                                {salaryDetails.other_allowance}
                              </p>
                              <p className="pay">
                                {salaryDetails.add_bonus
                                  ? salaryDetails.add_bonus
                                  : null}
                              </p>
                            </td>
                            <td>
                              <p
                                className="pay"
                                style={{ height: "50px", color: "#161515" }}
                              >
                                Leave Deduction
                              </p>
                            </td>
                            <td>
                              <p
                                className="pay"
                                style={{ height: "50px", color: "#161515" }}
                              >
                                {salaryDetails.leave_deduction}
                              </p>
                            </td>
                          </tr>

                          <tr>
                            <td></td>
                            <td></td>
                            <td
                              className="pay"
                              style={{ height: "50px", color: "#161515" }}
                            >
                              Total Deduction
                            </td>
                            <td
                              className="pay"
                              style={{ height: "50px", color: "#161515" }}
                            >
                              {otherDetails.total_deduction}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="pay"
                              colspan="3"
                              style={{ height: "50px", color: "#161515" }}
                            >
                              Net Salary
                            </td>
                            <td
                              className="pay"
                              style={{ height: "50px", color: "#161515" }}
                            >
                              {salaryDetails.total_salary}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="authorization-section">
                      <div className="authorization-seal">
                        <img
                          src=""
                          alt="Company Seal"
                          style={{ maxWidth: "150px", color: "#161515" }}
                        />
                      </div>
                      <div className="authorization-sign">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <img
                            src=""
                            alt="Authorized Signature"
                            style={{ maxWidth: "150px", color: "#161515" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Share To Email Modal --> */}
      <div className="modal fade" id="shareToEmail">
        <div className="modal-dialog modal-lg">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h5 className="m-3">Share Salary Details</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form
                onSubmit={handleShareEmail}
                className="needs-validation px-1"
                id="share_to_email_form"
              >
                <div className="card p-3 w-100">
                  <div className="form-group">
                    <label for="emailIds">Email IDs</label>
                    <textarea
                      className="form-control"
                      name="email_ids"
                      id="emailIds"
                      rows="3"
                      placeholder="Multiple emails can be added by separating with a comma(,)."
                      value={emailIds}
                      onChange={(e) => setEmailIds(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-group mt-2">
                    <label for="item_unitname">Message(optional)</label>
                    <textarea
                      name="email_message"
                      id="email_message"
                      className="form-control"
                      cols=""
                      rows="4"
                      value={emailMessage}
                      onChange={(e) => setEmailMessage(e.target.value)}
                      placeholder="This message will be sent along with Salary details."
                    />
                  </div>
                </div>
                <div
                  className="modal-footer d-flex justify-content-center w-100"
                  style={{ borderTop: "1px solid #ffffff" }}
                >
                  <button
                    type="submit"
                    id="share_with_email"
                    className="submitShareEmailBtn w-50 text-uppercase"
                  >
                    SEND MAIL
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add Comments Modal --> */}
      <div
        className="modal fade"
        id="commentModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content" style={{ backgroundColor: "#213b52" }}>
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Add Comments
              </h3>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <form onSubmit={saveSalaryDetailsComment} className="px-1">
              <div className="modal-body w-100">
                <textarea
                  type="text"
                  className="form-control"
                  name="comment"
                  value={comment}
                  required
                  onChange={(e) => setComment(e.target.value)}
                />
                {comments.length > 0 ? (
                  <div className="container-fluid">
                    <table className="table mt-4">
                      <thead>
                        <tr>
                          <th className="text-center">sl no.</th>
                          <th className="text-center">Comment</th>
                          <th className="text-center">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {comments.map((c, index) => (
                          <tr className="table-row">
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">{c.comment}</td>
                            <td className="text-center">
                              <a
                                className="text-danger"
                                onClick={() => deleteComment(`${c.id}`)}
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    fontSize: "1.1rem",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <span className="my-2 font-weight-bold d-flex justify-content-center">
                    No Comments.!
                  </span>
                )}
              </div>

              <div className="modal-footer w-100">
                <button
                  type="button"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  style={{ width: "fit-content", height: "fit-content" }}
                  className="btn"
                  id="commentSaveBtn"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewSalaryDetails;
